import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';

import { MongoDBClientContext } from '../providers/mongoDBClientProvider';
import { useContext, useEffect, useState } from 'react';
import { CaseDetail, CaseSummary } from '../models/Cases';
import { CaseSummaryTile } from './CaseSummaryTile';
import { ArcGISMapping, GISSummaryData, GISSummaryList } from './ArcGISMapping';
import { PriorityBadge } from './PriorityBadges';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router';
import { BuildSecondaryActions, PerformStandardAction } from './buildGISUtils';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const drawerWidth = 340;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

/*interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}*/

/*const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));*/

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  //const theme = useTheme();
  const [open] = React.useState(true);

  /*const handleDrawerOpen = () => {
    setOpen(true);
  };*/

  /*const handleDrawerClose = () => {
    setOpen(false);
  };*/

  const { state } = useContext(MongoDBClientContext);
  const [cases, setCases] = useState<CaseSummary[]>([])
  const [gisData, setGisData] = useState<GISSummaryList>([]);
  const [selectedCaseIds, setSelectedCaseIds] = useState<string[]>([]);
  const [popupsOpenIds, setPopupsOpenIds] = useState<string[]>([]);
  
  useEffect(() => {
    setCases(state.activeCases);
  }, [state.activeCases]);

  useEffect(() => {
    setGisData(cases.map<GISSummaryData>((c: CaseSummary) =>
    ({
      lat: c.initialAssessment?.ia_latitude, lng: c.initialAssessment?.ia_longitude, id: c._id,
      evacpriority: c.incident?.evacPriority ?? "",
      evacpriorityid: c.incident?.evacPriorityId ?? -1,
      surname: c.patientDetails?.surname, forename: c.patientDetails?.name,
      caseid: c.caseId,
      caseObjectId: c._id,
      title: `${c.caseId} (${c.patientDetails?.name} ${c.patientDetails?.surname})`
    }
    )
    )
    )
  }, [cases, setGisData]);

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const ids = searchParams.getAll('id')
    if (ids) {
      setSelectedCaseIds( prev => [...prev, ...ids]);
    }
  }, [searchParams]);


  const navigate = useNavigate();
  const handleClick = (id: string, tab: string) => {
    PerformStandardAction(navigate, id, tab);
  }

  const toggleCaseSelect = (c: CaseDetail, replaceSelection?:boolean) => {
    if (replaceSelection) {
      setSelectedCaseIds([c._id])
    } else {
    const n = [...selectedCaseIds];
    const idx = n.findIndex(i => c._id === i);
    if (idx === -1) {
      n.push(c._id)
    } else {
      delete n[idx];
    }
    setSelectedCaseIds([...n]);
  }
  };

  const listRef = React.useRef<any|null>(null);

  const handlePopupVisibilityChange =  useCallback((id:string, visible:boolean ) => {
    if (id) {
      console.log(`popupVisibilityChange(${id})`)
      
      const n = [...popupsOpenIds];
      const i = n.findIndex(x=>x === id);

      if (!visible && i >=0 ) {n.splice(i,1)}  else if (visible && i === -1) {n.push(id)};

      setPopupsOpenIds(n);
      if (visible) {
        listRef.current?.ownerDocument.getElementById(id)?.scrollIntoView();
      }
    }}, [listRef]);


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop: '10px'
          },
        }}
        variant="permanent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        </DrawerHeader>
        <List ref={listRef}  sx={{ width: '100%', height:'100%', overflowY:'auto', overflowX:'clip', bgcolor: 'background.paper', flexWrap: 'wrap', alignItems: 'flex-end', paddingRight: '12px' }}>
          {cases.map((c: any) =>
            <CaseSummaryTile key={c._id} c={c} actions={BuildSecondaryActions(c, 20, handleClick, "stack", true, false, true)} onClick={toggleCaseSelect}
              selected={selectedCaseIds.findIndex((i) => i === c._id) !== -1 || popupsOpenIds.findIndex(i=> i === c._id) !== -1 } 
              compact={true}
            />
          )}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div style={{ width: "100%", height: "600px" }} >
          <ArcGISMapping list={gisData} fields={
            [
              { name: "evacpriorityid", type: "integer" },
              { name: "evacpriority", type: "string" },
              { name: "caseid", type: "string" },
              { name: "id", type: "string" },
              { name: "surname", type: "string" },
              { name: "forename", type: "string" },
              { name: "title", type: "string" },
              { name: "caseObjectId", type: "string"}
            ]} popupTemplate={{ title: '{title}', content: buildPopup }}
            selectedCaseIds={selectedCaseIds}
            popupVisibilityChange={handlePopupVisibilityChange}
          />
        </div>
      </Main>
    </Box>
  );
}

function buildPopup(fields: { [key: string]: any }): Promise<HTMLElement> {
  const p = new Promise<HTMLElement>((resolve, reject) => {
    let puNode = document.createElement("div");
    ReactDOM.render(<PopUpContent fields={fields} />, puNode, () => { resolve(puNode) })
  });
  return p;
}
function PopUpContent(p: { fields: { [key: string]: any } }) {
  return (
    <div>
      <PriorityBadge priorityId={p.fields['evacpriorityid']} />
    </div>
  );
}
