import { CloudQueue, CloudOff } from "@mui/icons-material"
import { MongoDBClientConsumer } from "../providers/mongoDBClientProvider"

const NetworkConnectionIcon = ()=> {
    return (<MongoDBClientConsumer>
        {({state})=> (<>
            {!state.error && (<CloudQueue/>) }
            {state.error && (<CloudOff/>) }
            </>)
        }
    </MongoDBClientConsumer>);

}

export default NetworkConnectionIcon;