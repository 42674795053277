import { Box, Tooltip } from "@mui/material";
import { MouseEvent, ReactElement, useEffect, useRef, useState } from "react"
import { Annotation } from "../models/Cases";

export function CanvasComponent(p: { background: string, markers: Annotation[], scalex?: number, scaley?: number, imageActualWidth?: number, imageActualHeight?: number } & any) {

    const backgroundRef = useRef<HTMLImageElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    let markerboxes: { [index: string]: ReactElement } = {};

    const [, setRefresh] = useState(1);

    const [selected, setSelected] = useState<string | null>();

    const { background } = p;


    const draw = () => {
        if (backgroundRef?.current) {
            const sx = p.scalex ?? (backgroundRef.current.width/p.imageActualWidth) ?? (backgroundRef.current.width / backgroundRef.current.naturalWidth)
            const sy = p.scaley ?? (backgroundRef.current.height/p.imageActualHeight) ?? (backgroundRef.current.height / backgroundRef.current.naturalHeight);
            markerboxes = {};
            (p.markers || []).forEach((a: Annotation) => {
                let d = <Tooltip key={`tip${a._id}`} open={selected === a._id} title={a.annotation}><Box key={a._id} sx={{ position: 'absolute', left: (a.sX * sx) + 'px', top: ((a.sY * sy) + 10) + 'px', zIndex: '601', width: '10px', height: '10px', backgroundColor: 'blue' }} onClick={(e => canvasClick(e, a))}></Box></Tooltip>;
                markerboxes[a._id] = d;
            });

        }
    }

    useEffect(() => {
        if (backgroundRef && divRef) {
            const ro = new ResizeObserver(() => {
                divRef.current!.style.width = backgroundRef.current!.clientWidth + 'px';
                divRef.current!.style.height = backgroundRef.current!.clientHeight + 'px';
                setRefresh(prev => prev + 1);

            });

            ro.observe(backgroundRef!.current!);

            return () => {
                ro.disconnect();
            }
        }
    }, []);

    const canvasClick = (e: MouseEvent, a: Annotation): any => {
        setSelected(a._id);
    }

    draw();


    return <Box ref={divRef} sx={{ position: 'relative', height: '50px', width: '100%', minHeight: '500px', zIndex: 200 }}>
        {Object.values(markerboxes)}
        <img alt='bodyimage' ref={backgroundRef} src={background} style={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 100 }} />
    </Box>
}