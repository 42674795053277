import { Ccast, Vitals } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import { MultiSelectionComponent } from "./MultiSelectionComponent";
import { NumberOrMinusOneComponent } from "./NumberOrMinusOneComponent";
import { Grid } from "@mui/material";


export function CCastComponent(p: { t: Ccast | null, v: Vitals }) {
    const t = p.t;
    const v = p.v;


    //console.log(t);

    const bp = v !== null && v.systolicBp !== -1 ? v.systolicBp + ' / ' + v.diastolicBp + ' mmHg' : '';
    const capr = v.capR == -1 ? "" : v.capR > 0 ? "yes" : "no";

    return (t &&
        <li>
            <L t={'disposition-ccast-title'} />
            <ul>
                <li>
                    <L t={'disposition-ccast-ventilation'} />
                    <ul>
                        <li>
                            <L t={'disposition-ccast-ventilation-volume'} />{': '}<U><NumberOrMinusOneComponent v={t.ventilationTidal} units=' mL' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-ventilation-rate'} />{': '}<U><NumberOrMinusOneComponent v={t.ventilationRate} units='L/s' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-ventilation-peep'} />{': '}<U><NumberOrMinusOneComponent v={t.ventilationPeep} units='cm H2O' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-ventilation-pip'} />{': '}<U><NumberOrMinusOneComponent v={t.ventilationPip} units='cm H2O' /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-ccast-observations'} />
                    <ul>
                        <li>
                            <L t={'disposition-ccast-observations-pulse'} />{': '}<U><NumberOrMinusOneComponent v={v.hr} units='bpm' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-observations-bp'} />{': '}<U><>{bp}</></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-observations-capr'} />{': '}<U><L t={capr} /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-observations-coretemp'} />{': '}<U><NumberOrMinusOneComponent v={v.coreTemp} units='°C' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-observations-spo2'} />{': '}<U><NumberOrMinusOneComponent v={v.o2} units='%' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-observations-fio2'} />{': '}<U><NumberOrMinusOneComponent v={t.observationFio2} units='%' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-observations-etco2'} />{': '}<U><NumberOrMinusOneComponent v={v.etco2} units='' /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-ccast-venous-arterial-lines'} />
                    <ul>
                        <li>
                            <Grid container>
                                <Grid item md={4} >
                                    <U><MultiSelectionComponent data={[t.ivioArterial ? "arterial" : ""]}
                                        availableItems={{
                                            'arterial': 'disposition-ccast-venous-arterial-lines-arterial',
                                        }} /></U>
                                    {t.ivioArterial && (
                                        <ul>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-side'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioArterialSide]}
                                                    availableItems={{
                                                        'Left': 'left',
                                                        'Right': 'right',
                                                    }} /></U>
                                            </li>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-area'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioArterialArea]}
                                                    availableItems={{
                                                        'ACF': 'disposition-ccast-venous-arterial-lines-area-acf',
                                                        'Foot': 'disposition-ccast-venous-arterial-lines-area-foot',
                                                        'Forearm': 'disposition-ccast-venous-arterial-lines-area-forearm',
                                                        'Hand': 'disposition-ccast-venous-arterial-lines-area-hand',
                                                        'Jugular Vein': 'disposition-ccast-venous-arterial-lines-area-jugular',
                                                        'Wrist': 'disposition-ccast-venous-arterial-lines-area-wrist',
                                                        'Lower Leg': 'disposition-ccast-venous-arterial-lines-area-lowerleg',
                                                        'Upper Arm': 'disposition-ccast-venous-arterial-lines-area-upperarm',
                                                    }} /></U>
                                            </li>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-arterial-size'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioArterialSize]}
                                                    availableItems={{
                                                        '18G': 'disposition-ccast-venous-arterial-lines-size-18g',
                                                        '20G': 'disposition-ccast-venous-arterial-lines-size-20g',
                                                    }} /></U>
                                            </li>
                                        </ul>
                                    )}
                                </Grid>

                                <Grid item md={4} >
                                    <U><MultiSelectionComponent data={[t.ivioCvp ? "cvp" : ""]}
                                        availableItems={{
                                            'cvp': 'disposition-ccast-venous-arterial-lines-cvp',
                                        }} /></U>

                                    {t.ivioCvp && (
                                        <>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-side'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioCvpSide]}
                                                    availableItems={{
                                                        'Left': 'left',
                                                        'Right': 'right',
                                                    }} /></U>
                                            </li>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-area'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioCvpArea]}
                                                    availableItems={{
                                                        'ACF': 'disposition-ccast-venous-arterial-lines-area-acf',
                                                        'Foot': 'disposition-ccast-venous-arterial-lines-area-foot',
                                                        'Forearm': 'disposition-ccast-venous-arterial-lines-area-forearm',
                                                        'Hand': 'disposition-ccast-venous-arterial-lines-area-hand',
                                                        'Jugular Vein': 'disposition-ccast-venous-arterial-lines-area-jugular',
                                                        'Wrist': 'disposition-ccast-venous-arterial-lines-area-wrist',
                                                        'Lower Leg': 'disposition-ccast-venous-arterial-lines-area-lowerleg',
                                                        'Upper Arm': 'disposition-ccast-venous-arterial-lines-area-upperarm',
                                                    }} /></U>
                                            </li>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-size'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioCvpSize]}
                                                    availableItems={{
                                                        '14G': 'disposition-ccast-venous-arterial-lines-size-14g',
                                                        '16G': 'disposition-ccast-venous-arterial-lines-size-16g',
                                                        '18G': 'disposition-ccast-venous-arterial-lines-size-18g',
                                                    }} /></U>
                                            </li>
                                        </>
                                    )}
                                </Grid>

                                <Grid item md={4} >
                                    <U><MultiSelectionComponent data={[t.ivioVenous ? "venous" : ""]}
                                        availableItems={{
                                            'venous': 'disposition-ccast-venous-arterial-lines-venous',
                                        }} /></U>

                                    {t.ivioVenous && (
                                        <ul>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-line'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioVenousLine]}
                                                    availableItems={{
                                                        'Line 1': 'line 1',
                                                        'Line 2': 'line 2',
                                                    }} /></U>
                                            </li>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-side'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioVenousSide]}
                                                    availableItems={{
                                                        'Left': 'left',
                                                        'Right': 'right',
                                                    }} /></U>
                                            </li>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-area'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioVenousArea]}
                                                    availableItems={{
                                                        'ACF': 'disposition-ccast-venous-arterial-lines-area-acf',
                                                        'Foot': 'disposition-ccast-venous-arterial-lines-area-foot',
                                                        'Forearm': 'disposition-ccast-venous-arterial-lines-area-forearm',
                                                        'Hand': 'disposition-ccast-venous-arterial-lines-area-hand',
                                                        'Jugular Vein': 'disposition-ccast-venous-arterial-lines-area-jugular',
                                                        'Wrist': 'disposition-ccast-venous-arterial-lines-area-wrist',
                                                    }} /></U>
                                            </li>
                                            <li>
                                                <L t={'disposition-ccast-venous-arterial-lines-size'} />
                                                <br />
                                                <U><MultiSelectionComponent data={[t.ivioVenousSize]}
                                                    availableItems={{
                                                        '14G': 'disposition-ccast-venous-arterial-lines-size-14g',
                                                        '16G': 'disposition-ccast-venous-arterial-lines-size-16g',
                                                        '18G': 'disposition-ccast-venous-arterial-lines-size-18g',
                                                        '20G': 'disposition-ccast-venous-arterial-lines-size-20g',
                                                        '22G': 'disposition-ccast-venous-arterial-lines-size-22g',
                                                        '24G': 'disposition-ccast-venous-arterial-lines-size-24g',
                                                    }} /></U>
                                            </li>
                                        </ul>
                                    )}
                                </Grid>
                            </Grid>

                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-ccast-drains'} />
                    <ul>
                        <li>
                            <L t={'disposition-ccast-drains-urinary-catheter-size'} />{': '}<U><NumberOrMinusOneComponent v={t.dTUrinarySize} units='Fr' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-drains-nasogastric-size'} />{': '}<U><NumberOrMinusOneComponent v={t.dTNasogastric} units='Fr' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-drains-abdomnial-type'} />{': '}<U><>{t.dTAbdominalType}</></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-drains-abdomnial-size'} />{': '}<U><NumberOrMinusOneComponent v={t.dTAbdominalSize} units='Fr' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-drains-chest-side'} />
                            <br />
                            <U><MultiSelectionComponent data={t.dTChestSide}
                                availableItems={{
                                    'Left': 'left',
                                    'Right': 'right',
                                }} /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-drains-chest-size'} />{': '}<U><NumberOrMinusOneComponent v={t.dTChestSize} units='Fr' /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-drains-other'} />{': '}<U><>{t.dTOther}</></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-ccast-blood-group'} />
                    <ul>
                        <li>
                            <L t={'disposition-ccast-blood-group-group'} />
                            <br />
                            <U><MultiSelectionComponent data={[t.bloodGroup]}
                                availableItems={{
                                    'A': 'disposition-ccast-blood-group-a',
                                    'B': 'disposition-ccast-blood-group-b',
                                    'AB': 'disposition-ccast-blood-group-ab',
                                    'O': 'disposition-ccast-blood-group-o',
                                }} /></U>
                        </li>
                        <li>
                            <L t={'disposition-ccast-blood-group-rhd'} />
                            <br />
                            <U><MultiSelectionComponent data={[t.bloodRhd]}
                                availableItems={{
                                    'RhD +ve': 'disposition-ccast-blood-rhd-positive',
                                    'RhD -ve': 'disposition-ccast-blood-rhd-negative',
                                }} /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-ccast-infusions'} />
                    <ul>
                        <li><L t={'disposition-ccast-infusions-sedation'} /><ul>
                            <li>
                                <L t={'disposition-ccast-infusions-medazolam'} />
                                <ul>
                                    <li>
                                        <L t={'disposition-ccast-infusions-rate'} />{': '}<U><NumberOrMinusOneComponent v={t.midazolamRate} units=' mls/Hr' /></U>
                                    </li>
                                    <li>
                                        <L t={'disposition-ccast-infusions-conc'} />{': '}<U><NumberOrMinusOneComponent v={t.midazolamConcentration} units='%' /></U>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <L t={'disposition-ccast-infusions-morphine'} />
                                <ul>
                                    <li>
                                        <L t={'disposition-ccast-infusions-rate'} />{': '}<U><NumberOrMinusOneComponent v={t.morphineRate} units='mls/Hr' /></U>
                                    </li>
                                    <li>
                                        <L t={'disposition-ccast-infusions-conc'} />{': '}<U><NumberOrMinusOneComponent v={t.morphineConcentration} units='%' /></U>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <L t={'disposition-ccast-infusions-propofol'} />
                                <ul>
                                    <li>
                                        <L t={'disposition-ccast-infusions-rate'} />{': '}<U><NumberOrMinusOneComponent v={t.propofolRate} units=' mls/Hr' /></U>
                                    </li>
                                    <li>
                                        <L t={'disposition-ccast-infusions-conc'} />{': '}<U><NumberOrMinusOneComponent v={t.propofolConcentration} units='%' /></U>
                                    </li>
                                </ul>
                            </li>
                        </ul></li>
                        <li>
                            <L t={'disposition-ccast-infusions-inotropes'} />
                            <ul>
                                <li>
                                    <L t={'disposition-ccast-infusions-noradrenaline'} />
                                    <ul>
                                        <li>
                                            <L t={'disposition-ccast-infusions-rate'} />{': '}<U><NumberOrMinusOneComponent v={t.noradrenalineRate} units='mls/Hr' /></U>
                                        </li>
                                        <li>
                                            <L t={'disposition-ccast-infusions-conc'} />{': '}<U><NumberOrMinusOneComponent v={t.noradrenalineConcentration} units='%' /></U>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-infusions-adrenaline'} />
                                    <ul>
                                        <li>
                                            <L t={'disposition-ccast-infusions-rate'} />{': '}<U><NumberOrMinusOneComponent v={t.adrenalineRate} units='mls/Hr' /></U>
                                        </li>
                                        <li>
                                            <L t={'disposition-ccast-infusions-conc'} />{': '}<U><NumberOrMinusOneComponent v={t.adrenalineConcentration} units='%' /></U>
                                        </li>
                                    </ul>
                                </li>
                            </ul></li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-ccast-blood-results'} />
                    <ul>
                        <li>
                            <L t={'disposition-ccast-blood-results-haemotology'} />
                            <ul>
                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-hb'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodHb} units='G/DL' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-plt'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodPlt} /></U>
                                </li>

                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-hct'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodHct} units='G/DL' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-wcc'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodWcc} /></U>
                                </li>

                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-inr'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodInr} /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-pt'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodPt} /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-aptt'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodAptt} /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-haemotology-fib'} />{': '}<U><NumberOrMinusOneComponent v={t.bloodFib} /></U>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'disposition-ccast-blood-results-biochemistry'} />
                            <ul>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-sodium'} />{': '}<U><NumberOrMinusOneComponent v={t.bioSodium} units='mmol/L' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-potassium'} />{': '}<U><NumberOrMinusOneComponent v={t.bioPotassium} units='mmol/L' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-urea'} />{': '}<U><NumberOrMinusOneComponent v={t.bioUrea} units='mmol/L' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-creatinine'} />{': '}<U><NumberOrMinusOneComponent v={t.bioCreatinine} units='mmol/L' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-glucose'} />{': '}<U><NumberOrMinusOneComponent v={t.bioGlucose} units='mmol/L' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-calcium'} />{': '}<U><NumberOrMinusOneComponent v={t.bioCalcium} units='mmol/L' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-phosphate'} />{': '}<U><NumberOrMinusOneComponent v={t.bioPhosphate} units='mmol/L' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-biochemistry-magnesium'} />{': '}<U><NumberOrMinusOneComponent v={t.bioMagnesium} units='mmol/L' /></U>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <L t={'disposition-ccast-blood-results-gases'} />
                            <ul>
                                <li>
                                    <L t={'disposition-ccast-blood-results-gases-fio2'} />{': '}<U><NumberOrMinusOneComponent v={t.gasFio2} units='mmHg' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-gases-po2'} />{': '}<U><NumberOrMinusOneComponent v={t.gasPo2} units='mmHg' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-gases-pco2'} />{': '}<U><NumberOrMinusOneComponent v={t.gasPco2} units='mmHg' /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-gases-ph'} />{': '}<U><NumberOrMinusOneComponent v={t.gasPh} /></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-gases-hco3'} />{': '}<U><NumberOrMinusOneComponent v={t.gasHco3} units='mmol/L' /></U>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'disposition-ccast-blood-results-microbiology'} />
                            <ul>
                                <li>
                                    <L t={'disposition-ccast-blood-results-microbiology-site'} />{': '}<U><>{t.microSite}</></U>
                                </li>
                                <li>
                                    <L t={'disposition-ccast-blood-results-microbiology-culture'} />{': '}<U><>{t.microCulture}</></U>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    );

}
