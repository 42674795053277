import React from "react";
import { Avatar } from "@mui/material";
import { red, yellow, green, blue, grey } from "@mui/material/colors"

function BasePriorityBadge(p: { color: string, text: string }) {
    return <Avatar sx={{ bgcolor: p.color }}>{p.text}</Avatar>
}

export function PriorityBadge(p: { priorityId: number }) {
    switch (+p.priorityId) {
        case 0:
            return <BasePriorityBadge color={red[500]} text="T1"></BasePriorityBadge>;
        case 1:
            return <BasePriorityBadge color={yellow[500]} text="T2"></BasePriorityBadge>;
        case 2:
            return <BasePriorityBadge color={green[500]} text="T3"></BasePriorityBadge>;
        case 3:
            return <BasePriorityBadge color={blue[500]} text="T4"></BasePriorityBadge>;
        case 4:
            return <BasePriorityBadge color={grey[900]} text="D"></BasePriorityBadge>;
        case -1:
                return <BasePriorityBadge color={grey[400]} text="?"></BasePriorityBadge>;
        default:
            return <BasePriorityBadge color={grey[400]} text={p.priorityId?.toString()}></BasePriorityBadge>;
    }
}
