import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import U from './UpdateItemIndicator';
import L from "./localeText";

export function CarePlanComponent(p: { carePlan: string }) {

    const c = p.carePlan || "";

    const s = c.split("\n");

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'care-plan-title'} /> </Typography>} />

            <CardContent>
                {c && (
                    <ul>
                        <U><>{s.map((x, i) => <p key={`line${i}`} >{x}</p>)}</></U>
                    </ul>)}
            </CardContent>
        </Card>
    );
}
