import { PmrTwo } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import { MultiSelectionComponent } from "./MultiSelectionComponent";
import DateTimeRender from "./DateTimeRenderer";


export function PmrTwoComponent(p: { t: PmrTwo | null; }) {
    const t = p.t;
    return (t &&
        <li>
            <L t={'disposition-pmrtwo-title'} />
            <ul>
                <li>
                    <L t={'disposition-pmrtwo-dtg'} />{': '}
                    <ul>
                        <li>
                            <U><DateTimeRender value={t.dTG} format='date' /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrtwo-pmrone'} /><U><></></U>
                </li>

                <li>
                    <L t={'disposition-pmrtwo-classification-and-dependency'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrtwo-classification'} />
                            <br />
                            <U><MultiSelectionComponent data={[t.classificationGroup]}
                                availableItems={{
                                    '1a - Severe Psychiatric': 'disposition-pmrtwo-severe-psychiatric',
                                    '1b - Intermediate Psychiatric': 'disposition-pmrtwo-intermediate-psychiatric',
                                    '1c - Mildly Psychiatric - Escorted': 'disposition-pmrtwo-mildly-psychiatric-escorted',
                                    '1c - Mildly Psychiatric - Unescorted': 'disposition-pmrtwo-mildly-psychiatric-unescorted',
                                    '2a - Stretcher - Immobile - Escorted': 'disposition-pmrtwo-stretcher-immobile',
                                    '2b - Stretcher - Mobile - Escorted': 'disposition-pmrtwo-stretcher-mobile',
                                    '3a - Sitting - Immobile - Escorted': 'disposition-pmrtwo-sitting-immobile',
                                    '3b - Sitting - Mobile - Escorted': 'disposition-pmrtwo-sitting-mobile',
                                    '4 - Walking - Unescorted': 'disposition-pmrtwo-walking',
                                    '5 - Outpatient - Unescorted': 'disposition-pmrtwo-outpatient',
                                    '6 - Civilian': 'disposition-pmrtwo-civilian',
                                }} /></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-dependency'} />
                            <br />
                            <U><MultiSelectionComponent data={[t.classificationDependency]}
                                availableItems={{
                                    '1 - High': 'disposition-pmrtwo-dependency-high',
                                    '2 - Medium': 'disposition-pmrtwo-dependency-medium',
                                    '3 - Low': 'disposition-pmrtwo-dependency-low',
                                    '4 - Minimal': 'disposition-pmrtwo-dependency-minimal'
                                }} />
                            </U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrtwo-full-clinical-details'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrtwo-atmist'} />{': '}<U><>{t.clinicalAtmist}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-investigations'} />{': '}<U><>{t.clinicalInvestigations}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-vte-risk'} />{': '}
                            <ul>
                                <li>
                                    <U><MultiSelectionComponent data={[t.clinicalVteRiskYes ? "Yes" : "", t.clinicalVteRiskNo ? "No" : ""]}
                                        availableItems={{ "Yes": 'yes', "No": 'no' }} />
                                    </U>
                                    {t.clinicalVteRiskYes && (
                                        <U><li>{t.clinicalVteMeasures}</li></U>
                                    )}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrtwo-flight-considerations'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrtwo-flight-fully-mobile'} />
                            <U><MultiSelectionComponent data={[t.flightMobileYes ? "Yes" : "", t.flightMobileNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-flight-carry-bag'} />
                            <U><MultiSelectionComponent data={[t.flightCarryYes ? "Yes" : "", t.flightCarryNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-flight-escort'} />
                            <U><MultiSelectionComponent data={[t.flightClearYes ? "Yes" : "", t.flightClearNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-flight-wheelchair'} />
                            <U><MultiSelectionComponent data={[t.flightWheelYes ? "Yes" : "", t.flightWheelNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrtwo-infection-details'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrtwo-infection-fever'} />
                            <U><MultiSelectionComponent data={[t.infectionFeverYes ? "Yes" : "", t.infectionFeverNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-infection-diarrhoea'} />
                            <U><MultiSelectionComponent data={[t.infectionDiarrhoeaYes ? "Yes" : "", t.infectionDiarrhoeaNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-infection-vomiting'} />
                            <U><MultiSelectionComponent data={[t.infectionVomitingYes ? "Yes" : "", t.infectionVomitingNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-infection-rash'} />
                            <U><MultiSelectionComponent data={[t.infectionRashYes ? "Yes" : "", t.infectionRashNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-infection-cough'} />
                            <U><MultiSelectionComponent data={[t.infectionCoughYes ? "Yes" : "", t.infectionCoughNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-infection-risk'} />
                            <U><MultiSelectionComponent data={[t.infectionYes ? "Yes" : "", t.infectionNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-infection-mrsa'} />
                            <U><MultiSelectionComponent data={[t.infectionMrsaYes ? "Yes" : "", t.infectionMrsaNo ? "No" : ""]}
                                availableItems={{ "Yes": 'positive', "No": 'negative' }} />
                            </U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrtwo-dah-details'} />
                    <ul>
                        <li>
                            <U><MultiSelectionComponent data={[t.disposition]}
                                availableItems={{
                                    'RCDM': 'disposition-pmrtwo-disposition-rcdm',
                                    'ISP Psychiatry': 'disposition-pmrtwo-disposition-isp-psychiatry',
                                    'Headley Court': 'disposition-pmrtwo-dispostion-headley-court',
                                    'NHS Unit': 'disposition-pmrtwo-disposition-nhs-unit',
                                }} /></U>
                        </li>
                        <li>
                            <L t={'dispostion-pmrtwo-disposition-name'} />{': '}{t.dispositionName}
                        </li>
                        <li>
                            <L t={'dispostion-pmrtwo-disposition-location'} />{': '}{t.dispositionLocation}
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrtwo-medical-escort-details'} />
                    <ul>
                        <li>
                            <U><MultiSelectionComponent data={[t.escortYes ? "Yes" : "", t.escortNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                        </li>
                        {t.escortYes && (
                            <>
                                <li>
                                    <L t={'disposition-pmrtwo-type'} />{': '}<U><>{t.escortType}</></U>
                                </li>
                                <li>
                                    <L t={'disposition-pmrtwo-location'} />{': '}<U><>{t.escortLocation}</></U>
                                </li>
                                <li>
                                    <L t={'disposition-pmrtwo-treating'} />{': '}<U><>{t.escortHospital}</></U>
                                </li>
                                <li>
                                    <L t={'disposition-pmrtwo-mo-ic'} />{': '}<U><>{t.escortMo}</></U>
                                </li>
                                <li>
                                    <L t={'disposition-pmrtwo-contact-hospital-no'} />{': '}<U><>{t.escortHContact}</></U>
                                </li>
                                <li>
                                    <L t={'disposition-pmrtwo-contact-mo-no'} />{': '}<U><>{t.escortMContact}</></U>
                                </li>
                                <li>
                                    <L t={'disposition-pmrtwo-contact-patient-no'} />{': '}<U><>{t.escortPContact}</></U>
                                </li>
                            </>
                        )}
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrtwo-signature'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrtwo-signature-rank'} />{': '}<U><>{t.sigRank}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-signature-name'} />{': '}<U><>{t.sigName}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrtwo-signature-sig'} /><br /><U>
                                <img alt='signature' width={'100%'} src={`data:image/jpeg;base64,${t.signature}`} /></U>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    );
}
