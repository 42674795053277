export const TRANSLATIONS_CZ = {
    "yes" : "Ano",
    "no" : "Ne",

    "name": "Jméno",
    "gender": "Pohlaví",
    "zap":"zap",

    "dashboard-title": "Přístrojová deska",

    "active cases": "Aktivní případy",
    "historic cases": "Historické případy",

    "ample":"AMPLE",
    "allergies":"Alergie",
    "past medical history":"Past Medical History",
    "medications":"Medikace",
    "no ample details recorded.": "No Ample details recorded.",
    "asprin":"Asprin",
    "carbamazepine":"Carbamazepine",
    "cephalosporin" : "Cephalosporin",
    "codeine" : "Kodein" ,
    "insulin" : "Inzulín",
    "latex" : "Latex",
    "nsaid" : "Nsaid",
    "penicillin" : "Penicillin",
    "phenytoin" : "Phenytoin",
    "sulphonamide" : "Sulphonamide",
    "tetracycline" : "Tetracycline",
    "other" : "Jiná",
    "none" : "Žádný",
    "unknown" : "Neznámý",
    "no medical history recorded.":"No Medical History recorded.",
    "last oral intake":"Last Oral Intake",
    "event": "Event",
    "hours": "Hours",


    "Cancer":"Rakovina",
    "cva":"CVA (cerebrovaskulární příhoda)",
    "diabetes" : "Cukrovka",
    "epilepsy":"Epilepsie",
    "chronic heart condition":"Onemocnění srdce",
    "hypertension":"Vysoký tlak",
    "chronic lung condition":"Onemocnění plic",
    "mental illness":"Duševní nemoc",
    "physically disabled":"Invalidní",
    "pregnant":"Těhotná",
    "myocardial infarction":"Myokardiální",


    "chooseLocale": "Zvolte jazyk",
    "close": "Zavřít",
    "uk": "Spojené království",
    "unitedstates" : "Spojené státy",
    "german": "Německy",
    "french": "Francouzsky",
    "czech": "Česká Republika",
    "spain": "Španělsky",

    "label-vitals-time":"Čas",
    "label-vitals-vital-sign":"Měření",
    "label-vitals-value": "Měření",
    "vitals-label-o2History":"Nasycení kyslíkem",
    "vitals-label-systolicBp": "Krevní tlak",
    "vitals-label-hrHistory": "Tepová frekvence",
    "vitals-label-skintemphistory": "Teplota kůže",
    "vitals-label-coretemphistory": "teplota jádra",

    "vitals-label-rrHistory": "Dechová frekvence",
    "vitals-label-bgHistory": "Glukóza v krvi"



   };