import { useContext, useEffect } from 'react';
import { Card, CardContent, CardHeader, Container, Typography } from "@mui/material";
import U from './UpdateItemIndicator';
import L from "./localeText";
import { MultiSelectionComponent } from "./MultiSelectionComponent";
import { CycleComponent } from "./CycleComponent";
import { AzureClientContext } from '../providers/azureClientProvider';
import { FetchCaseImagery } from "./FetchCaseImagery";
import { CaseDetail } from "../models/Cases";
import { MechanismDetails } from './MechanismDetails';

export function IncidentComponent(p: { c: CaseDetail }) {

    const t = p.c.incident;
    const { dispatch } = useContext(AzureClientContext);

    useEffect(() => {
        dispatch({ type: 'caseSelectedChange', data: p.c._id })

        const interval = setInterval(()=> {
            dispatch({type:'refreshCaseData', data: p.c._id})            
        },10000)

        return () => clearInterval(interval)
    }, [p.c._id, dispatch])

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'incident-title'} /> </Typography>} />

            <CardContent>
                {t && (
                    <>
                    <ul>
                        <li>
                            <U><><L t='incident-location-title' />: <L t={'incident-location-lat'} />&nbsp;{t.latitude},&nbsp;<L t={'incident-location-lng'} />&nbsp;{t.longitude}</></U>
                        </li>
                        <li>
                            <U><><L t={'incident-address'} />{`: ${t?.addressLineOne ?? ''}, ${t?.addressLineTwo ?? ''}, ${t?.postcode ?? ''}`}</></U>
                        </li>
                        <li>
                            <U><><L t={'incident-details'} />{`: ${t?.details ?? ''}`}</></U>
                        </li>
                        <li>
                            <U><><L t={'incident-missiontype'} /><div><MissionTypeDetails data={t.missionType} /></div>
                                <div><U><><L t={'incident-missiontype-notes'} />{`: ${t?.missionTypeNotes ?? ''}`}</></U></div></></U>
                        </li>
                        <li>
                            <U><><L t={'incident-mechanism'} /><div><MechanismDetails data={t.mechanism} /></div></></U>
                            <U><><div><L t={'incident-mechanism-notes'} />{`: ${t?.mechanismNotes ?? ''}`}</div></></U>
                        </li>
                        <li>
                            <U><><L t={'incident-alert'} /><div><AlertDetails data={[t.alert]} /></div></></U>
                        </li>
                        <li>
                            <U><><L t={'incident-casualty-type'} /><div><CasualtyTypeDetails data={t.casualtyType} /></div></></U>
                        </li>
                        <li>
                            <U><><L t={'incident-evac-priority'} /><div><EvacuationPriorityDetails data={t.evacPriority} /></div></></U>
                        </li>
                        
                    </ul>
                    <Container>
                        <FetchCaseImagery selectedCase={p.c} dispatch={dispatch} filterToImageTypes={['Incident']} />
                    </Container>
                    </>
                )}
                {!t && (<Typography component="body"><L t={"no-incident-details-recorded"} /></Typography>)}
            </CardContent>
        </Card>
    )
}

function MissionTypeDetails(p: { data: string[] }) {
    return <MultiSelectionComponent data={p.data} availableItems={{ Medical: 'medical', Pregnancy: 'pregnancy', Psychiatric: 'psychiatric', Rescue: 'rescue', Trauma: 'trauma', Other: 'other' }} />
}

function AlertDetails(p: { data: string[] }) {
    return <MultiSelectionComponent data={p.data} availableItems={{ 'Cardiac Arrest': 'cardiac-arrest', CVA: 'cva', 'Respiratory Arrest': 'respiratory-arrest',
         Sepsis: 'sepsis', Trauma: 'trauma' }} />
}

function CasualtyTypeDetails(p: {data: string} ) {
    return <CycleComponent data={p.data} availableItems={
        {Friendly:{text:'friendly', sx:{color:'white', bgcolor:'blue'}},
        'Kilo Adult':{text:'kilo-adult', sx:{bgcolor:'yellow'}},
        'Kilo Child':{text:'kilo-child', sx:{color:'white', bgcolor:'green'}},
        'X-Ray':{text:'xray', sx:{color:'white', bgcolor:'red'}},
        }} />
}

function EvacuationPriorityDetails(p: {data: string} ) {
    return <CycleComponent data={p.data} availableItems={
        {'T1-Immediate':{text:'t1-immediate', sx:{color:'white', bgcolor:'red'}},
        'T2-Delayed':{text:'t2-delayed', sx:{bgcolor:'yellow'}},
        'T3-Minimal':{text:'t3-minimal', sx:{color:'white', bgcolor:'green'}},
        'T4-Expectant':{text:'t4-expectant', sx:{color:'white', bgcolor:'blue'}},
        'T5-Dead':{text:'t5-dead', sx:{color:'white', bgcolor:'black'}},
        }} />
}


