import React from "react";
import { Avatar } from "@mui/material";
import { red, yellow, green, grey } from "@mui/material/colors"

function BaseTriageSortBadge(p: { color: string, text: string, textColor:string }) {
    return <Avatar sx={{ bgcolor: p.color, color: p.textColor }}>{p.text}</Avatar>
}

export function TriageSortBadge(p: { triageSort: string }) {
    switch (p.triageSort) {
        case "T1":
            return <BaseTriageSortBadge color={red[500]} text={p.triageSort} textColor={grey[900]} ></BaseTriageSortBadge>;
        case "T2":
            return <BaseTriageSortBadge color={yellow[500]} text={p.triageSort}  textColor={grey[900]} ></BaseTriageSortBadge>;
        case "T3":
            return <BaseTriageSortBadge color={green[500]} text={p.triageSort}  textColor={grey[900]} ></BaseTriageSortBadge>;
        case "DEAD":
            return <BaseTriageSortBadge color={grey[900]} text={p.triageSort}  textColor={grey[50]}></BaseTriageSortBadge>;
        default:    
            return <BaseTriageSortBadge color={grey[400]} text={p.triageSort}  textColor={grey[50]}></BaseTriageSortBadge>;
    }
}
