import { Card, CardContent, CardHeader, Stack, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Vitals, VitalsHistory } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import DateTimeRender from "./DateTimeRenderer";
import { CycleComponent } from "./CycleComponent";
import { LineGraphComponent } from "./LineGraphComponent";

function VitalsComponent(p: { v: Vitals, h:VitalsHistory }) {

    const v = p.v;
    const h = p.h;

    return (
        <Card variant='outlined'>
            <CardHeader title={<Typography component="h1"><L t="Vital Signs" /> </Typography>} />

            <CardContent>
                {v && (
                    <Stack>
                       <VitalsDisplay v={v} h={h} />     
                    </Stack>
                )}
                {!v && (<Typography component="body"><L t="No Vital Signs recorded." /></Typography>)}
            </CardContent>
        </Card>
    )
}

const VitalsDisplay = (p:{v:Vitals, h:VitalsHistory})=> {
    const h= p.h

    let x: { [index: string]: { time?: Date, value?: number } } = {};
        if (h.bgHistory  && h.bgHistory.length) {
            let l = h.bgHistory.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.bgHistory[0] );
            x["bgHistory"] = {time:l.lastDate, value: l.bloodGlucose }
        }
        if (h.bpHistory  && h.bpHistory.length) {
            let l = h.bpHistory.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.bpHistory[0] );
            x["diastolicBp"] = {time:l.lastDate, value: l.diastolic }
            x["systolicBp"] = {time:l.lastDate, value: l.systolic }
        }
        
        if (h.o2History  && h.o2History.length) {
            let l = h.o2History.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.o2History[0] );
            x["o2History"] = {time:l.lastDate, value: l.spo2 }
        }
        if (h.hrHistory  && h.hrHistory.length) {
            let l = h.hrHistory.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.hrHistory[0] );
            x["hrHistory"] = {time:l.lastDate, value: l.hr }
        }
        // skin temp
        if (h.tempHistory && h.tempHistory.length) {
            let c=  h.tempHistory.filter(t=>t.tempType == 0)
            let l = c.reduce((v,c)=> v.lastDate < c.lastDate ? c: v, c[0] );
            if (l) {
                x["skinTempHistory"] = {time:l.lastDate, value: l.temperature }
            }
        }
        // core temp
        if (h.tempHistory && h.tempHistory.length) {
            let c = h.tempHistory.filter((t)=>t.tempType == 1)
            let l = c.reduce((v,c) => v.lastDate < c.lastDate ? c: v, c[0] );
            if (l) {
                x["coreTempHistory"] = {time:l.lastDate, value: l.temperature }
            }
        }


        if (h.rrHistory && h.rrHistory.length) {
            let l = h.rrHistory.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.rrHistory[0] );
            x["rrHistory"] = {time:l.lastDate, value: l.rr }
        }

        if (h.avpuHistory && h.avpuHistory.length) {
            let l = h.avpuHistory.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.avpuHistory[0] );
            x["avpu"] = {time:l.lastDate, value: l.avpu }
        }

        if (h.capRHistory && h.capRHistory.length) {
            let l = h.capRHistory.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.capRHistory[0] );
            x["capR"] = {time:l.lastDate, value: l.capR }
        }
        if (h.co2History && h.co2History.length) {
            let l = h.co2History.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.co2History[0] );
            x["co2"] = {time:l.lastDate, value: l.CO2 }
        }
        if (h.etco2History && h.etco2History.length) {
            let l = h.etco2History.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.etco2History[0] );
            x["etco2"] = {time:l.lastDate, value: l.etCO2 }
        }
        if (h.fico2History && h.fico2History.length) {
            let l = h.fico2History.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.fico2History[0] );
            x["fico2"] = {time:l.lastDate, value: l.fiCO2 }
        }
        if (h.ambientetco2History && h.ambientetco2History.length) {
            let l = h.ambientetco2History.reduce((v,c)=>v.lastDate < c.lastDate ? c: v, h.ambientetco2History[0] );
            x["ambientetco2"] = {time:l.lastDate, value: l.etCO2 }
        }



    const keys = Object.keys(x);
    let e = keys.map((v) => {
        let val: any = x[v].value;
        if (v === 'diastolicBp') return null;

        if (v === 'systolicBp') {
            let dia = (x['diastolicBp'] || { value: '' }).value;
            val = `${val}/${dia}`;
        }
        else if (v ==='avpu') {
            val = val != undefined ? <CycleComponent availableItems={{0:{text:' A '},1:{text:' V '},2:{text:' P '},3:{text:' U '}}} data={val.toString()} /> : <></>
        }
        else if (v ==='capR') {
            val = (val == undefined || val == -1) ? <></> :  val == 0  ? <L t="No" /> : <L t="Yes" /> 
        } else if (v ==='coreTempHistory' && !isNaN(val) ) {
            val = val.toFixed(1)    
        } else if (v ==='skinTempHistory' && !isNaN(val) ) {
            val = val.toFixed(1)    
        } else if (v ==='bgHistory' && !isNaN(val) ) {
            val = val.toFixed(1)            
        } else if ( (v === 'etco2' || v ==='co2' || v === 'fico2' || v === 'ambientetco2') && !isNaN(val)) {
            val = val.toFixed(1)
        } else {
            val = `${val}`;
        }

        let d = x[v].time ? x[v].time : undefined;

        if (val.val1 === -1) return null; 

        return <TableRow key={v} ><TableCell><U><DateTimeRender format="evt" value={d} /></U></TableCell><TableCell><U><L t={'vitals-label-' + v}  /></U></TableCell><TableCell><U>{val}</U></TableCell></TableRow>
    });    
    return <div><table>
        <TableHead>
            <TableRow style={{textAlign:'left'}}>
            <TableCell><L t="label-vitals-time" /></TableCell>
            <TableCell><L t="label-vitals-vital-sign" /></TableCell>
            <TableCell><L t="label-vitals-value"  /></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>{e}
        </TableBody>
    </table>
    {false && <VitalSignGraphsBuilder h= {h} />}
    </div>
}

function VitalSignGraphsBuilder(p:{h:VitalsHistory}) {

    return <>
    {BuildCO2Graph(p.h) }
    </>;

}
function BuildCO2Graph(h:VitalsHistory) {

    const etco2 = (h.etco2History||[]).filter(c=>c.lastDate).sort((a,b)=>a.lastDate.getTime() - b.lastDate.getTime() )
    const fico2 = (h.fico2History||[]).filter(c=>c.lastDate).sort((a,b)=>a.lastDate.getTime() - b.lastDate.getTime() )
    const ambco2 = (h.ambientetco2History||[]).filter(c=>c.lastDate).sort((a,b)=>a.lastDate.getTime() - b.lastDate.getTime() )
    //const ambco2 = (h.etco2History||[]).filter(c=>c.lastDate).sort((a,b)=>a.lastDate.getTime() - b.lastDate.getTime() )

    let etco2Data:{id:string, color:string, data?:{x:Date, y:number|null}[]} = {id:"ETCO2", color: "purple"}
    let fico2Data:{id:string, color:string, data?:{x:Date, y:number|null}[]} =  {id:"FICO2", color: "blue"}
    let ambco2Data:{id:string, color:string, data?:{x:Date, y:number|null}[]} =  {id:"ambCO2", color: "orange"}
    let etco2LineData:{[x:number]:number|null} = {}
    let fico2LineData:{[x:number]:number|null} = {}
    let ambco2LineData:{[x:number]:number|null} = {}
    let xV:{[x:number]:number|null} = {}

    if (etco2.length > 0) {
        const lastetco2Date = etco2.slice(-1)[0].lastDate;
        const last60 = new Date(lastetco2Date.setMinutes(lastetco2Date.getMinutes()-1))

        etco2LineData = etco2.filter(x=>x.lastDate>=last60).reduce((a,e)=>  {a[e.lastDate.getTime()]=e.etCO2; return a}, etco2LineData )
        xV = {...xV, ...etco2LineData} ;
    } 
    if (fico2.length > 0) {
        const lastfico2Date = fico2.slice(-1)[0].lastDate;
        const last60 = new Date(lastfico2Date.setMinutes(lastfico2Date.getMinutes()-1))

        fico2LineData = fico2.filter(x=>x.lastDate>=last60).reduce((a,e)=>  {a[e.lastDate.getTime()]=e.fiCO2; return a}, fico2LineData )
        xV = {...xV, ...fico2LineData} ;
    }
    if (ambco2.length > 0) {
        const lastambco2Date = ambco2.slice(-1)[0].lastDate;
        const last60 = new Date(lastambco2Date.setMinutes(lastambco2Date.getMinutes()-1))

        ambco2LineData = ambco2.filter(x=>x.lastDate>=last60).reduce((a,e)=>  {a[e.lastDate.getTime()]=e.etCO2; return a}, ambco2LineData )
        xV = {...xV, ...ambco2LineData} ;
    }

    for(var i in xV) xV[i] = null;

    const fi = {...xV , ...fico2LineData};
    const et = {...xV , ...etco2LineData};
    const amb = {...xV , ...ambco2LineData}

    fico2Data.data = Object.entries(fi).map(v=> { return {x:new Date(+v[0]),y:v[1]}} ).sort((a,b)=>a.x.getTime() - b.x.getTime() )
    etco2Data.data = Object.entries(et).map(v=> { return {x:new Date(+v[0]),y:v[1]}} ).sort((a,b)=>a.x.getTime() - b.x.getTime() )
    ambco2Data.data = Object.entries(amb).map(v=> { return {x:new Date(+v[0]),y:v[1]}} ).sort((a,b)=>a.x.getTime() - b.x.getTime() )

    //fico2Data.data = fico2Data.data.filter(f=>f.x != null);
    //etco2Data.data = etco2Data.data.filter(f=>f.x != null);
    //ambco2Data.data = ambco2Data.data.filter(f=>f.x != null);

    //console.log(fico2Data.data)
    //console.log(etco2Data.data)

    const d = []
    d.push(etco2Data)
    d.push(fico2Data)
    d.push(ambco2Data)

    if (d.length > 0) {    
        return <div><Typography component='h2'><span>CO2 trends</span></Typography><div style={{height:'500px',width:'100%'}}><LineGraphComponent data={d} /></div></div>
    } else {
        return <></>
    }
}



export default VitalsComponent;
