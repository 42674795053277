import './App.css';
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";

import UnauthenticatedLayout from './views/UnauthenticatedLayout'
import { AppContextConsumer, AppContextProvider } from './providers/appContextProvider';
import LoginPage from './views/LoginPage';
import AuthenticatedLayout from './views/AuthenticatedLayout';


function App() {
  return (
    <div className="App">
      <AppContextProvider>
        <BrowserRouter >
          <AppContextConsumer>
            {({ state, dispatch }) => {
              return state.user ?
                <AuthenticatedLayout />
                : (
                  <>
                    <UnauthenticatedLayout >
                      <Routes>
                        <Route path="/*" element={<LoginPage />} />
                      </Routes>
                    </UnauthenticatedLayout>
                  </>

                );
            }}
          </AppContextConsumer>
        </BrowserRouter>
      </AppContextProvider>
    </div>
  );
}

export default App;
