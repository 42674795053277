import * as React from 'react';
import L from "./localeText";
import { Chip, ToggleButton, ToggleButtonGroup, } from '@mui/material';
import DateTimeRenderer from './DateTimeRenderer';
import { useEffect, useState } from 'react';
import { ECGMeasurement } from '../models/Cases';
import { useTranslation } from 'react-i18next';


function renderECG(caseId:string, ecgMeasurement:ECGMeasurement, ecg: ArrayBuffer | null) {
    return <>
        <div>
            <L t="ecg-date-recorded" />:&nbsp;
            <DateTimeRenderer value={ecgMeasurement.timeOfMeasurement} format="evt" />
            &nbsp;
            <span><L t="ecg-duration" /*data={{val1:ecg.duration }}*/ />:&nbsp;{ecgMeasurement.duration}&nbsp;seconds</span>
        </div>

        <div>
            <ECGGraphRender ecgData={ecg} ecg={ecgMeasurement}/>
        </div>

        <div>
            <SelectedECGRhythms selectedRhythms={ecgMeasurement.selectedRhythms} />
            <p><L t="ecg-report-notes" />:&nbsp;{ecgMeasurement.findings}</p>
        </div>
        </>;
}

function ECGGraphRender (p:{ ecg: ECGMeasurement, ecgData:ArrayBuffer | null }) {

    const [fetchError,setFetchError] = useState(null);
    const [signalToggles, setSignalToggles] = useState<SignalToggleValues>({I:1, II:1, III:1,V1:1,V2:1,V3:1,V4:1,V5:1,V6:1,aVR:1, aVL:1, aVF:1});

    /*shouldComponentUpdate(nextProps: ECGGraphRenderProps, nextState: ECGGraphRenderState) {
        let oldToggleState = Object.keys(this.state.signalToggles).reduce((p, k) => p + (this.state.signalToggles[k]?? "n" ).toString(), '');
        let newToggleState = Object.keys(nextState.signalToggles).reduce((p, k) => p + (nextState.signalToggles[k]??"n").toString(), '');
        console.log(newToggleState, oldToggleState);
        return (this.state.ecgData != nextState.ecgData) || (this.state.fetchError != nextState.fetchError) || (oldToggleState != newToggleState);   
    }*/
    
    if (fetchError) {
        return <div id={p.ecg._id} min-height="200px" min-width="100%" style={{ textAlign: 'center', verticalAlign: 'middle' }} >
            Error fetching ECG data - {fetchError}
            </div>

    }
    if (p.ecgData == null) {
        return <div id={p.ecg._id} min-height="200px" min-width="800px" style={{ textAlign: 'center', verticalAlign: 'middle' }} >
            Fetching ECG data....
            </div>

    } else {
        const graphCanvasId = 'graph_' + p.ecg._id;
        const divId = 'div_' + p.ecg._id;

        const rv = <div /*style={{ width:"100%"}}*/>
            <div id={divId} ></div>
            <ECGGSignalToggles signals={signalToggles} signalTogglesChanged={(newSignals: SignalToggleValues) => setSignalToggles(newSignals) } />
            <div style={{height:"300px", overflow:"auto"}}  >
            <canvas id={graphCanvasId} height="300px"  />
            </div>
        </div>;

        setTimeout(() => {
            drawECGGraphs(graphCanvasId, divId, p.ecgData, signalToggles);
        }, 10);

        return rv;
    }

}

type SignalToggleValues = {
    [i:string]:number | undefined, 
    I?: number,
    II?: number,
    III?: number,
    aVR?: number,
    aVL?: number,
    aVF?: number,
    V1?: number,
    V2?: number,
    V3?: number,
    V4?: number,
    V5?: number,
    V6?: number,
   
}
function ECGGSignalToggles(p:{ signals: SignalToggleValues, signalTogglesChanged:(newSignals:SignalToggleValues)=>void }) {

    const [signals,setSignals] = useState(p.signals);

    const toggles = Object.keys(signals).map((k) => {
        const v = signals[k]; const disabled = v == null; const checked = v === 1;

        return <ToggleButton disabled={disabled} selected={checked} value={k} 
        onChange={(e: any) => {
            const n:{[index:string]:number} = {};
            
            n[k]= e.target.selected ? 1 : 0;

            setSignals((prev)=> { const newState =  { ...prev, ...n };p.signalTogglesChanged(newState); return newState;})
        }}
        
        >{k}</ToggleButton>;
    });

    return <div><ToggleButtonGroup value={Object.keys(signals).filter(k=>signals[k] === 1 ? 'k': '')} onChange={(event: React.MouseEvent<HTMLElement>,newValues:string[])=>{
        const newState = Object.keys(signals).reduce<{[index:string]:number}>(
            (a:{[index:string]:number},k)=> {a[k] = newValues.includes(k) ? 1 :0; return a; }, {});
        setSignals(newState);
        p.signalTogglesChanged(newState)}

    } >{toggles}</ToggleButtonGroup></div>;
}

function drawECGGraphs(canvasId: string, divId:string, ecgData: ArrayBuffer|null, signalToggles:SignalToggleValues) {
    if (canvasId && ecgData) {
        const element: HTMLCanvasElement | null = document.getElementById(canvasId) as (HTMLCanvasElement);
        const div: HTMLDivElement | null = document.getElementById(divId) as (HTMLDivElement);
        const ctxt = element.getContext('2d');
        if (ctxt) {
            ctxt.scale(window.devicePixelRatio, window.devicePixelRatio);
            element.style.width = "100%"
            element.width = element.offsetWidth;
            let canvasHeight = 400;
            const canvasWidth = element.width;            

            const stream = { buffer: ecgData, pos: 0 };

            const version = readDataAsString(stream, 8);
            const patient = readDataAsString(stream, 80);
            const recording = readDataAsString(stream, 80);
            const date = readDataAsString(stream, 8);
            const hour = readDataAsString(stream, 8);
            const headerSize = readDataAsInt(stream, 8);
            stream.pos += 44;
            const nRecords = readDataAsInt(stream, 8);
            const duration = readDataAsInt(stream, 8);
            let nSignals = readDataAsInt(stream, 4);
            const channelLabels = readDataAsString(stream, nSignals * 16);
            stream.pos += (nSignals * (80 + 8));
            const pMins = new Float32Array(15);
            const pMaxs = new Float32Array(15);
            const dMins = new Uint32Array(15);
            const dMaxs = new Uint32Array(15);
            const freqs = new Uint32Array(15);


            for (let i = 0; i < nSignals; i++)
            {
                pMins[i] = readDataAsFloat(stream, 8);
            }
            for (let i = 0; i < nSignals; i++)
            {
                pMaxs[i] = readDataAsFloat(stream, 8);
            }
            for (let i = 0; i < nSignals; i++)
            {
                dMins[i] = readDataAsInt(stream, 8);
            }
            for (let i = 0; i < nSignals; i++)
            {
                dMaxs[i] = readDataAsInt(stream, 8);
            }
            stream.pos += (nSignals * 80);

            for (let i = 0; i < nSignals; i++)
            {
                freqs[i] = readDataAsInt(stream, 8);
            }
            stream.pos += (nSignals * 32); // Reserved

            let size = 0;
            for (let i = 0; i < nSignals; i++)
            {
                size += 2 * freqs[i] * nRecords;
            }
            const data = new DataView(stream.buffer,stream.pos, stream.buffer.byteLength - stream.pos);

            const grid = canvasHeight / 54.0;
            const ecg_height = grid * 51;

            const height = 50 * grid;
            const topMargin = 15.0;
            const signalHeight = Math.floor((height - topMargin) / 3);
            const density = 96 / 25.4 * window.devicePixelRatio;
            const hScale = Math.floor(10 * density); // 10mmSec
            const vScale = Math.floor(10 * density); // 10mm/mV

            //element.width = hScale * nRecords;
            //label signals
            let { I, II, III, aVR, aVL, aVF, V1, V2, V3, V4, V5, V6 } = signalToggles;
            const arr = [I, II, III, aVR, aVL, aVF, V1, V2, V3, V4, V5, V6];
            let l = Math.floor((signalHeight / 2) + topMargin);

            l += (signalHeight * arr.map(c=>c??0).reduce((p, c) => p + c, 0));

            canvasHeight = l;
            element.height = canvasHeight;

            l = Math.floor((signalHeight / 2) + topMargin + 6); // 12px text, 

            ctxt.font = "12px Arial";
            ctxt.beginPath();
            ctxt.fillStyle = "black";
            ctxt.strokeStyle = "#000000";
            if (I) {
                ctxt.fillText("I", 0, l);
                l += signalHeight;
            }
            if (II) {
                ctxt.fillText("II", 0, l);
                l += signalHeight;
            }
            if (III) {
                ctxt.fillText("III", 0, l);
                l += signalHeight;
            }
            if (aVR) {
                ctxt.fillText("aVR", 0, l);
                l += signalHeight;
            }
            if (aVL) {
                ctxt.fillText("aVL", 0, l);
                l += signalHeight;
            }
            if (aVF) {
                ctxt.fillText("aVF", 0, l);
                l += signalHeight;
            }
            if (V1) {
                ctxt.fillText("V1", 0, l);
                l += signalHeight;
            }
            if (V2) {
                ctxt.fillText("V2", 0, l);
                l += signalHeight;
            }
            if (V3) {
                ctxt.fillText("V3", 0, l);
                l += signalHeight;
            }
            if (V4) {
                ctxt.fillText("V4", 0, l);
                l += signalHeight;
            }
            if (V5) {
                ctxt.fillText("V5", 0, l);
                l += signalHeight;
            }
            if (V6) {
                ctxt.fillText("V6", 0, l);
                l += signalHeight;
            }

            ctxt.translate(25, 0);

            ctxt.beginPath();
            ctxt.strokeStyle = "#FF0000";
            ctxt.lineWidth = 0.5;
            for (let i = 0; i < canvasHeight; i += 20) {
                ctxt.moveTo(0, i);
                ctxt.lineTo(canvasWidth, i);
            }
            for (let i = 0; i < canvasWidth;  i += 20 ) {
                ctxt.moveTo(i, 0);
                ctxt.lineTo(i, canvasHeight);
            }
            ctxt.stroke();
            
            // Plot
            if (nSignals == 2) { // IEM
                nSignals = 3;
            }
            else if (nSignals == 8) { // BT12
                nSignals = 12;
            }

            // plot
            let s = 0;

            const plotLineFn = function (t :number , getValue: (index:number) => number, getIndex: (index:number) => number) {
                    for (let k = 0; k < hScale; k++) {
                        let index = Math.floor((((k * freqs[0])
                            / hScale) + 0.5));
                        if ((k + (t * hScale)) == 0) {
                            ctxt.moveTo(0, topMargin + (s * signalHeight)
                                + (signalHeight / 2));
                        }
                        else if ((k + (t * hScale)) == (0 - 1)) {
                            ctxt.lineTo(
                                k + (t * hScale),
                                topMargin + (s * signalHeight)
                                + (signalHeight / 2));
                        }
                        else if (((k + (t * hScale)) >= 0)
                            && ((k + (t * hScale)) <= element.width)) {

                            index = getIndex(index);
                            ctxt.lineTo(k + (t * hScale),
                                (topMargin + (s * signalHeight)
                                    + (signalHeight / 2))
                                - (getValue(index)
                                    * vScale));
                        }
                    }
            }

            let startIndex = 0, endIndex = nRecords;
            ctxt.strokeStyle = "#000000";
            ctxt.lineWidth = 1;

            for (let t = 0; t < nRecords; t++) {
                s = 0;
                if (I) {
                    for (let t = startIndex; t < endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 0, t); //Signal 0(channel 0) equals II
                        let data3: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 1, t); //Signal 1(channel 1) equals III
                        ctxt.beginPath();

                        plotLineFn(t, (index) => {
                            return data2[index] - data3[index];
                        }, (index) => {
                                if (index >= data2.length) {
                                    index = data2.length - 1;
                                }
                                if (index >= data3.length) {
                                    index = data3.length - 1;
                                }
                                return index;
                        });
                        ctxt.stroke();
                    }

                    s++;
                }
                if (II) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 0, t); //Signal 0(channel 0) equals II
                        plotLineFn(t, (i) => data2[i], (i) => i);
                    }
                    ctxt.stroke();
                    s++;
                }
                if (III) {
                    ctxt.beginPath();

                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 1, t); //Signal 1(channel 1) equals III
                        plotLineFn(t, (i) => data2[i], (i) => i);
                    }
                    ctxt.stroke();
                    s++;

                }
                if (aVR) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 0, t); //Signal 0(channel 0) equals II
                        let data3: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 1, t); //Signal 1(channel 1) equals III
                        // aVR = (III / 2) - II
                        plotLineFn(t, (i) => (data3[i] / 2) - data2[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                } 
                                if (i > data3.length) {
                                    i = data3.length - 1;
                                }
                                return i;
                        });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (aVL) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 0, t); //Signal 0(channel 0) equals II
                        let data3: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 1, t); //Signal 1(channel 1) equals III
                        // // aVL = (II / 2) - III
                        plotLineFn(t, (i) => (data2[i] / 2) - data3[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }
                                if (i > data3.length) {
                                    i = data3.length - 1;
                                }
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (aVF) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 0, t); //Signal 0(channel 0) equals II
                        let data3: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 1, t); //Signal 1(channel 1) equals III
                         // aVF = (II + III) / 2
                        plotLineFn(t, (i) => (data2[i] + data3[i]) / 2,
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }
                                if (i > data3.length) {
                                    i = data3.length - 1;
                                }
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (V1) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 2, t); //Signal 0(channel 0) equals II
                        plotLineFn(t, (i) => data2[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (V2) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 3, t); //Signal 0(channel 0) equals II
                        plotLineFn(t, (i) => data2[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (V3) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 4, t); //Signal 0(channel 0) equals II
                        plotLineFn(t, (i) => data2[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (V4) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 5, t); //Signal 0(channel 0) equals II
                        plotLineFn(t, (i) => data2[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }   
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (V5) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 6, t); //Signal 0(channel 0) equals II
                        plotLineFn(t, (i) => data2[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
                if (V6) {
                    ctxt.beginPath();
                    for (let t = startIndex; t <= endIndex; t++) {
                        let data2: Float32Array = getDataAtSecond(data, nSignals, dMins, dMaxs, pMaxs, pMins, freqs, 7, t); //Signal 0(channel 0) equals II
                        plotLineFn(t, (i) => data2[i],
                            (i) => {
                                if (i > data2.length) {
                                    i = data2.length - 1;
                                }
                                return i;
                            });
                    }
                    ctxt.stroke();
                    s++;
                }
            }

            const str = `version: ${version}, patient:${patient}, date: ${date}, time:${hour}, headerSize:${headerSize}, nRecords:${nRecords}
                    duration:${duration}, nSignals:${nSignals}`;
            //div.innerHTML = str;
        }

    }
}

function getDataAtSecond(data: DataView, nSignals: number, dMins: Uint32Array, dMaxs: Uint32Array, pMaxs: Float32Array, pMins: Float32Array, freqs: Uint32Array, signal: number, second: number): Float32Array {
    let location = 0;
    let retval = new Float32Array(freqs[signal]);
    const start = Date.now();

    for (let i = 0; i < nSignals; i++) {
        location += second * 2 * freqs[i];
    }
    for (let i = 0; i < signal; i++) {
        location += 2 * freqs[i];
    }
    try {
        let buf = new DataView(data.buffer, data.byteOffset + location);//, freqs[signal] * 2);
        //System.arraycopy(data, location, buf, 0, buf.length);
        for (let i = 0; i < freqs[signal]; i++) {
            try {
                let value = (buf.getUint8(i * 2) & 0xff) | ((buf.getUint8((i * 2) + 1) & 0xff) << 8);
                //let value = buf.getInt16(i,true);
                //the real physical value(unit mV) is retval[i]
                retval[i] = (((value - dMins[signal]) / (dMaxs[signal] - dMins[signal])) * (pMaxs[signal] - pMins[signal]))
                    + pMins[signal];
            }
            catch (e) {
                //console.log(e);
            }
        }
        //console.log({ signal: signal, location: location, t: second })
    } catch (e) {
        //console.log(e); 
    }
    //console.log(`sig:${signal} t:${second} milliseconds:${Date.now() - start} `)

    return retval;
}

function readDataAsInt(reader: { buffer: ArrayBuffer, pos: number }, length: number): number {

    const str = readDataAsString(reader, length);
    return parseInt(str);
}

function readDataAsFloat(reader: { buffer: ArrayBuffer, pos: number }, length: number): number {

    const str = readDataAsString(reader, length);
    return parseFloat(str);
}


function readDataAsString(reader: { buffer: ArrayBuffer, pos: number }, length: number) : string {
    //const decoder = new TextDecoder('windows-1251');
    const intArray = new Int8Array(reader.buffer, reader.pos, length);
    const arr = Array.from(intArray.values());

    const rv = String.fromCharCode.apply(null,arr);
        //decoder.decode(reader.buffer.subarray(reader.pos, reader.pos + length));
    reader.pos += length;
    return rv.trim();
}

function SelectedECGRhythms (props:{ selectedRhythms: string }) {
    const {t} = useTranslation();

    if (props.selectedRhythms) {
        const arr = props.selectedRhythms.split(",");
        return <p>{arr.map(a => <Chip variant='filled' color="info" label={t('ecg-'+a)} /> )}</p>;
    }

    return null;
}

export function ECGComponent(p:{d:{fetchImage: () => Promise<Blob | null>, filename:string, caseId:string}, ecgMeasurements:ECGMeasurement[]}) {

    const [blob, setBlob] = useState<ArrayBuffer|null>(null);
    const [selectedECGMeasurement, setSelectedEcgMeasurement] = useState<ECGMeasurement|undefined>(undefined);

    useEffect(()=> {
       const m = p.ecgMeasurements.find(e=>p.d.filename.includes(e._id));
       setSelectedEcgMeasurement(m);
    }, [p.d.filename, p.ecgMeasurements]);


    useEffect(() => {
        async function f() {
            let img = await p.d.fetchImage();
            if (img != null) {
                setBlob(await img.arrayBuffer());
            }
        }
        f();
    }, [p.d]);    

    let e:JSX.Element | null = null;
    if (blob && selectedECGMeasurement) {
        e = renderECG(p.d.caseId, selectedECGMeasurement,  blob);
    } 

    return e;

}

