import { useContext, useEffect } from 'react';
import { Card, CardContent, CardHeader, Container, Typography } from "@mui/material";
import { Injuries } from "../models/Cases";
import L from "./localeText";
import { MultiSelectionComponent } from "./MultiSelectionComponent";
import { AzureClientContext } from '../providers/azureClientProvider';
import { FetchCaseImagery } from "./FetchCaseImagery";
import { CaseDetail } from "../models/Cases";

export function InjuriesComponent(p: { c: CaseDetail }) {

    const t = p.c.injuries;

    const { dispatch } = useContext(AzureClientContext);

    useEffect(() => {
        dispatch({ type: 'caseSelectedChange', data: p.c._id })

        const interval = setInterval(()=> {
            dispatch({type:'refreshCaseData', data: p.c._id})            
        },10000)

        return () => clearInterval(interval)
    }, [p.c._id, dispatch])

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'injuries-title'} /> </Typography>} />

            <CardContent>
                {t && (
                    <>
                    <ul>
                        <li>
                            <L t={'injuries-head-title'} />
                            <BurnFractureWoundDetails data={createBFWArray(t.headBurn, t.headFracture, t.headWound)} />
                        </li>
                        <li>
                            <L t={'injuries-neck-title'} />
                            <BurnFractureWoundDetails data={createBFWArray(t.neckBurn, t.neckFracture, t.neckWound)} />
                        </li>
                        <li>
                            <L t={'injuries-chest-title'} />
                            <BurnFractureWoundDetails data={createBFWArray(t.chestBurn, t.chestFracture, t.chestWound)} />
                        </li>
                        <li>
                            <L t={'injuries-abdomen-title'} />
                            <BurnWoundDetails data={createBWArray(t.abdomenBurn, t.abdomenWound)} />
                        </li>
                        <li>
                            <L t={'injuries-right-arm-title'} />
                            <BurnFractureWoundDetails data={createBFWArray(t.rightArmBurn, t.rightArmFracture, t.rightArmWound)} />
                        </li>
                        <li>
                            <L t={'injuries-right-leg-title'} />
                            <BurnFractureWoundDetails data={createBFWArray(t.rightLegBurn, t.rightLegFracture, t.rightLegWound)} />
                        </li>
                        <li>
                            <L t={'injuries-left-arm-title'} />
                            <BurnFractureWoundDetails data={createBFWArray(t.leftArmBurn, t.leftArmFracture, t.leftArmWound)} />
                        </li>
                        <li>
                            <L t={'injuries-left-leg-title'} />
                            <BurnFractureWoundDetails data={createBFWArray(t.leftLegBurn, t.leftLegFracture, t.leftLegWound)} />
                        </li>
                        <li>
                            <L t={'injuries-burn-score-title'} /><Typography component='span' >&nbsp;{t.burnScore} %</Typography>
                        </li>
                        <li>
                            <L t={'injuries-notes-title'} /><Typography component='span' >&nbsp;{t.notes} </Typography>
                        </li>
                    </ul>
                    <Container>
                        <FetchCaseImagery selectedCase={p.c} dispatch={dispatch} filterToImageTypes={['Injuries']} />
                    </Container>
                    </>
                )
                }
            </CardContent>
        </Card>);
}

function createBFWArray(burn: boolean, fracture: boolean, wound: boolean): string[] {
    return [burn ? "Burn" : "", fracture ? "Fracture" : "", wound ? "Wound" : ""];
}
function createBWArray(burn: boolean, wound: boolean): string[] {
    return [burn ? "Burn" : "", wound ? "Wound" : ""];
}


function BurnFractureWoundDetails(p: { data: string[] }) {
    return <MultiSelectionComponent data={p.data}
        availableItems={{
            Burn: 'injuries-burn',
            Fracture: 'injuries-fracture',
            Wound: 'injuries-wound',
        }} />
}

function BurnWoundDetails(p: { data: string[] }) {
    return <MultiSelectionComponent data={p.data}
        availableItems={{
            Burn: 'injuries-burn',
            Wound: 'injuries-wound',
        }} />
}
