import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_DE } from "./de/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
import { TRANSLATIONS_CZ } from "./cz/translations";
import { TRANSLATIONS_US } from "./us/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     us: {
        translation: TRANSLATIONS_US
     },
     fr: {
       translation: TRANSLATIONS_FR
     },
     es: {
        translation: TRANSLATIONS_ES
      },
      de: {
        translation: TRANSLATIONS_DE
      },
      cz: {
          translation: TRANSLATIONS_CZ
      }
 
   }
 });
 
const languageCookie  = document.cookie.replace(/(?:(?:^|.*;\s*)lang\s*=\s*([^;]*).*$)|^.*$/, "$1"); 
i18n.changeLanguage(languageCookie?? "en");