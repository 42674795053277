import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { TourniquetHistory, Treatments } from "../models/Cases";
import L from "./localeText";
import Grid from "@mui/material/Grid";
import { ReactNode } from "react";
import { TourniquetHistoryView } from "./TourniquetHistoryView";
import { TreatmentCycleButton, ChestInterventionCycleButton } from "./TreatmentCycleButton";
import { TextWithCarriageReturnsComponent } from "./TextWithCarriageReturnsComponent";
export function TreatmentsComponent(p: { i: Treatments, th: TourniquetHistory }) {

    const t = p.i;
    const th = p.th;

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'treatment-title'} /> </Typography>} />

            <CardContent>
                {t && (
                    <>
                        <Grid container >
                            <Grid item md={4}><TreatmentCycleButton title={'treatment-head-title'} value={t.head} /></Grid>
                            <Grid item md={4}><TreatmentCycleButton title={'treatment-face-title'} value={t.face} /></Grid>
                            <Grid item md={4}><TreatmentCycleButton title={'treatment-neck-title'} value={t.neck} /></Grid>
                            <Grid item md={4}><TreatmentCycleButton title={'treatment-head-chest'} value={t.chestDressing} /></Grid>
                            {/* <Grid item md={4}><ChestInterventionCycleButton title={'treatment-head-chest-intervention'} value={t.chestIntervention} /></Grid> */}
                            <Grid item md={4}><TreatmentCycleButton title={'treatment-head-abdomen'} value={t.abdomen} /></Grid>
                            

                            <Grid item md={5}><TreatmentCycleButton title={'treatment-right-arm'} value={t.rightArmDressing} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-right-arm-dist" tourniquetOnOffhistory={th.rightArmDistTimes} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-right-arm-junc" tourniquetOnOffhistory={th.rightArmJuncTimes} />
                            </Grid>
                            <Grid item md={2}></Grid>
                            <Grid item md={5}><TreatmentCycleButton title={'treatment-left-arm'} value={t.leftArmDressing} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-left-arm-dist" tourniquetOnOffhistory={th.leftArmDistTimes} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-left-arm-junc" tourniquetOnOffhistory={th.leftArmJuncTimes} />
                            </Grid>

                            <Grid item md={5}><TreatmentCycleButton title={'treatment-right-leg'} value={t.rightLegDressing} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-right-leg-dist" tourniquetOnOffhistory={th.rightLegDistTimes} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-right-leg-junc" tourniquetOnOffhistory={th.rightLegJuncTimes} />
                            </Grid>
                            <Grid item md={2}></Grid>
                            <Grid item md={5}><TreatmentCycleButton title={'treatment-left-leg'} value={t.leftLegDressing} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-left-leg-dist" tourniquetOnOffhistory={th.leftLegDistTimes} />
                                <TourniquetHistoryView title="treatment-tourniquet-history-left-leg-junc" tourniquetOnOffhistory={th.leftLegJuncTimes} />
                            </Grid>
                            
                            <Grid item md={12}><Typography component='h2' ><L t='treatment-notes' /></Typography><TextWithCarriageReturnsComponent text={t.notes} /></Grid>
                        </Grid>
                    </>
                )}
            </CardContent>
        </Card>
    )
}



