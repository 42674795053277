const whiteColor = "#FFF";
const blackColor = "#000";

const hexToRgb = input => {
    input = input + "";
    input = input.replace("#", "");
    let hexRegex = /[0-9A-Fa-f]/g;
    if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
      throw new Error("input is not a valid hex color.");
    }
    if (input.length === 3) {
      let first = input[0];
      let second = input[1];
      let last = input[2];
      input = first + first + second + second + last + last;
    }
    input = input.toUpperCase(input);
    let first = input[0] + input[1];
    let second = input[2] + input[3];
    let last = input[4] + input[5];
    return (
      parseInt(first, 16) +
      ", " +
      parseInt(second, 16) +
      ", " +
      parseInt(last, 16)
    );
  };
  

export const pageStyle = theme=> ( {

    fullPage: {
        //padding: "120px 0",
        position: "relative",
        minHeight: "100vh",
        display: "flex!important",
        margin: "0",
        border: "0",
        color: whiteColor,
        alignItems: "center",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
        width:"100%",
        [theme.breakpoints.down("sm")]: {
            minHeight: "fit-content!important"
        },
        "& footer": {
            position: "absolute",
            bottom: "0",
            width: "100%",
            border: "none !important"
        },
        "&:before": {
            backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
        },
        /*"&:before,&:after": {
            display: "block",
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            zIndex: "2"
        }*/
    }
});