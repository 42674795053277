import React, { useEffect } from 'react'
import { useState } from "react";
import reactElementToJSXString from 'react-element-to-jsx-string';


const UpdateItemIndicator:React.FunctionComponent<{}> = (p) => {
    const [value, setValue] = useState<{val:string, cls:string}>({val:'', cls:' cellValueChangedAnimation' })
    useEffect(()=> { 
    const newVal = reactElementToJSXString(p.children);
    if ( newVal !== value.val) {

        setValue({val:newVal, cls:'cellValueChangedAnimation'})
        setTimeout(()=>{setValue({val:newVal, cls:'cellValue'})} ,5000)
    } 
    }, [p.children, value.val])
    return <span className={value.cls}>{p.children}</span>
}

export default UpdateItemIndicator;