export const TRANSLATIONS_FR = {
    "Yes" : "Qui",
    "No" : "Non",

    "name": "Nom",
    "gender": "Sexe",
    "zap":"zap",

    "dashboard-title": "Tableau de bord",

    "active cases": "Dossiers actifs",
    "historic cases": "Historique des dossiers",

    "ample":"SAMPLE",
    "allergies":"Allergies",
    "medical history":"Antécédents médicaux",
    "medications":"Médicaments",
    "no ample details recorded.": "_No Ample details recorded.",
    "asprin":"Aspirine",
    "carbamazepine":"Carbamazépine",
    "cephalosporin" : "Céphalosporine",
    "codeine" : "Codéine" ,
    "insulin" : "Insuline",
    "latex" : "Latex",
    "nsaid" : "AINS",
    "penicillin" : "Pénicilline",
    "phenytoin" : "Phénytoïne",
    "sulphonamide" : "Sulfonamide",
    "tetracycline" : "Tetracycline",
    "other" : "Autre",
    "none" : "Aucun",
    "nnknown" : "Inconnu",
    "no medical history recorded.":"_No Medical History recorded.",

    "cancer":"Cancer",
    "cva":"AVC",
    "diabetes" : "Diabète",
    "epilepsy":"Épilepsie",
    "chronic heart condition":"Maladie cardiaque",
    "hypertension":"Hypertension",
    "chronic lung condition":"Maladie pulmonaire",
    "mental illness":"Maladie mentale",
    "physically disabled":"Handicapé",
    "pregnant":"Enceinte",
    "myocardial infarction":"Myocardiaque",


    "chooseLocale": "Choisir une langue",
    "close": "Fermer",
    "uk": "Royaume-Uni",
    "unitedstates" : "États Unis",
    "german": "Allemand",
    "french": "Français",
    "czech": "République Tchèque",
    "spain": "Espagnol",

    "vitals-label-skintemphistory": "Température de la peau",
    "vitals-label-coretemphistory": "Température à cœur",


   };