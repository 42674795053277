import { AzureClientContext } from '../providers/azureClientProvider';
import { Container, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { CaseDetail } from "../models/Cases";
import { FetchCaseImagery } from "./FetchCaseImagery";

export function ImageryComponent(p: { selectedCase: CaseDetail }) {
    const { dispatch } = useContext(AzureClientContext);

    const c = p.selectedCase;
    console.log(c.annotations);

    useEffect(() => {
        dispatch({ type: 'caseSelectedChange', data: p.selectedCase._id })

        const interval = setInterval(()=> {
            dispatch({type:'refreshCaseData', data: p.selectedCase._id})            
        },10000)

        return () => clearInterval(interval)
    }, [p.selectedCase._id, dispatch])


    return (
        <Container>
            <Typography component='h2' >
                Case Imagery
            </Typography>

            <FetchCaseImagery selectedCase={c} dispatch={dispatch} />
        </Container>
    )
}

