
import { ListItem, ListItemText, ListItemIcon, Box } from "@mui/material";

import { CaseDetail } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import { PriorityBadge } from "./PriorityBadges";
import { ReactNode } from "react";
import { TriageSortBadge } from "./TriageSortBadges";


export function CaseSummaryTile(p: { c: CaseDetail, actions: ReactNode, onClick?:(c:CaseDetail, replaceSelection?:boolean)=>void, selected?:boolean, compact?:boolean }) {
    const dateFmt = Intl.DateTimeFormat("en-GB", {})
    const c = p.c;
    //const navigate = useNavigate();
    const onClick = p.onClick?? function d(c) {};
    const triagePriorityOrSort = (!c.vitals?.triageSort || c.vitals?.triageSort == "") ? 
        <PriorityBadge priorityId={c.incident?.evacPriorityId ?? -1} /> :
        <TriageSortBadge triageSort={c.vitals?.triageSort ?? ""}   />
        

    
    const secondaryStyle = p.compact ? {marginLeft:-8} : {}
    return <ListItem id={c._id} key={c._id} alignItems="flex-start" onClick={(evt)=>onClick(c,true)} className={p.selected ? "caseSummaryTile selected" : "caseSummaryTile" }
        secondaryAction={p.actions} >
        <ListItemIcon>
            {triagePriorityOrSort}
        </ListItemIcon>
        <ListItemText
            primary= {<U><span>{c.caseId} {dateFmt.format(c.created)}</span></U>}
            secondary = {<Box component='div' sx={secondaryStyle} ><U><span><L t='name'/>:&nbsp;{c.patientDetails.name} {c.patientDetails.surname}  <L t='gender' />:&nbsp;{c.patientDetails.sex}  <L t='zap' />:&nbsp;{c.patientDetails.zapNumber}</span></U></Box>}
        ></ListItemText>
        
    </ListItem>
}
