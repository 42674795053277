import { TourniquetOnOff } from "../models/Cases";
import DateTimeRender from "./DateTimeRenderer";
import LocaleText from "./localeText";

export function TourniquetHistoryView(p: { title: string; tourniquetOnOffhistory: TourniquetOnOff[]; }) {

    const t = (p.tourniquetOnOffhistory ?? []).map((t, i) => <tr key={`h${i}`}><td><LocaleText t='treatment-tourniquet-history-on' /><span>&nbsp;</span></td><td><DateTimeRender value={t.on} format='evt' /></td><td>{t.off ? <><LocaleText t='treatment-tourniquet-history-off' />&nbsp;<DateTimeRender value={t.off} format='evt' /></> : ''}</td></tr>);
    return <div><LocaleText t={p.title} /><br />
        <table><tbody>
            {t}</tbody></table></div>;
}
