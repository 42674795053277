import React, { createContext, useEffect, useReducer } from "react";
import { CaseDetail, CaseSummary } from "../models/Cases";
import mdbClient from "./MongoDBClient";

//let err: string | undefined = undefined;

type MongoDBState = { activeCases: CaseSummary[], historicCases: CaseSummary[], selectedCase?: CaseDetail, selectedCaseId?: string, error?: string }

const mongoStateReducer = function (state: MongoDBState, action: { type: string, data: any }): MongoDBState {
    switch (action.type) {
        case "activeCases":
            return { ...state, activeCases: action.data };
        case "historicCases":
            return { ...state, historicCases: action.data };
        case "selectedCaseChange":
            if (action.data !== state.selectedCaseId) {
                mdbClient.setSelectedCaseId(action.data, getHostData);
                return { ...state, selectedCaseId: undefined, selectedCase:undefined };
            }
            break;
        case "selectedCase":
            return { ...state, selectedCase: action.data }
        case "selectedVitals":
            const currentCase = {...state.selectedCase!!, vitals: {...action.data}}
            return {...state, selectedCase: currentCase}
        case "selectedVitalsHistory":
            const currentCase2 = {...state.selectedCase!!, vitalsHistory: {...action.data}}
            return {...state, selectedCase: currentCase2}
            default:
            break;
    }
    return state;
}

export type HostData = {a:string, b:string}



type MongoDbClientContextType = { state: MongoDBState, dispatch: React.Dispatch<any> };

const initialState = { state: { activeCases: [], historicCases: [] }, dispatch: () => { } };

const MongoDBClientContext = createContext<MongoDbClientContextType>(initialState);


// dev website
//const appId = "rapidepr-svfdk";
//const apiKey = "4igpG4rY3eJDy9GL41TGVBa3Yl61xO4eoyl3ovTpGRklOtU3vOJIOrCbqnhNZ8ic";

// medis website
//const apiKey = "rapidepr-medis-ollwy"
//const apiKey = "4BKmsnUpAH8joLQ1wjC1grrIXcmgnxbfmE2lFA4OwK9jcCvbZjf2ci3kAJ9wS22O"

// singapore rapid epr website
// const appId = "rapidepr0-iuubt"
// const apiKey = "9CDmv6VSPSYEr4yu5gjciV5e3tw13cD6j28ZpGqBUuAc4LkWfM6A72jjOgre5tnZ"

const getHostData = () => {
    const h = window.location.host; 
    if (h.startsWith("medis") ) {
        return {a:"rapidepr-medis-ollwy" , b:"4BKmsnUpAH8joLQ1wjC1grrIXcmgnxbfmE2lFA4OwK9jcCvbZjf2ci3kAJ9wS22O" }

    } 
    else if (h.startsWith("dstl") || h.startsWith("dstlblobstorage.z33")) {
        return {a:"rapidepr-kluob" , b:"Uu52f4OZR1ZxxRnjRd0XuJLXYEnKhY5wbTP3KDz0ReDc1e8R9tIA2H3DSxMbW5ra" }
        
    }
    else if (h.startsWith("localhost") || h.startsWith("rapideprsingapore.z23") || h.startsWith("aris")) {
        return {a:"rapidepr0-iuubt" , b:"9CDmv6VSPSYEr4yu5gjciV5e3tw13cD6j28ZpGqBUuAc4LkWfM6A72jjOgre5tnZ" }
    }
    else if (h.startsWith("localhost") || h.startsWith("rapideprwebsite.z33")) {
        return {a:"rapidepr-svfdk" , b:"4igpG4rY3eJDy9GL41TGVBa3Yl61xO4eoyl3ovTpGRklOtU3vOJIOrCbqnhNZ8ic" }
    }
    else {
        return {a:"",b:""}
    }    
}


function MongoDBClientProvider(props: { children: React.ReactNode }) {

    const fullState = {
        activeCases: [],
        historicCases: []
    }

    const [state, Dispatch] = useReducer(mongoStateReducer, fullState);

    useEffect(() => { mdbClient.init(Dispatch, getHostData) }, [Dispatch]);


    const value = React.useMemo(() => { return { state, dispatch: Dispatch }; }, [state, Dispatch]);


    return <MongoDBClientContext.Provider value={value}>{props.children}</MongoDBClientContext.Provider>
}

const MongoDBClientConsumer = MongoDBClientContext.Consumer;

export { MongoDBClientContext, MongoDBClientProvider, MongoDBClientConsumer };
