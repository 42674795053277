import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { DashboardPage } from './DashboardPage';
import { LiveCasesPage } from './LiveCasesPage';
import { HistoricCasesPage } from './HistoricCasesPage';
import { AdminPage } from './AdminPage';
import { CaseDetailsPage } from './CaseDetailsPage';
import { AppContextConsumer } from '../providers/appContextProvider';
import LogoutIcon from '@mui/icons-material/Logout';
import { MongoDBClientProvider } from '../providers/mongoDBClientProvider';
import NetworkConnectionIcon from './NetworkConnectIcon';
import LocaleSwitcher from './LocalSwitcher';
import MapPage from './MapPage';
import { Button, Stack } from '@mui/material';
import { Footer } from './Footer';
import packageJson from '../../package.json'

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function AuthenticatedLayout() {
    //const theme = useTheme();
    //const [open, setOpen] = React.useState(false);

    /*const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };*/

    return (
        <AppContextConsumer>
            {({ state, dispatch }) => (
                <MongoDBClientProvider>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CssBaseline />
                        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} >
                            <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'right' }} >
                                <nav>
                                    <List component={Stack} direction="row">
                                        <Button key='Dashboard' sx={{color:'inherit'}}  href="/dashboard">Dashboard</Button>
                                        <Button key='Active Casualties' sx={{color:'inherit'}}  href="/live">Active Casualties</Button>
                                        <Button key='MapPage' sx={{color:'inherit'}}  href="/MapPage">Map</Button>
                                        <Button key='Historic' sx={{color:'inherit'}}  href="/historic">Historic Casualties</Button>
                                        <Button key='Admin' sx={{color:'inherit'}} href="/Admin">Administration</Button>
                                    </List>
                                </nav>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'right', marginLeft: 'auto' }}>
                                    <NetworkConnectionIcon />
                                    <LocaleSwitcher />
                                    <Typography  component="span" sx={{ flexGrow: 1, fontSize:'smaller' }}>
                                        {state.user?.displayname}&nbsp;<LogoutIcon onClick={() => {
                                            dispatch({ type: 'logout', data: {} })
                                        }} />
                                    </Typography>
                                </Box>
                            </Toolbar>
                        </AppBar>

                        <Main open={false}>
                            <DrawerHeader />
                            <Routes>
                                <Route path="/dashboard" element={<DashboardPage />} />
                                <Route path="/live" element={<LiveCasesPage />} />
                                <Route path="/historic" element={<HistoricCasesPage />} />
                                <Route path="/admin" element={<AdminPage />} />
                                <Route path="/caseDetail" element={<CaseDetailsPage />} />
                                <Route path="/mapPage" element={<MapPage />} />
                                <Route path="/*" element={<DashboardPage />} />
                            </Routes>
                            <Outlet />
                        </Main>
                        <Footer v={'v'+ packageJson.version}/>
                    </Box>
                </MongoDBClientProvider>
            )}
        </AppContextConsumer>
    );
}
