import { Card, CardContent, Grid, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { CaseSummary } from "../models/Cases";
import { MongoDBClientContext } from "../providers/mongoDBClientProvider"
import { ArcGISMapping, GISSummaryList, GISSummaryData } from "./ArcGISMapping";
import L from "./localeText";
import { PriorityBadge } from "./PriorityBadges";

export function DashboardPage() {
    const { state } = useContext(MongoDBClientContext);
    const [cases, setCases] = useState<CaseSummary[]>([])
    const [historicCases, setHistoricCases] = useState<CaseSummary[]>([])
    const [gisData, setGisData] = useState<GISSummaryList>([]);

    useEffect(() => {
        setCases(state.activeCases);
        setHistoricCases(state.historicCases)
        setGisData(state.activeCases.map<GISSummaryData>((c: CaseSummary) =>
        ({
            lat: c.initialAssessment?.ia_latitude, lng: c.initialAssessment?.ia_longitude, id: c._id,
            evacpriority: c.incident?.evacPriority ?? "",
            evacpriorityid: c.incident?.evacPriorityId ?? -1,
            surname: c.patientDetails?.surname, forename: c.patientDetails?.name,
            caseid: c.caseId,
            caseObjectId: c._id,
            title: `${c.caseId} (${c.patientDetails?.name} ${c.patientDetails?.surname})`
        }
        )
        )
        )
    }, [state.activeCases, setCases, setHistoricCases, setGisData, state.historicCases]
    );


    return (
        <Container sx={{marginLeft:'240px'}}>
            <Typography>
                <h2><L t="dashboard-title" /></h2>
            </Typography>
            <Grid container spacing={2}>
                <DashboardTile xs={5} cases={cases} tileTitle="Active Cases" link="/live" />
                <DashboardTile xs={5} cases={historicCases} tileTitle="Historic Cases" link="/historic" />

            </Grid>
            <div style={{ width: "100%", height: "500px" }} >
                <ArcGISMapping list={gisData} fields={
                    [
                        { name: "evacpriorityid", type: "integer" },
                        { name: "evacpriority", type: "string" },
                        { name: "caseid", type: "string" },
                        { name: "id", type: "string" },
                        { name: "surname", type: "string" },
                        { name: "forename", type: "string" },
                        { name: "title", type: "string" },
                    ]} popupTemplate={{ title: '{title}', content: buildPopup }} />
            </div>
        </Container>
    )
}

function DashboardTile(c: { xs: number, cases: CaseSummary[], tileTitle: string, link: string }) {
    return (
        <Grid item xs={c.xs} >
            <Link to={c.link}>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            <L t={c.tileTitle} />
                        </Typography>
                        <Typography variant="h5" component="div">
                            {c.cases.length}
                        </Typography>
                    </CardContent>
                </Card>
            </Link>
        </Grid>
    )
}

function buildPopup(fields: { [key: string]: any }): Promise<HTMLElement> {
    const p = new Promise<HTMLElement>((resolve, reject) => {
        let puNode = document.createElement("div");
        ReactDOM.render(<PopUpContent fields={fields} />, puNode, () => { resolve(puNode) })
    });
    return p;
}
function PopUpContent(p: { fields: { [key: string]: any } }) {
    return (
        <div>
            <PriorityBadge priorityId={p.fields['evacpriorityid']} />
        </div>
    );
}
