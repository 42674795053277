import assert from "assert";
import * as Realm from 'realm-web'
import { CaseDetail, CaseSummary } from "../models/Cases";
import { HostData } from "./mongoDBClientProvider";

export interface IMongoDBClient {
    activeCases: CaseSummary[];
    selectedCaseDetails?: CaseDetail;
}



async function loginApiKey(h:HostData): Promise<Realm.App> {
    const config = {id: h.a};

    const app: Realm.App = new Realm.App(config);
    const credentials = Realm.Credentials.apiKey(h.b);
    const user: Realm.User = await app.logIn(credentials);
    // `App.currentUser` updates to match the logged in user    
    assert(user.id === app.currentUser?.id)
    return app;
}

let mcl: globalThis.Realm.Services.MongoDB | undefined = undefined;

type ClientType = {
    _dispatch: React.Dispatch<any>;
    init: (dispatch: React.Dispatch<any>, h:()=>HostData) => Promise<void>;
    setSelectedCaseId: (id: string,h:()=>HostData) => Promise<void>;
    hostData:()=>HostData;
    selectedCase: AsyncGenerator<globalThis.Realm.Services.MongoDB.ChangeEvent<any>, any, unknown> | null;
    caseCollection?: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>;
    vitalsCollection?: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>;
    selectedVitals: AsyncGenerator<globalThis.Realm.Services.MongoDB.ChangeEvent<any>, any, unknown> | null;
    vitalsHistoryCollection?: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>;
    selectedVitalsHistory: AsyncGenerator<globalThis.Realm.Services.MongoDB.ChangeEvent<any>, any, unknown> | null;
}

const client: ClientType = {
    _dispatch: () => { },
    selectedCase: null,
    caseCollection: undefined,
    selectedVitals: null,
    selectedVitalsHistory:null,
    hostData:()=>({a:'',b:''}),

    init: async (dispatch: React.Dispatch<any>, h:()=>HostData ) => {
        client._dispatch = dispatch
        client.hostData = h
        if (mcl === undefined) {
            const app = await loginApiKey(client.hostData());
            mcl = app.currentUser?.mongoClient("mongodb-atlas");
        }
        client.caseCollection = mcl?.db("RapidEPR").collection('Case');
        client.vitalsCollection = mcl?.db("RapidEPR").collection('Vitals');            
        client.vitalsHistoryCollection = mcl?.db("RapidEPR").collection('VitalsHistory');            

        if (client.caseCollection) {
            const start = (await client.caseCollection.aggregate(activeCasesSummaryFilter));
            client._dispatch({ type: 'activeCases', data: start })
            const closed = (await client.caseCollection.aggregate(historicCasesSummaryFilter));
            client._dispatch({ type: 'historicCases', data: closed })

            for await (const _ of client.caseCollection.watch(activeCasesSummaryFilter)) {
                const c = await client.caseCollection.aggregate(activeCasesSummaryFilter);
                client._dispatch({ type: 'activeCases', data: c });
            }

            for await (const _ of client.caseCollection.watch(historicCasesSummaryFilter)) {
                const c = await client.caseCollection.aggregate(historicCasesSummaryFilter);
                client._dispatch({ type: 'historicCases', data: c });
            }
        }
    },
    setSelectedCaseId: async (Id: string, h:()=>HostData) => {
        if (mcl === undefined) {
            const app = await loginApiKey(h());
            mcl = app.currentUser?.mongoClient("mongodb-atlas");
            client.caseCollection = mcl?.db("RapidEPR").collection('Case');
            client.vitalsCollection= mcl?.db("RapidEPR").collection('Vitals');            
            client.vitalsHistoryCollection= mcl?.db("RapidEPR").collection('VitalsHistory');            
        }

        if (mcl && client.caseCollection && client.vitalsCollection && client.vitalsHistoryCollection) {

            const c = await client.caseCollection.aggregate([{ "$match": { "_id": Id } }, ...activeCasesDetailFilter,
            ]);
            client._dispatch({ type: 'selectedCase', data: c[0] });
            client.selectedCase = client.caseCollection.watch({ids:[Id]});
            const vitalsId = c[0].vitals._id
            const vitalsHistoryId = c[0].vitalsHistory._id
            client.selectedVitals = client.vitalsCollection!!.watch({ids:[vitalsId]});
            client.selectedVitalsHistory = client.vitalsHistoryCollection!!.watch({ids:[vitalsHistoryId]})

            if (client.selectedCase) {
                for await (const change of combineAsyncIterables([client.selectedCase, client.selectedVitals, client.selectedVitalsHistory])) {
                    if (change.operationType === "update" && change.documentKey._id === Id)
                    {                        
                        const ch = await client.caseCollection.aggregate([{ "$match": { "_id": Id } }, ...activeCasesDetailFilter]);
                        client._dispatch({ type: 'selectedCase', data: ch[0] });
                    }
                    if (change.operationType === "update" && change.documentKey._id === vitalsId) 
                    {
                        const ch = await client.vitalsCollection!!.aggregate([{"$match": {"_id": vitalsId}}])    
                        client._dispatch({type:'selectedVitals', data:ch[0]})
                    }
                    if (change.operationType === "update" && change.documentKey._id === vitalsHistoryId) 
                    {
                        const ch = await client.vitalsHistoryCollection!!.aggregate([{"$match": {"_id": vitalsHistoryId}}, ...activeVitalsDetailsFilter ])    
                        client._dispatch({type:'selectedVitalsHistory', data:ch[0].vitalsHistory})
                    }
                    
            }
            }            
        
            if (mcl && client.vitalsCollection) {
                const vitalsId = c[0].vitals._id
                const v = await client.vitalsCollection.aggregate([{"$match":{"_id": vitalsId  }}, ... activeVitalsDetailsFilter ])
                client._dispatch({type:'selectedVitals', data: v[0]});
                client.selectedVitals = client.vitalsCollection.watch({ids:[vitalsId]});
                if (client.selectedVitals) {
                    for await(const change of client.selectedVitals) {
                        if (change.operationType === "update" && change.documentKey._id === vitalsId) 
                        {
                            const ch = await client.vitalsCollection.aggregate([{"$match": {"_id": vitalsId}}, ...activeVitalsDetailsFilter])    
                            client._dispatch({type:'selectedVitals', data:ch[0]})
                        }
                    }
                }
            }
        }
    }
};


export default client;

const casesSummary = [
    {
        "$lookup": {
            from: "PatientDetails",
            localField: "patientDetails",
            foreignField: "_id",
            as: "patientDetails"
        },
    },
    {
        "$lookup": {
            from: "InitialAssessment",
            localField: "initialAssessment",
            foreignField: "_id",
            as: "initialAssessment"
        }
    },
    {
        "$lookup": {
            from: "Incident",
            localField: "incident",
            foreignField: "_id",
            as: "incident"
        }
    },
    { 
        "$addFields": { "patientDetails": { "$first": "$patientDetails" }, "initialAssessment": { "$first": "$initialAssessment" }, "incident": { "$first": "$incident" } } 
    },
    {
        "$sort" : {"created": -1 }
    }
];

const activeCasesSummaryFilter: globalThis.Realm.Services.MongoDB.AggregatePipelineStage[] = [
    {
        "$match": { "markedDeleted": null },
    },
    ...casesSummary
];

const historicCasesSummaryFilter: globalThis.Realm.Services.MongoDB.AggregatePipelineStage[] = [
    {
        "$match": { "markedDeleted": { "$ne": null } },
    },
    ...casesSummary
];


const activeCasesDetailFilter: globalThis.Realm.Services.MongoDB.AggregatePipelineStage[] = [
    {
        "$facet": {
            "patientDetails": [
                {
                    "$lookup": {
                        from: "PatientDetails",
                        localField: "patientDetails",
                        foreignField: "_id",
                        as: "patientDetails"
                    }
                },
                //{ "$addFields": { "patientDetails": { "$first": "$patientDetails" } } },
                { "$replaceRoot": { newRoot: { "$first": "$patientDetails" } } }
            ],
            "ample": [
                {
                    "$lookup": {
                        from: "AltAmple",
                        localField: "ample",
                        foreignField: "_id",
                        as: "ample"
                    }
                },
                { "$addFields": { "ample": { "$first": "$ample" } } },
                {
                    "$lookup": {
                        from: "AltAmpleA",
                        localField: "ample.allergies",
                        foreignField: "_id",
                        as: "ample.allergies"
                    }
                },
                {
                    "$lookup": {
                        from: "AltAmpleM",
                        localField: "ample.medications",
                        foreignField: "_id",
                        as: "ample.medications"
                    }
                },
                {
                    "$lookup": {
                        from: "AltAmpleP",
                        localField: "ample.pastMedicalHistory",
                        foreignField: "_id",
                        as: "ample.pastMedicalHistory"
                    }
                },
                {
                    "$addFields": {
                        "ample.allergies": { "$first": "$ample.allergies" },
                        "ample.medications": { "$first": "$ample.medications" },
                        "ample.pastMedicalHistory": { "$first": "$ample.pastMedicalHistory" }
                    }
                },
                {
                    "$lookup": {
                        from: "Administration",
                        localField: "ample.medications.administrations",
                        foreignField: "_id",
                        as: "ample.medications.administrations"
                    }
                },
                { "$replaceRoot": { newRoot: "$ample" } }

            ],
             "vitals": [
                {
                    "$lookup": {
                        from: "Vitals",
                        localField: "vitals",
                        foreignField: "_id",
                        as: "vitals"
                    }
                },
                { "$replaceRoot": { newRoot: { "$last": "$vitals" } } }

            ],            
            "vitalsHistory": [
                {
                    "$lookup": {
                        from: "VitalsHistory",
                        localField: "vitalsHistory",
                        foreignField: "_id",
                        as: "vitalsHistory"
                    }
                },
                {
                    "$lookup": {
                        from: "RealmSpo2Measurement",
                        localField: "vitalsHistory.o2History",
                        foreignField: "_id",
                        as: "o2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmHeartRateMeasurement",
                        localField: "vitalsHistory.hrHistory",
                        foreignField: "_id",
                        as: "hrHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmBloodPressureMeasurement",
                        localField: "vitalsHistory.bpHistory",
                        foreignField: "_id",
                        as: "bpHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmBloodGlucoseMeasurement",
                        localField: "vitalsHistory.bgHistory",
                        foreignField: "_id",
                        as: "bgHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmTemperatureMeasurement",
                        localField: "vitalsHistory.tempHistory",
                        foreignField: "_id",
                        as: "tempHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmRespirationRateMeasurement",
                        localField: "vitalsHistory.rrHistory",
                        foreignField: "_id",
                        as: "rrHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmAvpuMeasurement",
                        localField: "vitalsHistory.avpuHistory",
                        foreignField: "_id",
                        as: "avpuHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmCapRMeasurement",
                        localField: "vitalsHistory.capRHistory",
                        foreignField: "_id",
                        as: "capRHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmCO2Measurement",
                        localField: "vitalsHistory.co2History",
                        foreignField: "_id",
                        as: "co2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmETCO2Measurement",
                        localField: "vitalsHistory.etco2History",
                        foreignField: "_id",
                        as: "etco2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmFICO2Measurement",
                        localField: "vitalsHistory.fico2History",
                        foreignField: "_id",
                        as: "fico2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmETCO2Measurement",
                        localField: "vitalsHistory.ambientetco2History",
                        foreignField: "_id",
                        as: "ambientetco2History"

                    }
                },

                {
                    "$addFields": {
                        "vitalsHistory.rrHistory": "$rrHistory",
                        "vitalsHistory.o2History": "$o2History",
                        "vitalsHistory.hrHistory": "$hrHistory",
                        "vitalsHistory.bpHistory": "$bpHistory",
                        "vitalsHistory.bgHistory": "$bgHistory",
                        "vitalsHistory.tempHistory": "$tempHistory",
                        "vitalsHistory.avpuHistory": "$avpuHistory",
                        "vitalsHistory.capRHistory": "$capRHistory",
                        "vitalsHistory.co2History": "$co2History",
                        "vitalsHistory.etco2History": "$etco2History",
                        "vitalsHistory.ambientetco2History": "$ambientetco2History",
                        "vitalsHistory.fico2History": "$fico2History",
                    }
                },
                {
                    "$replaceRoot": { newRoot: { "$first": "$vitalsHistory" } },
                    /*"$addFields": {
                        "vitalsHistory": { "$first": "$vitalsHistory" }
                    }*/
                },
            ], 
            "incident": [
                {
                    "$lookup": {
                        from: "Incident",
                        localField: "incident",
                        foreignField: "_id",
                        as: "incident"
                    }
                },
                {
                    //"$addFields": { "incident": { "$first": "$incident" } }
                    "$replaceRoot": { newRoot: { "$first": "$incident" } }
                }

            ],
            "timings": [
                {
                    "$lookup": {
                        from: "Timings",
                        localField: "timings",
                        foreignField: "_id",
                        as: "timings"
                    }
                },
                {
                    //"$addFields": { "timings": { "$first": "$timings" } }
                    "$replaceRoot": { newRoot: { "$first": "$timings" } }
                }
            ],
            "primarySurvey": [
                {
                    "$lookup": {
                        from: "PrimarySurvey",
                        localField: "primarySurvey",
                        foreignField: "_id",
                        as: "primarySurvey"
                    }
                },
                {
                    "$addFields": { "primarySurvey": { "$first": "$primarySurvey" } }
                },
                {
                    "$lookup": {
                        from: "PSCatastrophicHaemorrhage",
                        localField: "primarySurvey.catHem",
                        foreignField: "_id",
                        as: "catHem"
                    },
                },
                {
                    "$lookup": {
                        from: "PSCervicalSpine",
                        localField: "primarySurvey.cerSpin",
                        foreignField: "_id",
                        as: "cerSpin"
                    },
                },
                {
                    "$lookup": {
                        from: "PSAirway",
                        localField: "primarySurvey.airway",
                        foreignField: "_id",
                        as: "airway"
                    },
                },
                {
                    "$lookup": {
                        from: "PSBreathing",
                        localField: "primarySurvey.breathing",
                        foreignField: "_id",
                        as: "breathing"
                    },
                },
                {
                    "$lookup": {
                        from: "PSCirculation",
                        localField: "primarySurvey.circulation",
                        foreignField: "_id",
                        as: "circulation"
                    },
                },
                {
                    "$lookup": {
                        from: "PSDisability",
                        localField: "primarySurvey.disability",
                        foreignField: "_id",
                        as: "disability"
                    },
                },
                {
                    "$lookup": {
                        from: "PSExamination",
                        localField: "primarySurvey.examination",
                        foreignField: "_id",
                        as: "examination"
                    },
                },
                {
                    "$lookup": {
                        from: "Sepsis",
                        localField: "primarySurvey.sepsis",
                        foreignField: "_id",
                        as: "sepsis"
                    },
                },
                {
                    "$addFields": {
                        "primarySurvey.catHem": { "$first": "$catHem" },
                        "primarySurvey.cerSpin": { "$first": "$cerSpin" },
                        "primarySurvey.airway": { "$first": "$airway" },
                        "primarySurvey.breathing": { "$first": "$breathing" },
                        "primarySurvey.circulation": { "$first": "$circulation" },
                        "primarySurvey.disability": { "$first": "$disability" },
                        "primarySurvey.examination": { "$first": "$examination" },
                        "primarySurvey.sepsis": { "$first": "$sepsis" },
                    }
                },
                {
                    "$lookup": {
                        from: "Annotation",
                        localField: "primarySurvey.examination.frontAnnotations",
                        foreignField: "_id",
                        as: "primarySurvey.examination.frontAnnotations"
                    },
                },
                {
                    "$lookup": {
                        from: "Annotation",
                        localField: "primarySurvey.examination.backAnnotations",
                        foreignField: "_id",
                        as: "primarySurvey.examination.backAnnotations"
                    },
                },
                { "$replaceRoot": { newRoot: "$primarySurvey" } }

            ],
            "injuries": [
                {
                    "$lookup": {
                        from: "Injuries",
                        localField: "injuries",
                        foreignField: "_id",
                        as: "injuries"
                    }
                },
                {
                    //"$addFields": { "injuries": { "$first": "$injuries" } }
                    "$replaceRoot": { newRoot: { "$first": "$injuries" } }

                },
            ],
            "initialAssessment": [
                {
                    "$lookup": {
                        from: "InitialAssessment",
                        localField: "initialAssessment",
                        foreignField: "_id",
                        as: "initialAssessment"
                    }
                },
                {
                    "$replaceRoot": { newRoot: { "$first": "$initialAssessment" } }

                },
            ],
            "treatments": [
                {
                    "$lookup": {
                        from: "Treatments",
                        localField: "treatments",
                        foreignField: "_id",
                        as: "treatments"
                    }
                },
                {
                    //"$addFields": { "treatments": { "$first": "$treatments" } }
                    "$replaceRoot": { newRoot: { "$first": "$treatments" } }

                },
            ],
            "tourniquetHistory": [
                {
                    "$lookup": {
                        from: "TourniquetHistory",
                        localField: "tourniquetHistory",
                        foreignField: "_id",
                        as: "tourniquetHistory"
                    }
                },
                {
                    "$addFields": { "tourniquetHistory": { "$first": "$tourniquetHistory" } }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightArmDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightArmDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightArmJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightArmJuncTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftArmDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftArmDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftArmJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftArmJuncTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightLegDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightLegDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightLegJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightLegJuncTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftLegDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftLegDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftLegJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftLegJuncTimes"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$tourniquetHistory" }
                }

            ],
            "interventions": [
                {
                    "$lookup": {
                        from: "Interventions",
                        localField: "interventions",
                        foreignField: "_id",
                        as: "interventions"
                    }
                },
                {
                    "$addFields": { "interventions": { "$first": "$interventions" } }
                },
                {
                    "$lookup": {
                        from: "CPR",
                        localField: "interventions.cprApplications",
                        foreignField: "_id",
                        as: "interventions.cprApplications"
                    }
                },
                {
                    "$lookup": {
                        from: "Defibrillator",
                        localField: "interventions.defibApplications",
                        foreignField: "_id",
                        as: "interventions.defibApplications"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$interventions" }
                }
            ],
            "medications": [
                {
                    "$lookup": {
                        from: "Medications",
                        localField: "medications",
                        foreignField: "_id",
                        as: "medications",
                    }
                },
                {
                    "$addFields": { "medications": { "$first": "$medications" } }
                },
                {
                    "$lookup": {
                        from: "Administration",
                        localField: "medications.administrations",
                        foreignField: "_id",
                        as: "medications.administrations"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$medications" }
                }
            ],
            "disposition": [
                {
                    "$lookup": {
                        from: "Disposition",
                        localField: "disposition",
                        foreignField: "_id",
                        as: "disposition"
                    },
                },
                {
                    "$addFields": { "disposition": { "$first": "$disposition" } }
                },
                {
                    "$lookup": {
                        from: "PmrOne",
                        localField: "disposition.pmrOne",
                        foreignField: "_id",
                        as: "disposition.pmrOne"
                    }
                },
                {
                    "$lookup": {
                        from: "PmrTwo",
                        localField: "disposition.pmrTwo",
                        foreignField: "_id",
                        as: "disposition.pmrTwo"
                    }
                },
                {
                    "$lookup": {
                        from: "Medevac",
                        localField: "disposition.medevac",
                        foreignField: "_id",
                        as: "disposition.medevac"
                    }
                },
                {
                    "$lookup": {
                        from: "Ccast",
                        localField: "disposition.ccast",
                        foreignField: "_id",
                        as: "disposition.ccast"
                    }
                },
                {
                    "$addFields": {
                        "disposition.medevac": { "$first": "$disposition.medevac" },
                        "disposition.pmrOne": { "$first": "$disposition.pmrOne" },
                        "disposition.pmrTwo": { "$first": "$disposition.pmrTwo" },
                        "disposition.ccast": { "$first": "$disposition.ccast" },
                    }
                },
                {
                    "$lookup": {
                        from: "Relative",
                        localField: "disposition.pmrOne.relatives",
                        foreignField: "_id",
                        as: "disposition.pmrOne.relatives"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$disposition" }
                }
            ],
            "ecgMeasurements": [
                {
                    "$lookup": {
                        from: "MeasurementECG",
                        localField:"ecgMeasurements",
                        foreignField:"_id",
                        as: "ecgMeasurements.values"
                    }
                },                
                {
                    "$replaceRoot": { newRoot: "$ecgMeasurements" }
                }                
            ],
            "root": [
                {
                    "$project": { "carePlan": 1, "_id": 1, "caseId": 1 }
                }
            ],
            "annotations": [
                {
                    "$lookup": {
                        from: "Annotation",
                        localField: "annotations",
                        foreignField: "_id",
                        as: "annotations"
                    }
                },
                //{
                    //"$addFields": { "annotations":"$annotations" } 
                    //"$replaceRoot": { newRoot: "$annotations" }

                //},
            ]
        },
    },
    {
        "$addFields":
        {
            "patientDetails": { "$first": "$patientDetails" },
            "ample": { "$first": "$ample" },
            "vitals": { "$first": "$vitals" },
            "vitalsHistory": { "$first": "$vitalsHistory" },
            "incident": { "$first": "$incident" },
            "timings": { "$first": "$timings" },
            "primarySurvey": { "$first": "$primarySurvey" },
            "injuries": { "$first": "$injuries" },
            "treatments": { "$first": "$treatments" },
            "tourniquetHistory": { "$first": "$tourniquetHistory" },
            "interventions": { "$first": "$interventions" },
            "medications": { "$first": "$medications" },
            "carePlan": { "$first": "$root.carePlan" },
            "disposition": { "$first": "$disposition" },
            "ecgMeasurements": {"$first": "$ecgMeasurements.values"},
            "initialAssessment": {"$first": "$initialAssessment"},
            "annotations": {"$first": "$annotations.annotations"},
            "_id": { "$first": "$root._id" },
            "caseId": { "$first": "$root.caseId" },
        }
    }
];

const activeVitalsDetailsFilter: globalThis.Realm.Services.MongoDB.AggregatePipelineStage[] = [
    {
        "$facet": {
         /*   "vitals": [
                {
                    "$lookup": {
                        from: "Vitals",
                        localField: "vitals",
                        foreignField: "_id",
                        as: "vitals"
                    }
                },
                { "$replaceRoot": { newRoot: { "$first": "$vitals" } } }

            ],*/
             "vitalsHistory": [
                {
                    "$lookup": {
                        from: "RealmSpo2Measurement",
                        localField: "o2History",
                        foreignField: "_id",
                        as: "o2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmHeartRateMeasurement",
                        localField: "hrHistory",
                        foreignField: "_id",
                        as: "hrHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmBloodPressureMeasurement",
                        localField: "bpHistory",
                        foreignField: "_id",
                        as: "bpHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmBloodGlucoseMeasurement",
                        localField: "bgHistory",
                        foreignField: "_id",
                        as: "bgHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmTemperatureMeasurement",
                        localField: "tempHistory",
                        foreignField: "_id",
                        as: "tempHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmRespirationRateMeasurement",
                        localField: "rrHistory",
                        foreignField: "_id",
                        as: "rrHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmAvpuMeasurement",
                        localField: "avpuHistory",
                        foreignField: "_id",
                        as: "avpuHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmCapRMeasurement",
                        localField: "capRHistory",
                        foreignField: "_id",
                        as: "capRHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmCO2Measurement",
                        localField: "co2History",
                        foreignField: "_id",
                        as: "co2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmETCO2Measurement",
                        localField: "etco2History",
                        foreignField: "_id",
                        as: "etco2History"

                    }
                },
                /*{
                    "$addFields": {
                        "vitalsHistory.rrHistory": "$rrHistory",
                        "vitalsHistory.o2History": "$o2History",
                        "vitalsHistory.hrHistory": "$hrHistory",
                        "vitalsHistory.bpHistory": "$bpHistory",
                        "vitalsHistory.bgHistory": "$bgHistory",
                        "vitalsHistory.tempHistory": "$tempHistory",
                    }
                },*/
                {
                    /*"$replaceRoot": { newRoot: { "$first": "$vitalsHistory" } }*/
                    "$addFields": {
                        "vitalsHistory": { "$first": "$vitalsHistory" }
                    }
                },
            ],
        },
    },
    {
        "$addFields":
        {
            //"vitals": { "$first": "$vitals" },
            "vitalsHistory": {"$first": "$vitalsHistory"},
            //"_id": { "$first": "$root._id" },
        }
    }
];

const historicCasesDetailFilter: globalThis.Realm.Services.MongoDB.AggregatePipelineStage[] = [
    {
        "$facet": {
            "patientDetails": [
                {
                    "$lookup": {
                        from: "PatientDetails",
                        localField: "patientDetails",
                        foreignField: "_id",
                        as: "patientDetails"
                    }
                },
                //{ "$addFields": { "patientDetails": { "$first": "$patientDetails" } } },
                { "$replaceRoot": { newRoot: { "$first": "$patientDetails" } } }
            ],
            "ample": [
                {
                    "$lookup": {
                        from: "AltAmple",
                        localField: "ample",
                        foreignField: "_id",
                        as: "ample"
                    }
                },
                { "$addFields": { "ample": { "$first": "$ample" } } },
                {
                    "$lookup": {
                        from: "AltAmpleA",
                        localField: "ample.allergies",
                        foreignField: "_id",
                        as: "ample.allergies"
                    }
                },
                {
                    "$lookup": {
                        from: "AltAmpleM",
                        localField: "ample.medications",
                        foreignField: "_id",
                        as: "ample.medications"
                    }
                },
                {
                    "$lookup": {
                        from: "AltAmpleP",
                        localField: "ample.pastMedicalHistory",
                        foreignField: "_id",
                        as: "ample.pastMedicalHistory"
                    }
                },
                {
                    "$addFields": {
                        "ample.allergies": { "$first": "$ample.allergies" },
                        "ample.medications": { "$first": "$ample.medications" },
                        "ample.pastMedicalHistory": { "$first": "$ample.pastMedicalHistory" }
                    }
                },
                {
                    "$lookup": {
                        from: "Administration",
                        localField: "ample.medications.administrations",
                        foreignField: "_id",
                        as: "ample.medications.administrations"
                    }
                },
                { "$replaceRoot": { newRoot: "$ample" } }

            ],
            "vitals": [
                {
                    "$lookup": {
                        from: "Vitals",
                        localField: "vitals",
                        foreignField: "_id",
                        as: "vitals"
                    }
                },
                { "$replaceRoot": { newRoot: { "$first": "$vitals" } } }

            ],
            "vitalsHistory": [
                {
                    "$lookup": {
                        from: "VitalsHistory",
                        localField: "vitalsHistory",
                        foreignField: "_id",
                        as: "vitalsHistory"
                    }
                },
                {
                    "$lookup": {
                        from: "RealmSpo2Measurement",
                        localField: "vitalsHistory.o2History",
                        foreignField: "_id",
                        as: "o2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmHeartRateMeasurement",
                        localField: "vitalsHistory.hrHistory",
                        foreignField: "_id",
                        as: "hrHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmBloodPressureMeasurement",
                        localField: "vitalsHistory.bpHistory",
                        foreignField: "_id",
                        as: "bpHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmBloodGlucoseMeasurement",
                        localField: "vitalsHistory.bgHistory",
                        foreignField: "_id",
                        as: "bgHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmTemperatureMeasurement",
                        localField: "vitalsHistory.tempHistory",
                        foreignField: "_id",
                        as: "tempHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmRespirationRateMeasurement",
                        localField: "vitalsHistory.rrHistory",
                        foreignField: "_id",
                        as: "rrHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmAvpuMeasurement",
                        localField: "vitalsHistory.avpuHistory",
                        foreignField: "_id",
                        as: "avpuHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmCapRMeasurement",
                        localField: "vitalsHistory.capRHistory",
                        foreignField: "_id",
                        as: "capRHistory"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmCO2Measurement",
                        localField: "vitalsHistory.co2History",
                        foreignField: "_id",
                        as: "co2History"

                    }
                },
                {
                    "$lookup": {
                        from: "RealmETCO2Measurement",
                        localField: "vitalsHistory.etco2History",
                        foreignField: "_id",
                        as: "etco2History"

                    }
                },
                {
                    "$addFields": {
                        "vitalsHistory.rrHistory": "$rrHistory",
                        "vitalsHistory.o2History": "$o2History",
                        "vitalsHistory.hrHistory": "$hrHistory",
                        "vitalsHistory.bpHistory": "$bpHistory",
                        "vitalsHistory.bgHistory": "$bgHistory",
                        "vitalsHistory.tempHistory": "$tempHistory",
                        "vitalsHistory.avpuHistory": "$avpuHistory",
                        "vitalsHistory.capRHistory": "$capRHistory",
                        "vitalsHistory.co2History": "$co2History",
                        "vitalsHistory.etco2History": "$etco2History",

                    }
                },
                {
                    "$replaceRoot": { newRoot: { "$first": "$vitalsHistory" } }
                    /*"$addFields": {
                        "vitalsHistory": { "$first": "$vitalsHistory" }
                    }*/
                },
            ],
            "incident": [
                {
                    "$lookup": {
                        from: "Incident",
                        localField: "incident",
                        foreignField: "_id",
                        as: "incident"
                    }
                },
                {
                    //"$addFields": { "incident": { "$first": "$incident" } }
                    "$replaceRoot": { newRoot: { "$first": "$incident" } }
                }

            ],
            "timings": [
                {
                    "$lookup": {
                        from: "Timings",
                        localField: "timings",
                        foreignField: "_id",
                        as: "timings"
                    }
                },
                {
                    //"$addFields": { "timings": { "$first": "$timings" } }
                    "$replaceRoot": { newRoot: { "$first": "$timings" } }
                }
            ],
            "primarySurvey": [
                {
                    "$lookup": {
                        from: "PrimarySurvey",
                        localField: "primarySurvey",
                        foreignField: "_id",
                        as: "primarySurvey"
                    }
                },
                {
                    "$addFields": { "primarySurvey": { "$first": "$primarySurvey" } }
                },
                {
                    "$lookup": {
                        from: "PSCatastrophicHaemorrhage",
                        localField: "primarySurvey.catHem",
                        foreignField: "_id",
                        as: "catHem"
                    },
                },
                {
                    "$lookup": {
                        from: "PSCervicalSpine",
                        localField: "primarySurvey.cerSpin",
                        foreignField: "_id",
                        as: "cerSpin"
                    },
                },
                {
                    "$lookup": {
                        from: "PSAirway",
                        localField: "primarySurvey.airway",
                        foreignField: "_id",
                        as: "airway"
                    },
                },
                {
                    "$lookup": {
                        from: "PSBreathing",
                        localField: "primarySurvey.breathing",
                        foreignField: "_id",
                        as: "breathing"
                    },
                },
                {
                    "$lookup": {
                        from: "PSCirculation",
                        localField: "primarySurvey.circulation",
                        foreignField: "_id",
                        as: "circulation"
                    },
                },
                {
                    "$lookup": {
                        from: "PSDisability",
                        localField: "primarySurvey.disability",
                        foreignField: "_id",
                        as: "disability"
                    },
                },
                {
                    "$lookup": {
                        from: "PSExamination",
                        localField: "primarySurvey.examination",
                        foreignField: "_id",
                        as: "examination"
                    },
                },
                {
                    "$lookup": {
                        from: "Sepsis",
                        localField: "primarySurvey.sepsis",
                        foreignField: "_id",
                        as: "sepsis"
                    },
                },
                {
                    "$addFields": {
                        "primarySurvey.catHem": { "$first": "$catHem" },
                        "primarySurvey.cerSpin": { "$first": "$cerSpin" },
                        "primarySurvey.airway": { "$first": "$airway" },
                        "primarySurvey.breathing": { "$first": "$breathing" },
                        "primarySurvey.circulation": { "$first": "$circulation" },
                        "primarySurvey.disability": { "$first": "$disability" },
                        "primarySurvey.examination": { "$first": "$examination" },
                        "primarySurvey.sepsis": { "$first": "$sepsis" },
                    }
                },
                {
                    "$lookup": {
                        from: "Annotation",
                        localField: "primarySurvey.examination.frontAnnotations",
                        foreignField: "_id",
                        as: "primarySurvey.examination.frontAnnotations"
                    },
                },
                {
                    "$lookup": {
                        from: "Annotation",
                        localField: "primarySurvey.examination.backAnnotations",
                        foreignField: "_id",
                        as: "primarySurvey.examination.backAnnotations"
                    },
                },
                { "$replaceRoot": { newRoot: "$primarySurvey" } }

            ],
            "injuries": [
                {
                    "$lookup": {
                        from: "Injuries",
                        localField: "injuries",
                        foreignField: "_id",
                        as: "injuries"
                    }
                },
                {
                    //"$addFields": { "injuries": { "$first": "$injuries" } }
                    "$replaceRoot": { newRoot: { "$first": "$injuries" } }

                },
            ],
            "initialAssessment": [
                {
                    "$lookup": {
                        from: "InitialAssessment",
                        localField: "initialAssessment",
                        foreignField: "_id",
                        as: "initialAssessment"
                    }
                },
                {
                    "$replaceRoot": { newRoot: { "$first": "$initialAssessment" } }

                },
            ],
            "treatments": [
                {
                    "$lookup": {
                        from: "Treatments",
                        localField: "treatments",
                        foreignField: "_id",
                        as: "treatments"
                    }
                },
                {
                    //"$addFields": { "treatments": { "$first": "$treatments" } }
                    "$replaceRoot": { newRoot: { "$first": "$treatments" } }

                },
            ],
            "tourniquetHistory": [
                {
                    "$lookup": {
                        from: "TourniquetHistory",
                        localField: "tourniquetHistory",
                        foreignField: "_id",
                        as: "tourniquetHistory"
                    }
                },
                {
                    "$addFields": { "tourniquetHistory": { "$first": "$tourniquetHistory" } }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightArmDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightArmDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightArmJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightArmJuncTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftArmDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftArmDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftArmJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftArmJuncTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightLegDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightLegDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.rightLegJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.rightLegJuncTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftLegDistTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftLegDistTimes"
                    }
                },
                {
                    "$lookup": {
                        from: "TourniquetOnOff",
                        localField: "tourniquetHistory.leftLegJuncTimes",
                        foreignField: "_id",
                        as: "tourniquetHistory.leftLegJuncTimes"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$tourniquetHistory" }
                }

            ],
            "interventions": [
                {
                    "$lookup": {
                        from: "Interventions",
                        localField: "interventions",
                        foreignField: "_id",
                        as: "interventions"
                    }
                },
                {
                    "$addFields": { "interventions": { "$first": "$interventions" } }
                },
                {
                    "$lookup": {
                        from: "CPR",
                        localField: "interventions.cprApplications",
                        foreignField: "_id",
                        as: "interventions.cprApplications"
                    }
                },
                {
                    "$lookup": {
                        from: "Defibrillator",
                        localField: "interventions.defibApplications",
                        foreignField: "_id",
                        as: "interventions.defibApplications"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$interventions" }
                }
            ],
            "medications": [
                {
                    "$lookup": {
                        from: "Medications",
                        localField: "medications",
                        foreignField: "_id",
                        as: "medications",
                    }
                },
                {
                    "$addFields": { "medications": { "$first": "$medications" } }
                },
                {
                    "$lookup": {
                        from: "Administration",
                        localField: "medications.administrations",
                        foreignField: "_id",
                        as: "medications.administrations"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$medications" }
                }
            ],
            "disposition": [
                {
                    "$lookup": {
                        from: "Disposition",
                        localField: "disposition",
                        foreignField: "_id",
                        as: "disposition"
                    },
                },
                {
                    "$addFields": { "disposition": { "$first": "$disposition" } }
                },
                {
                    "$lookup": {
                        from: "PmrOne",
                        localField: "disposition.pmrOne",
                        foreignField: "_id",
                        as: "disposition.pmrOne"
                    }
                },
                {
                    "$lookup": {
                        from: "PmrTwo",
                        localField: "disposition.pmrTwo",
                        foreignField: "_id",
                        as: "disposition.pmrTwo"
                    }
                },
                {
                    "$lookup": {
                        from: "Medevac",
                        localField: "disposition.medevac",
                        foreignField: "_id",
                        as: "disposition.medevac"
                    }
                },
                {
                    "$lookup": {
                        from: "Ccast",
                        localField: "disposition.ccast",
                        foreignField: "_id",
                        as: "disposition.ccast"
                    }
                },
                {
                    "$addFields": {
                        "disposition.medevac": { "$first": "$disposition.medevac" },
                        "disposition.pmrOne": { "$first": "$disposition.pmrOne" },
                        "disposition.pmrTwo": { "$first": "$disposition.pmrTwo" },
                        "disposition.ccast": { "$first": "$disposition.ccast" },
                    }
                },
                {
                    "$lookup": {
                        from: "Relative",
                        localField: "disposition.pmrOne.relatives",
                        foreignField: "_id",
                        as: "disposition.pmrOne.relatives"
                    }
                },
                {
                    "$replaceRoot": { newRoot: "$disposition" }
                }
            ],
            "ecgMeasurements": [
                {
                    "$lookup": {
                        from: "MeasurementECG",
                        localField:"ecgMeasurements",
                        foreignField:"_id",
                        as: "ecgMeasurements.values"
                    }
                },                
                {
                    "$replaceRoot": { newRoot: "$ecgMeasurements" }
                }                
            ],
            "root": [
                {
                    "$project": { "carePlan": 1, "_id": 1 }
                }
            ]
        },
    },
    {
        "$addFields":
        {
            "patientDetails": { "$first": "$patientDetails" },
            "ample": { "$first": "$ample" },
            "vitals": { "$first": "$vitals" },
            "vitalsHistory": { "$first": "$vitalsHistory" },
            "incident": { "$first": "$incident" },
            "timings": { "$first": "$timings" },
            "primarySurvey": { "$first": "$primarySurvey" },
            "injuries": { "$first": "$injuries" },
            "treatments": { "$first": "$treatments" },
            "tourniquetHistory": { "$first": "$tourniquetHistory" },
            "interventions": { "$first": "$interventions" },
            "medications": { "$first": "$medications" },
            "carePlan": { "$first": "$root.carePlan" },
            "disposition": { "$first": "$disposition" },
            "ecgMeasurements": {"$first": "$ecgMeasurements.values"},
            "initialAssessment": {"$first": "$initialAssessment"},
            "_id": { "$first": "$root._id" },
        }
    }


];

const combineAsyncIterables = async function* <T>(
    asyncIterables: AsyncIterable<T>[],
  ): AsyncGenerator<T> {
    const asyncIterators = Array.from(asyncIterables, (o) =>
      o[Symbol.asyncIterator](),
    );
    const results = [];
    let count = asyncIterators.length;
    const noOp = ()=> {};
    const never: Promise<never> = new Promise(noOp);
    const getNext = (asyncIterator: AsyncIterator<T>, index: number) =>
      asyncIterator.next().then((result) => ({ index, result }));
  
    const nextPromises = asyncIterators.map(getNext);
    try {
      while (count) {
        const { index, result } = await Promise.race(nextPromises);
        if (result.done) {
          nextPromises[index] = never;
          results[index] = result.value;
          count--;
        } else {
          nextPromises[index] = getNext(asyncIterators[index], index);
          yield result.value;
        }
      }
    } finally {
      for (const [index, iterator] of asyncIterators.entries()) {
        if (nextPromises[index] != never && iterator.return != null) {
          // no await here - see https://github.com/tc39/proposal-async-iteration/issues/126
          void iterator.return();
        }
      }
    }
}

/*async function activeCasesSummary(cases: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>) {
    return await cases.aggregate(activeCasesSummaryFilter);
}

async function historicCasesSummary(cases: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>) {
    return await cases.aggregate(historicCasesSummaryFilter);
}*/
