import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { PatientDetails, Ccast } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import DateTimeRender from "./DateTimeRenderer";
import { NumberOrMinusOneComponent } from "./NumberOrMinusOneComponent";
export function PatientDetailsComponent(p: { d:PatientDetails, c?:Ccast|null}) {
    const d = p.d;
    const c = p.c;

    return (
        <Card variant='outlined'>
            <CardHeader title={<Typography component="h1"><L t="patient-details" /> </Typography>} />

            <CardContent>
                {d && (
                    <Stack>
                        <ul>
                        <U><li><L t={'patient-details-forename'} />:&nbsp;<L t={d.name} /></li></U>
                        <U><li><L t={'patient-details-surname'} />:&nbsp;<L t={d.surname} /></li></U>
                        <U><li><L t={'patient-details-dob'} />:&nbsp; <DateTimeRender value={d.dob} format='date' /></li></U>
                        <U><li><L t={'patient-details-age'} />:&nbsp;<NumberOrMinusOneComponent v={d.age} decimalPlaces={0} units="" /></li></U>
                        <U><li><L t={'patient-details-sex'} />:&nbsp; <L t={d.sex}/>  </li></U>

                        <U><li><L t={'patient-details-service-no'} />:&nbsp; <L t={d.serviceNumber}/>  </li></U>
                        <U><li><L t={'patient-details-unit'} />:&nbsp; <L t={d.unit}/>  </li></U>
                        <U><li><L t={'patient-details-rank'} />:&nbsp; <L t={d.rank}/>  </li></U>
                        <U><li><L t={'patient-details-zap'} />:&nbsp; <L t={d.zapNumber}/>  </li></U>
                        <U><li><L t={'patient-details-blood-group'} />:&nbsp; <L t={c?.bloodGroup}/>  </li></U>
                        <U><li><L t={'patient-details-blood-rhd'} />:&nbsp; <L t={c?.bloodRhd}/>  </li></U>
                        </ul>
                    </Stack>
                )}
            </CardContent>
        </Card>
    )

}

