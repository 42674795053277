import L from "./localeText";
import { AzureClientConsumer } from '../providers/azureClientProvider';
import { Grid, Typography } from '@mui/material';
import { LoadingSpinner } from './LoadingSpinner';
import { ReactNode, useEffect, useState } from 'react';
import { ECGComponent } from "./ECGComponent";
import { Annotation, CaseDetail } from "../models/Cases";
import { CanvasComponent } from "./CanvasComponent";

export function FetchCaseImagery(p: { selectedCase: CaseDetail; dispatch: React.Dispatch<any>; filterToImageTypes?: string[]; }) {
    const c = p.selectedCase;
    const dispatch = p.dispatch;

    return <AzureClientConsumer>
        {({ state, dispatch }) => {
            if (state.selectedCaseId && state.azureCaseData) {
                let f = () => {
                    // any image without an annotation has been deleted
                    // where should always be at least ONE annotation per image
                    var imagesWithAnnotations = (state.azureCaseData as [{ filename: string; }]).filter(x => c.annotations.some(a => a.filename == x.filename));
                    var groupedByType = groupBy(imagesWithAnnotations, 'datatype');
                    var filteredToTypes = Object.keys(groupedByType).filter(t => t.startsWith('Image') 
                        && (!p.filterToImageTypes || p.filterToImageTypes.findIndex(f=>t.includes(f)) >= 0  )   )
                    const images = filteredToTypes.map(t => <>
                        <Typography component='h3'>
                            <L t={t} />
                        </Typography>
                        {buildImageComponents(groupedByType, t, c)}

                    </>
                    );
                    var filteredToTypes = Object.keys(groupedByType).filter(t => t.startsWith('ECG') 
                        && (!p.filterToImageTypes || p.filterToImageTypes.includes(t) )   )
                    const ecgs = filteredToTypes.map(t => <>
                        <Typography component='h3'>
                            <L t={t} />
                        </Typography>
                        <Grid container spacing={1}>{(groupedByType[t] || [] as []).map((i: any) => <Grid md={12} item><ECGComponent d={i} ecgMeasurements={p.selectedCase.ecgMeasurements} /></Grid>)}</Grid>

                    </>
                    );

                    return <>{images}{ecgs}</>;
                };
                return f();

            } else {
                return <LoadingSpinner />;
            }
        }}
    </AzureClientConsumer>;
}
function buildImageComponents(groupedByType: any, t: string, c: CaseDetail) {
    return <Grid container spacing={1}>{(groupedByType[t] || [] as []).map(buildImageComponent(c))}</Grid>;
}
function buildImageComponent(c: CaseDetail): ReactNode {
    return (i: { fetchImage: () => Promise<Blob | null>; name: string | null; dataType: string; caseId: string; filename: string; }) => {
        //console.log(i);
        const annotationsForThisImage = c.annotations.filter(a => a.filename == i.filename);
        const titleAnnotationIndex = annotationsForThisImage.findIndex(a => a.annotation.startsWith('##'));
        const title = titleAnnotationIndex >= 0 ? annotationsForThisImage[titleAnnotationIndex].annotation.replaceAll('#', '') : i.name;
        let actualWidth = undefined;
        let actualHeight = undefined;
        if (titleAnnotationIndex >= 0) {
            actualWidth = annotationsForThisImage[titleAnnotationIndex].sX;
            actualHeight = annotationsForThisImage[titleAnnotationIndex].sY;
            annotationsForThisImage.splice(titleAnnotationIndex, 1);
        }

        return <Grid md={4} item><p>{title}</p><ImageComponent d={i} a={annotationsForThisImage} actualWidth={actualWidth} actualHeight={actualHeight} /></Grid>;
    };
}
function ImageComponent(p: { a: Annotation[]; d: { fetchImage: () => Promise<Blob | null>; name: string | null; dataType: string; caseId: string; filename: string; }; actualWidth?: number; actualHeight?: number; }) {
    const [imageData, setImageData] = useState<string | null>(null);
    const [blob, setBlob] = useState<ArrayBuffer | null>(null);

    useEffect(() => {
        async function f() {
            let img = await p.d.fetchImage();
            if (img != null) {
                const url = URL.createObjectURL(img);

                setImageData(url);
            }
        }
        f();
    }, [p.d]);

    const scalex = p.actualWidth ? undefined : 0.29;
    const scaley = p.actualHeight ? undefined : 0.29;

    const c = imageData ?
        <CanvasComponent background={imageData} markers={p.a} scalex={scalex} scaley={scaley} imageActualWidth={p.actualWidth} imageActualHeight={p.actualHeight} /> : <p>{'loading...'}</p>;
    return c;
}
var groupBy = function (xs: object[], key: string): { [key: string]: []; } {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
