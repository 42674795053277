import { Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";


export function CycleComponent(p: { availableItems: any, data: string }) {
    const a = p.availableItems;
    const d = p.data;
    const { t } = useTranslation();
    var items: ReactNode[] = [];

    const match = Object.keys(a || {}).filter(k => k === d);
    if (match && match.length) {
        const text = t(a[match[0]].text) || a[match[0]].text;
        const sx = a[match[0]].sx;
        items.push(<Grid item> <Chip label={text} sx={sx} /></Grid>)
    }
    if (!items.length) items.push(<Grid item></Grid>)


    return (
        <Grid container spacing={2} flexWrap='wrap'>
            {items}
        </Grid>);
}