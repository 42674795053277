import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Timings } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import DateTimeRender from "./DateTimeRenderer";

export function TimingsComponent(p: { t: Timings }) {

    const t = p.t;

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t="timings-title" /> </Typography>} />

            <CardContent>
                {t && (
                    <ul>
                        <li><TimingsDetail label={'timings-incident'} date={p.t.incident} /></li>
                        <li><TimingsDetail label={'timings-assigned'} date={p.t.assigned} /></li>
                        <li><TimingsDetail label={'timings-dispatched'} date={p.t.dispatched} /></li>
                        <li><TimingsDetail label={'timings-atLocation'} date={p.t.atLocation} /></li>
                        <li><TimingsDetail label={'timings-atPatient'} date={p.t.atPatient} /></li>
                        <li><TimingsDetail label={'timings-leaveLocation'} date={p.t.leaveLocation} /></li>
                        <li><TimingsDetail label={'timings-atDestination'} date={p.t.atDestination} /></li>
                        <li><TimingsDetail label={'timings-leaveDestination'} date={p.t.leaveDestination} /></li>
                        <li><TimingsDetail label={'timings-cleared'} date={p.t.cleared} /></li>
                    </ul>
                )}
                {!t && (<Typography component="body"><L t="No timings recorded." /></Typography>)}
            </CardContent>
        </Card>
    )
}

function TimingsDetail(p:{label:string, date?:Date}) {
    return <U><Typography  component="p"><L t={p.label} />: <DateTimeRender value={p.date} format='evt' /></Typography></U>;

}