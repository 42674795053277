// @ts-nocheck

//import {ResponsiveLine} from "@nivo/line"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  } from 'chart.js'
  import { Chart,Line } from 'react-chartjs-2'
  import {enGB} from 'date-fns/locale';
  import "chartjs-adapter-date-fns";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  )


export const options = {
    spanGaps:true,
    scales: {
        x: {
          type: "time",
          time: {
            unit: "second",
            displayFormats: {
              hour:"hh"
            }
          },
          adapters: {date: enGB},
          ticks: { source: "auto" },
          offset: false,
          grid: {
            //display: false,
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: true
          },
          font: {
            size: 8
          }
        },
        xAxis2: {
          type: "time",
          time: {
            unit: "day"
          }
        }
        // y: {
        //   ticks: {
        //     fontSize: 8,
        //     beginAtZero: false,
        //     stepSize: 10
        //   }
        // }
      }
    };    


export function LineGraphComponent(p:{data:any[]}) {
    //console.log(p.data)
    const labels = p.data.map(x=>x.id)
    //console.log(labels);
    p.data.forEach(x=>x.borderColor=x.color)
    p.data.forEach(x=>x.label=x.id)

    return <Line 
        datasetIdKey='id'
        data = {{ 
            datasets:p.data,
            labels: {labels}

        }} options={options}
    />


}


function timeString(d:Date):String {
    return d.toLocaleTimeString()
}