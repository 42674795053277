import { Container, Typography } from "@mui/material";

export function AdminPage() {
    return (
        <Container>
        <Typography>
        <h2>Administration</h2>
        </Typography>
        </Container>
    )
}