import React, { createContext, useContext, useEffect, useReducer } from "react";
import { AppContext, AppState } from "./appContextProvider";

type AzureState = { azureClient:AzureClient, team: string, caseType: string,
     queryBlobUrl:URL|null,
     fetchBlobUrl:URL|null,
     selectedCaseId: string, azureCaseData: object | null };

const azureStateReducer = function (state: AzureState, action: { type: string, data: any }): AzureState {
    let newState = state;
    switch (action.type) {
        case "init":
            state.azureClient._dispatch = action.data.dispatch;
            state.azureClient._appState = action.data.appContext.state;
            break;
        case "fetchConfigData":
            state.azureClient.fetchConfig();
            break;
        case "fetchedConfigData":
            newState = { ...state, ...action.data};
            break;
        case "caseSelectedChange":
            newState = { ...state, selectedCaseId: action.data, azureCaseData:null };
            state.azureClient.fetchAzureDataForCaseId(newState);
            break;
        case "refreshCaseData":
            state.azureClient.fetchAzureDataForCaseId(state);
            break;
        case "fetchCaseData":
            newState = {...state, selectedCaseId:action.data};
            break;
        case "fetchedCaseData":
            newState = { ...state, 
                azureCaseData: [...(action.data||[]).map( (d:any)=> ({...d, fetchImage: ()=>state.azureClient.fetchImage(
                    {caseId:d['caseId'],
                    filename:d['filename'],
                    caseType:d['caseType'],
                    team:d['team'],
                    dataType:d['datatype']
                 }, state) }) ) ] };
            break;

    };

    return newState;
};

class AzureClient {
    _dispatch: React.Dispatch<any> | null = null;
    _appState : AppState | null = null;

    fetchConfig = () => {
        const d = this._dispatch || (()=>{}); 
        
        d({
            type:'fetchedConfigData',
            data:{
                team: 'dev',
                caseType: 'epr',
                queryBlobUrl: new URL("https://rapideprdownloadservice.azurewebsites.net/api/QueryBlobs?code=aWMQBXTZALs-e09Ncav7Wc8h4Gq56kn_2FQFJaQiZXIJAzFuzrTzng==" ) ,
                fetchBlobUrl: new URL("https://rapideprdownloadservice.azurewebsites.net/api/DownloadBlob?code=aWMQBXTZALs-e09Ncav7Wc8h4Gq56kn_2FQFJaQiZXIJAzFuzrTzng==" ) 
            }
        }); 
    }


    fetchAzureDataForCaseId = (state: AzureState) => {
        const c = state.selectedCaseId;        
        const d = this._dispatch || (()=>{}); 
        d({ type: 'fetchCaseData', data: c });
        if (state.queryBlobUrl) {
            var req = new Request(`${state.queryBlobUrl}&c=${c}&ct=${state.caseType}&t=${state.team}`);
            fetch(req, {
                headers : {
                    'Authorization': "Bearer "+ this._appState?.user?.accesstoken ,
                    'X-ZUMO-AUTH': "" + this._appState?.user?.accesstoken
                }
            })
            .then((response)=> response.json()
            .then((obj)=> d({type:"fetchedCaseData", data:obj})))
            .catch((err)=> console.log(err));
        }

    }
    fetchImage = async (m:{caseId:string, filename:string, caseType:string, team:string, dataType:string},s:AzureState):Promise<Blob|undefined|null> => {

        //if (s.fetchBlobUrl) {
            var req = new Request(`${s.fetchBlobUrl}&c=${m.caseId}&f=${m.filename}&ct=${m.caseType}&t=${m.team}&dt=${m.dataType}`);
            return fetch(req, {
                headers : {
                    'Authorization': "Bearer "+ this._appState?.user?.accesstoken ,
                    'X-ZUMO-AUTH': "" + this._appState?.user?.accesstoken
                }
            })
            //.catch((err)=> console.log(err)));
            .then((response)=> 
            response.blob()
            .catch( err => {console.log(err); return null;})
            )
        //}

    }
}


type AzureClientContextType = { state: AzureState, dispatch: React.Dispatch<any> };

const initialState: AzureClientContextType = { state: { azureClient: new AzureClient(), team: '', caseType: '', selectedCaseId: '', queryBlobUrl:null, fetchBlobUrl:null, azureCaseData: null }, dispatch: () => { } };

const AzureClientContext = createContext<AzureClientContextType>(initialState);


function AzureClientProvider(props: { children: React.ReactNode }) {

    const fullState = initialState.state;

    const [state, Dispatch] = useReducer(azureStateReducer, fullState);

    const appContext =  useContext(AppContext);

    useEffect(() => {
         Dispatch({type:'init', data:{dispatch:Dispatch, appContext:appContext }});
         Dispatch({type:'fetchConfigData', data:null});
        },[Dispatch, appContext]);


    const value = React.useMemo(() => { return { state, dispatch: Dispatch }; }, [state, Dispatch]);


    return <AzureClientContext.Provider value={value}>{props.children}</AzureClientContext.Provider>
}

const AzureClientConsumer = AzureClientContext.Consumer;

export { AzureClientContext, AzureClientProvider, AzureClientConsumer };

