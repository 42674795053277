import React, { createContext, useEffect, useMemo, useReducer } from "react";
import RSA from 'react-simple-auth';
import { microsoftProvider } from '../providers/microsoft';


type AppState = { user?:{username:string, displayname:string, accesstoken:string}}


const appStateReducer = function(state:AppState, action:{type:string, data:any}):AppState {
    switch (action.type) {
        case "login":
            const decodedIdToken = action.data.decodedIdToken;
            if (action.data.decodedIdToken) {
                return {...state, user:{username:decodedIdToken.name, displayname:decodedIdToken.preferred_username, accesstoken:action.data.accessToken}}
            }
            break;
        case "logout":
            let newState = {...state};
            delete newState.user;
            RSA.invalidateSession();
            return newState;
        default:
            break;
    }
    return state;
}



let initialState:AppState =  {} ;



const AppContext = createContext<{state:AppState, dispatch:React.Dispatch<any>}>(
    {state:initialState, dispatch:()=>{}});
    
function AppContextProvider (props:{children:React.ReactNode}) {

    const fullState = {
        ...initialState        
    }

    const [state,Dispatch] = useReducer(appStateReducer, fullState)

    useEffect(()=>{
    (async ()=>{
         

        const existingSession = await RSA.restoreSession(microsoftProvider);
        if (existingSession && state.user?.accesstoken !== existingSession.accessToken) {            
           Dispatch({type:'login', data:{...existingSession}})
        }
        })()},[state,Dispatch]);
         



let value = useMemo(()=> {return {state,dispatch:Dispatch};}, [state,Dispatch])

return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
}

const AppContextConsumer = AppContext.Consumer

export {AppContext, AppContextProvider, AppContextConsumer};
export type {AppState};


