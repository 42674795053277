import { MonitorHeart, Info, Map } from "@mui/icons-material";
import { List, IconButton, Stack } from "@mui/material";
import { ReactNode } from "react";
import { NavigateFunction } from "react-router";
import { CaseDetail } from "../models/Cases";

export function BuildSecondaryActions(c: CaseDetail, iconSize: number = 40, handleClick: (id: string, type: string) => void, direction: 'row' | 'stack',
  showEcg: boolean, showMap: boolean, showInfo: boolean): ReactNode {
  const e = (showEcg ? (<IconButton onClick={() => handleClick(c._id, 'ecg')} ><MonitorHeart sx={{ fontSize: iconSize }} /></IconButton>) : <></>)
  const m = (showMap ? (<IconButton onClick={() => handleClick(c._id, 'map')} ><Map sx={{ fontSize: iconSize }} /></IconButton>) : <></>)
  const i = (showInfo ? (<IconButton onClick={() => handleClick(c._id, 'full')} ><Info sx={{ fontSize: iconSize }} /></IconButton>) : <></>)

  return (<List component={direction === 'row' ? List : Stack} >
    {e}
    {m}
    {i}
  </List>);
}

export function PerformStandardAction(navigate: NavigateFunction, id: string, type: string) {
  switch (type) {
    case "ecg":
    case "full":
      navigate(`/caseDetail?id=${id}&tab=${type}`);
      break;
    case "map":
      navigate(`/mapPage?id=${id}`);
      break;

  }

}