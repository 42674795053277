export const TRANSLATIONS_DE = {
    "yes" : "Ja",
    "no" : "Nein",

    "name": "Name",
    "gender": "Geschlecht",
    "zap":"zap",

    "dashboard-title": "Instrumententafel",

    "active cases": "Aktive Fälle",
    "historic cases": "Historische Fälle",


    "ample":"AMPLE",
    "allergies":"Allergien",
    "past medical history":"Past Medical History",
    "medications":"Medikationen",
    "no ample details recorded.": "No Ample details recorded.",
    "asprin":"Asprin",
    "carbamazepine":"Carbamazepine",
    "cephalosporin" : "Cephalosporin",
    "codeine" : "Kodein" ,
    "insulin" : "Insulin",
    "latex" : "Latex",
    "nsaid" : "NSAR",
    "penicillin" : "Penicillin",
    "phenytoin" : "Phenytoin",
    "sulphonamide" : "Sulfonamid",
    "other" : "Andere",
    "none" : "Keine",
    "unknown" : "Unbekannt",
    "no medical history recorded.":"No Medical History recorded.",
    "last oral intake":"Last Oral Intake",
    "event": "Event",
    "hours": "Hours",


    "cancer":"Krebs",
    "cva":"Schlaganfall",
    "diabetes" : "Diabetes",
    "epilepsy":"Epilepsie",
    "chronic heart condition":"Herzerkrankung",
    "hypertension":"Bluthochdruck",
    "chronic lung condition":"Lungenerkrankung",
    "mental illness":"Psychische Erkrankung",
    "physically disabled":"Behindert",
    "pregnant":"Schwanger",
    "myocardial infarction":"Infarkt myokardu",

    "chooseLocale": "Sprache wählen",
    "close": "Schließen",
    "uk": "Großbritannien",
    "unitedstates" : "USA",
    "german": "Deutsch",
    "french": "Französisch",
    "czech": "Tschechien",
    "spain": "Spanisch",

    "label-vitals-time":"Zeit",
    "label-vitals-vital-sign":"Messung",
    "label-vitals-value": "Wert",
    "vitals-label-o2History":"Sauerstoffsättigung",
    "vitals-label-systolicBp": "Blutdruck",
    "vitals-label-hrHistory": "Pulsschlag",
    "vitals-label-skintemphistory": "Hauttemperatur",
    "vitals-label-coretemphistory": "Kerntemperatur",
    "vitals-label-rrHistory": "Atemfrequenz",
    "vitals-label-bgHistory": "Blutzucker"

   };