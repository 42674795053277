import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Interventions } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import DateTimeRender from "./DateTimeRenderer";

export function InterventionsComponent(p: { i: Interventions }) {

    const t = p.i;

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'interventions-title'} /> </Typography>} />

            <CardContent>
                {t && (
                <ul>
                    <li>
                    <L t={'interventions-cric'}/>{': '}<U><L t={t.cric !=='' ? 'interventions-'+t.cric : ''}/></U>
                    </li>
                    <li>
                        <p><L t={'interventions-et-tube'} /></p>
                        <L t={'interventions-et-tube-size'} /> {': '}<U><span>{t.ettubeSize === -1 ? 'None': t.ettubeSize}</span></U>{': '}&nbsp;
                        <L t={'interventions-et-tube-outcome'}/>{': '}<U><L t={t.ettubeOutcome !== '' ?  'interventions-'+t.ettubeOutcome: ''} /></U>&nbsp;
                        <L t={'interventions-et-tube-suction'} />{': '}<U><L t={t.ettubeSuction} /></U>&nbsp;
                        <L t={'interventions-et-tube-confirmed'} />{': '}<U><>{(t.ettubeConfirm||[]).map((c,i)=><><L key={`i${i}`} t={'interventions-'+c}/> ,</>) } </></U>&nbsp;
                    </li>
                    <li>
                    <p><L t={'interventions-npa'} /></p>
                        <L t={'interventions-npa-size'} /> {': '}<U><span>{t.npaSize === -1 ? 'None': t.npaSize}</span></U>{': '}&nbsp;
                        <L t={'interventions-npa-outcome'}/>{': '}<U><L t={t.npaOutcome !== '' ? 'interventions-'+t.npaOutcome: ''} /></U>&nbsp;
                    </li>
                    <li>
                        <p><L t={'interventions-o2'} /></p>
                        <L t={'interventions-o2-flow'} /> {': '}<U><span>{t.o2Flow === -1 ? 'None': t.o2Flow}</span></U>&nbsp;{'l/min'}&nbsp;
                        <L t={'interventions-o2-mask'}/>{': '}<U><L t={t.o2Type !== '' ? 'interventions-mask-'+t.o2Type: ''} /></U>&nbsp;

                    </li>
                    <li>
                    <p><L t={'interventions-sga'} /></p>
                        <L t={'interventions-sga-size'} /> {': '}<U><span>{t.sgaSize === -1 ? 'None': t.sgaSize}</span></U>{': '}&nbsp;
                        <L t={'interventions-sga-outcome'}/>{': '}<U><L t={t.sgaOutcome !== '' ? 'interventions-'+t.sgaOutcome: ''} /></U>&nbsp;
                        <L t={'interventions-sga-suction'} />{': '}<U><L t={t.sgaSuction} /></U>&nbsp;
                        <L t={'interventions-sga-confirmed'} />{': '}<U><>{(t.sgaConfirm||[]).map((c,i)=><><L key={`c${i}`} t={'interventions-'+c}/> ,</>) } </></U>&nbsp;
                    </li>

                    <li>
                    <p><L t={'interventions-needle-d'} /></p>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <p><L t={'interventions-left'} /></p>
                                <p><L t={'interventions-needle-d-position'} /> {': '}<U><span>{t.needleDecompPositionLeft}</span></U></p>
                                <p><L t={'interventions-needle-d-size'} /> {': '}<U><span>{t.needleDecompSizeLeftF === -1 ? 'None': t.needleDecompSizeLeftF}</span></U></p>
                            </Grid>
                            <Grid item md={4}>
                                <p><L t={'interventions-right'} /></p>
                                <p><L t={'interventions-needle-d-position'} /> {': '}<U><span>{t.needleDecompPositionRight}</span></U></p>
                                <p><L t={'interventions-needle-d-size'} /> {': '}<U><span>{t.needleDecompSizeRightF === -1 ? 'None': t.needleDecompSizeRightF}</span></U></p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <L t={'interventions-needle-d-notes'} /> {': '}<U><span>{t.needleDecompNotes}</span></U>
                            </Grid>
                        </Grid>
                    </li>

                    <li>
                    <p><L t={'interventions-chest-drain'} /></p>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <p><L t={'interventions-left'} /></p>
                                <p><L t={'interventions-chest-drain-size'} /> {': '}<U><span>{t.chestDrainSizeLeftF === -1 ? 'None': t.chestDrainSizeLeftF}</span></U></p>
                            </Grid>
                            <Grid item md={4}>
                                <p><L t={'interventions-right'} /></p>
                                <p><L t={'interventions-chest-drain-size'} /> {': '}<U><span>{t.chestDrainSizeRightF === -1 ? 'None': t.chestDrainSizeRightF}</span></U></p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <L t={'interventions-chest-drain-notes'} /> {': '}<U><span>{t.chestDrainNotes}</span></U>
                            </Grid>
                        </Grid>
                    </li>


                    <li>
                    <p><L t={'interventions-chest-seal'} /></p>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <p><L t={'interventions-left'} /></p>
                                <p><L t={'interventions-chest-seal-position'} /> {': '}<U><span>{t.chestSealPositionLeft}</span></U></p>
                                <p><L t={'interventions-chest-seal-size'} /> {': '}<U><span>{t.chestSealSizeLeftF === -1 ? 'None': t.chestSealSizeLeftF}</span></U></p>
                            </Grid>
                            <Grid item md={4}>
                                <p><L t={'interventions-right'} /></p>
                                <p><L t={'interventions-chest-seal-position'} /> {': '}<U><span>{t.chestSealPositionRight}</span></U></p>
                                <p><L t={'interventions-chest-seal-size'} /> {': '}<U><span>{t.chestSealSizeRightF === -1 ? 'None': t.chestSealSizeRightF}</span></U></p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <L t={'interventions-chest-seal-notes'} /> {': '}<U><span>{t.chestSealNotes}</span></U>
                            </Grid>
                        </Grid>
                    </li>


                    <li>
                    <p><L t={'interventions-ivio'} /></p>
                    <Grid container spacing={2} >
                        <Grid  item  md={5}>
                        <><L t={'interventions-ivio-peripheral-vein'} />&nbsp;<L t={'line 1'} /></>
                        <ul>
                            <li><L t={'interventions-ivio-peripheral-vein-side'} />{': '}<U><L t={t.ivVeinSide} /></U></li>
                            <li><L t={'interventions-ivio-peripheral-vein-area'} />{': '}<U><L t={t.ivVeinArea} /></U></li>
                            <li><L t={'interventions-ivio-peripheral-vein-size'} />{': '}<U><L t={t.ivVeinSize} /></U></li>
                            <li><L t={'interventions-ivio-peripheral-outcome'} />{': '}<U><L t={t.ivVeinOutcome !== '' ? 'interventions-'+t.ivVeinOutcome: ''} /></U></li>
                            <li><L t={'interventions-ivio-perihperal-time-removed'}/>{': '}<U><DateTimeRender value={t.ivVeinTime} format='evt'/></U></li>
                        </ul>

                        </Grid>
                        <Grid  item  md={5}>
                        <><L t={'interventions-ivio-peripheral-vein'} />&nbsp;<L t={'line 2'} /></>
                        <ul>
                            <li><L t={'interventions-ivio-peripheral-vein-side'} />{': '}<U><L t={t.ivVein2Side} /></U></li>
                            <li><L t={'interventions-ivio-peripheral-vein-area'} />{': '}<U><L t={t.ivVein2Area} /></U></li>
                            <li><L t={'interventions-ivio-peripheral-vein-size'} />{': '}<U><L t={t.ivVein2Size} /></U></li>
                            <li><L t={'interventions-ivio-peripheral-outcome'} />{': '}<U><L t={t.ivVein2Outcome !== '' ? 'interventions-'+t.ivVein2Outcome: ''} /></U></li>
                            <li><L t={'interventions-ivio-perihperal-time-removed'}/>{': '}<U><DateTimeRender value={t.ivVein2Time} format='evt'/></U></li>
                        </ul>

                        </Grid>


                        <Grid item  md={5}>
                        <><L t={'interventions-ivio-interosseous'} />&nbsp;<L t={'line 1'}/> </>
                        <ul>
                            <li><L t={'interventions-ivio-interosseous-vein-side'} />{': '}<U><L t={t.ioInterSide} /></U></li>
                            <li><L t={'interventions-ivio-interosseous-vein-area'} />{': '}<U><L t={t.ioInterArea} /></U></li>
                            <li><L t={'interventions-ivio-interosseous-outcome'} />{': '}<U><L t={t.ioInterOutcome !== '' ? 'interventions-'+t.ioInterOutcome: ''} /></U></li>
                            <li><L t={'interventions-ivio-interosseous-time-removed'}/>{': '}<U><DateTimeRender value={t.ioInterTime} format='evt'/></U></li>
                        </ul>

                        </Grid>
                        <Grid item  md={5}>
                        <><L t={'interventions-ivio-interosseous'} />&nbsp;<L t={'line 2'}/> </>
                        <ul>
                            <li><L t={'interventions-ivio-interosseous-vein-side'} />{': '}<U><L t={t.ioInter2Side} /></U></li>
                            <li><L t={'interventions-ivio-interosseous-vein-area'} />{': '}<U><L t={t.ioInter2Area} /></U></li>
                            <li><L t={'interventions-ivio-interosseous-outcome'} />{': '}<U><L t={t.ioInter2Outcome !== '' ? 'interventions-'+t.ioInter2Outcome: ''} /></U></li>
                            <li><L t={'interventions-ivio-interosseous-time-removed'}/>{': '}<U><DateTimeRender value={t.ioInter2Time} format='evt'/></U></li>
                        </ul>

                        </Grid>

                    </Grid>
                    </li>
                    <li>
                    <p><L t={'interventions-defib'} /></p>
                    <ul>
                            {(t.defibApplications||[])
                                .map(a=><li key={a._id} ><U><><DateTimeRender value={a.timestamp} format='evt'/>&nbsp;{a.joules}<L t={'joules'} /> </></U></li> ) }

                            <li><span><L t={'interventions-defib-rosc'} /></span>{': '}<U><><L t={t.defibRosc} />&nbsp;<DateTimeRender value={t.defibDate} format='evt' /></></U> </li>
                        </ul>

                    </li>
                    <li>
                    <p><L t={'interventions-cpr'} /></p>
                        <ul>
                            {(t.cprApplications||[]).sort((a,b)=> (a.started?.valueOf() ?? 0)  - (b.started?.valueOf() ?? 0) )
                                .map(a=><li key={a._id} ><U><><DateTimeRender value={a.started} format='evt'/>&nbsp;-&gt;&nbsp;<DateTimeRender value={a.stopped} format='evt' /></></U></li> ) }

                            <li><span><L t={'interventions-cpr-rosc'} /></span>{': '}<U><><L t={t.cprRosc} />&nbsp;<DateTimeRender value={t.cprDate} format='evt' /></></U> </li>
                        </ul>
                        
                    </li>
                    <li>
                    <p><L t={'interventions-eyeshield'} /></p>
                        <ul>
                        <li><L t={'interventions-eyeshield-side'} />{': '}<U><L t={t.eyeSide} /></U></li>
                        </ul>                        
                    </li>
                    <li>
                    <p><L t={'interventions-splint'} /></p>
                        <ul>
                        <li><L t={'interventions-splint-type'} />{': '}<U><>{(t.splintType||[]).map((c,i)=><><L key={`s${i}`} t={'interventions-splint-'+c}/> ,</>) } </></U></li>
                        </ul>                        
                    </li>
                </ul>
                )}
                </CardContent>
                </Card>);
};

