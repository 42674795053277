import React from "react";
import { useTranslation } from "react-i18next";
import "../translations/i18n";


const LocaleText = (pr:{t:string|undefined}) => {
    const {t} = useTranslation();
    
    const text= t((pr.t??''.replace('\n',' ')).toLowerCase()) || pr.t;

    return (<>{text}</>);
}


export default LocaleText;