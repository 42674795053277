import { Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";


export function MultiSelectionComponent(p: { availableItems: any, data: string[], itemsToHide?: any }) {
    const items: ReactNode[] = [];
    const a = p.availableItems;
    const ka = Object.keys(a);
    const kh = p.itemsToHide|| {};
    const d = p.data || [];
    const { t } = useTranslation();

    for (let k in a) {
        if (k.endsWith('Other')) continue;
        const text = t(a[k]) || a[k];
        const variant = (d.findIndex((v) => k === v) === -1 ? "outlined" : "filled")
        if (!kh[k]) {
            items.push(<Grid item> <Chip label={text} color="success" variant={variant} /></Grid>)
        }
    }
    const justOther = kh['Other'] == false

    if (!justOther) {
        if (ka.findIndex(k => k.endsWith('Other')) !== -1) {
            const dk = Object.values(d).filter(x => !ka.includes(x));
            for (var o in dk) {
                const text = dk[o];
                items.push(<Grid item> <Chip label={text} color="success" variant="filled" /></Grid>)
            }
        }
    } else {
        console.log(`d=${p.data}`)
        const text = t("Other") || "Other" ;
        const variant = Object.values(d).every(x=>ka.findIndex(k=>x == k) !== -1 ) && d.some(x=>x === "Other")  ? "filled" : "outlined"

        items.push(<Grid item> <Chip label={text} color="success" variant={variant} /></Grid>)
    }

    return (
        <Grid container spacing={2} flexWrap='wrap'>
            {items}
        </Grid>
    )
}
