import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Administration, InitialAssessment, Medications } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import DateTimeRender from "./DateTimeRenderer";

export function MedicationsComponent(p: { i: Medications, ia:InitialAssessment }) {

    const t = p.i;
    const ia = p.ia;

    let a = [...t.administrations]

    if ((ia.fentLollipops ?? -1) >= 0 && ia.fentLollipopsTime) {
       a = [...a,  {name: "OTFC", quantity:(ia.fentLollipops??0).valueOf(), date:ia.fentLollipopsTime,
        unit:"", userId:"", ivio:"", barcode:"", drugFrequency:"", _id:"fentlp", _partitionId:p.i._partitionId  } ] 
    }

    a.sort((a,b)=>a.date > b.date ? 1 : (a.date <= b.date ? -1 : (a.name > b.name ? 1 : -1 ))  )

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'medications-title'} /> </Typography>} />

            <CardContent>
                {t && (
                    <ul>
                        <li>
                            <L t={'medications-administered'} />
                            <MedicationsList m={a} />
                        </li>
                        <li>
                        <L t={'medications-pain-score'} />
                        {': '}<U><><span>{t.painScore === -1 ? 'None': t.painScore}</span>&nbsp;(<span><DateTimeRender value={t.painDate} format='evt' /> </span>)</></U>
                        </li>

                    </ul>)}
            </CardContent>
        </Card>);
}


function MedicationsList(p: { m?: Administration[] }) {
    return <ul >
        {(p.m ?? []).length ? p.m?.map((a: Administration, i:number) => <li key={`m${i}`}>
            <Typography component="span">{a.date.toLocaleString()}&nbsp;</Typography>
            <Typography component="span">{a.name}&nbsp;</Typography>
            <Typography component="span">{a.quantity}&nbsp;</Typography>
            <Typography component="span">{a.unit}&nbsp;</Typography>
            <Typography component="span">{a.ivio}&nbsp;</Typography></li>
        ) :
            (<li><Typography component="p"><L t="No medications recorded." /></Typography></li>)
        }
    </ul>
}

