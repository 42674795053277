export const TRANSLATIONS_EN = {
    "yes": "Yes",
    "no": "No",

    "name": "name",
    "gender": "gender",
    "zap": "zap",

    "dashboard-title": "Dashboard",
    "active cases": "Active Cases",
    "historic cases": "Historic Cases",

    "ample": "AMPLE",
    "atmist-title": "ATMIST",
    "allergies": "Allergies",
    "past medical history":"Past Medical History",
    "ample-medications-history": "Medication History",
    "no ample details recorded.": "No Ample details recorded.",
    "aspirin": "Aspirin",
    "carbamazepine": "Carbamazepine",
    "cephalosporin": "Cephalosporin",
    "codeine": "Codeine",
    "insulin": "Insulin",
    "latex": "Latex",
    "nsaid": "Nsaid",
    "penicillin": "Penicillin",
    "phenytoin": "Phenytoin",
    "sulphonamide": "Sulphonamide",
    "tetracycline" : "Tetracycline",
    "other": "Other",
    "none": "None",
    "unknown": "Unknown",
    "no medical history recorded.": "No Medical History recorded.",
    "last oral intake":"Last Oral Intake",
    "event": "Event",
    "hours": "Hours",
    "not known": "Not Known",

    "cancer": "Cancer",
    "cva": "CVA",
    "diabetes": "Diabetes",
    "epilepsy": "Epilepsy",
    "chronic heart condition": "Chronic Heart Condition",
    "hypertension": "Hypertension",
    "chronic lung condition": "Chronic Lung Condition",
    "mental illness": "Mental Illness",
    "physically disabled": "Physically Disabled",
    "pregnant": "Pregnant",
    "myocardial infarction": "Myocardial Infarction",


    "chooseLocale": "Choose Locale",
    "close": "Close",
    "uk": "United Kingdom",
    "unitedstates": "United States",
    "german": "Germany",
    "french": "French",
    "czech": "Czech",
    "spain": "Spain",

    "label-vitals-time": "Time",
    "label-vitals-vital-sign": "Measurement",
    "label-vitals-value": "Value",
    "vitals-label-o2history": "spO2",
    "vitals-label-systolicbp": "BP",
    "vitals-label-hrhistory": "Heart Rate",
    "vitals-label-skintemphistory": "Skin Temperature",
    "vitals-label-coretemphistory": "Core Temperature",
    "vitals-label-rrhistory": "Resp. Rate",
    "vitals-label-bghistory": "Blood Glucose",

    'timings-title': 'Timings',

    'timings-assigned': 'Assigned',
    'timings-atdestination': 'At Destination',
    'timings-atpatient': 'At Patient',
    'timings-cleared': 'Cleared',
    'timings-dispatched': 'Dispatched',
    'timings-incident': 'Incident',
    'timings-leavedestination': 'Leave Destination',
    'timings-atlocation': 'At Location',
    'timings-leavelocation': 'Leave Location',

    'incident-title': 'Incident',
    'incident-location-title': 'Incident Location',
    'incident-location-lat': 'Latitude',
    'incident-location-lng': 'Longitude',

    'incident-address': 'Address',
    'incident-details': 'Details',

    'incident-missiontype': 'Mission Type',
    'medical': 'Medical',
    'pregnancy': 'Pregnancy',
    'psychiatric': 'Psychiatric',
    'rescue': 'Rescue',
    'trauma': 'Trauma',
    //'other': 'Other',

    'incident-missiontype-notes': 'Mission Type Notes',

    'incident-mechanism': 'Mechanism',
    'chemical': 'Chemical',
    'biological': 'Biological',
    'radiological': 'Radiological',
    'nuclear': 'Nuclear',
    'blast': 'Blast',
    'aircraft': 'Aircraft',
    'assault': 'Assault',
    'collapsed-structure': 'Collapsed Structure',
    'fall': 'Fall',
    'fire': 'Fire',
    'gsw': 'GSW',
    'hazmat': 'Hazmat',
    'industrial': 'Industrial',
    'mtfa': 'MTFA',
    'mva': 'MVA',
    'natural-disaster': 'Natural Disaster',
    'train-metro': 'Train/Metro',
    'water': 'Water',

    'incident-mechanism-notes': 'Mechanism Notes',

    'incident-alert': 'Alert',
    'cardiac-arrest': 'Cardiac Arrest',
    //'cva': 'CVA',
    'respiratory-arrest': 'Respiratory Arrest',
    'sepsis': 'Sepsis',

    'incident-casualty-type': 'Casualty Type',
    'friendly': 'Friendly',
    'kilo-adult': 'Kilo-Adult',
    'kilo-child': 'Kilo-Child',
    'xray': 'X-Ray',

    'incident-evac-priority': 'Evacuation Priority',
    't1-immediate': 'T1-Immediate',
    't2-delayed': 'T2-Delayed',
    't3-minimal': 'T3-Minimal',
    't4-expectant': 'T4-Expectant',
    't5-dead': 'T5-Dead',

    'primary-survey-title': 'Primary Survey',

    'ps-cat-hem-title': 'Catastrophic Haemorrhage',
    'ps-observation': 'Observation',
    'normal': 'Normal',
    'abnormal': 'Abnormal',

    'ps-site-title': 'Site',
    'ps-loss-title': 'Loss',
    'head': 'Head',
    'neck': 'Neck',
    'chest': 'Chest',
    'upper limb': 'Upper Limb',
    'lower limb': 'Lower Limb',
    'abdomen': 'Abdomen',

    'mild': 'Mild',
    'moderate': 'Moderate',
    'severe': 'Severe',

    'ps-cer-spin-title': 'Cervical Spine',

    'ps-abnormality-title': 'Abnormality Present',
    'deformity': 'Deformity',
    'neurological deficit': 'Neurological Deficit',
    'tenderness': 'Tenderness',
    'pain': 'Pain',

    'ps-airway-title': 'Airway',
    'ps-airway-severity': 'Severity',
    'complete obstruction': 'Complete Obstruction',
    'partial obstruction': 'Partial Obstruction',

    'ps-airway-cause': 'Cause',
    'blood': 'Blood',
    'burn': 'Burn',
    'food': 'Food',
    'foreign body': 'Foreign Body',
    'tongue': ' Tongue',
    'tracheostomy': 'Tracheostomy',
    'vomit': 'Vomit',

    'ps-breathing-title': 'Breathing',
    'ps-breathing-rate': 'Rate',
    'ps-breathing-type': 'Type',

    'fast': 'Fast',
    'slow': 'Slow',
    'irregular': 'Irregular',
    'noisy': 'Noisy',
    'shallow': 'Shallow',
    'unequal movements': 'Unequal Movements',

    'ps-circulation-title': 'Circulation',
    'ps-circulation-pulse': 'Pulse',
    'ps-circulation-complexion': 'Complexion',
    'ps-circulation-temperature': 'Temperature',
    'hot': 'Hot',
    'cold': 'Cold',
    'radial pulse absent': 'Radial Pulse Absent',
    'brachial pulse absent': 'Brachial pulse Absent',
    'thready': 'Thready',
    'carotid pulse absent': 'Carotid Pulse Absent',
    'cyanosed': 'Cyanosed',
    //'hot': 'Hot',
    //'cold': 'Cold',

    'ps-disability-title': 'Disability',
    'ps-disabilty-gcs-eyes': 'GCS Eyes',
    'eyes open to pain': 'Eyes open to pain',
    'does not open eyes': 'Does not open eyes',
    'eyes open immediately': 'Eyes open immediately',
    'eyes open to speech': 'Eyes open to speech',

    'ps-disabilty-gcs-verbal': 'GCS Best Verbal Response',

    'alert and orientated': 'Alert & orientated',
    'appropriate coo & cry': 'Appropriate Coo & Cry',
    'confused or disorientated': 'Confused or disorientated',
    'irritable cry' : 'Irritable Cry',
    'inappropriate words': 'Inappropriate words',
    'inconsolable crying' : 'Inconsolable Crying',
    'unintelligable sounds': 'Unintelligable sounds',
    'grunts': 'Grunts',
    'no sounds': 'No sounds',

    'ps-disabilty-gcs-motor': 'GCS Best Motor Response',
    'obeys command': 'Obeys command',
    'normal spontaneous': 'Normal Spontaneous',
    'localises pain': 'Localises pain',
    'withdrawal from pain': 'Withdrawal from pain',
    'withdraws to touch': 'Withdraws to touch',
    'abnormal flexion': 'Abnormal flexion',
    'extension response': 'Extension response',
    'no response': 'No response',

    'ps-disabilty-gcs-score': 'GCS Score',

    'ps-pupils-right': 'Pupils Right',
    'ps-pupils-left': 'Pupils Left',
    'disability-eyes-':'',
    'disability-eyes-constricted': 'Constricted',
    'disability-eyes-dilated': 'Dilated',
    'disability-eyes-false': 'False',
    'disability-eyes-fixed': 'Fixed',
    'disability-eyes-fixed & dilated': 'Fixed & Dilated',
    'disability-eyes-normal': 'Normal',

    'ps-fast-facial': 'FAST Facial Weakness',
    'ps-fast-arm': 'FAST Arm Weakness',
    'ps-fast-leg': 'FAST Leg Weakness',
    'ps-fast-speech': 'FAST Speech Abnormality',
    'right': 'Right',
    'left': 'Left',
    'both': 'Both',

    'ps-pupils-right-size': 'Size',
    'ps-pupils-left-size': 'Size',

    'ps-examination': 'Examination',

    'ps-sepsis': 'Sepsis',
    'ps-sepsis-type': 'Sepsis Type',
    'ps-sepsis-patient-look-sick': 'Does the patient look very sick?',
    'ps-sepsis-observations-abnormal': 'Are any observations abnormal?',
    'ps-sepsis-meows-triggering': 'Is MEOWS triggering?',
    'ps-sepsis-news-3-or-above': 'Is the NEWS 3 or above?',
    'ps-sepsis-history-infection': 'Is the History Suggestive of infection?',
    'ps-sepsis-any-red-flags': 'Is any ONE Red Flag present?',
    'ps-sepsis-any-amber-flags': 'Is any ONE Amber Flag Present?',
    'ps-sepsis-child-observations-abnormal':'Are Observations abnormal?',
    'ps-sepsis-child-feverish':'is the child feverish or looking sick?',
    'ps-sepsis-child-parent-worried':'Is the patient/carer very worried?',
    'yes, but the source not obvious': 'Yes, but the source not obvious',
    'yes, but source not obvious': 'Yes, but the source not obvious',
    'infected caesarean or perineal wound': 'Infected caesarean or perineal wound',
    'urinary tract infection': 'Urinary tract infection',
    'chorioamnionitis/endometritis': 'Chorioamnionitis/Endometritis',
    'influenza, severe sore throat or pneumonia': 'Influenza, severe sore throat or pneumonia',
    'abdominal pain or distension': 'Abdominal pain or distension',
    'breast abscess/mastitis': 'Breast abscess/mastitis',
    'pneumonia/likely chest source': 'Pneumonia/likely chest source',
    'meningitis/encephalitis': 'Meningitis/Encephalitis',
    'cellulitis/septic arthritis/infected wound': 'Cellulitis/septic arthritis/infected wound',
    'device-related infection': 'Device-related infection',
    'meningitis': 'Meningitis',
    'heart rate > 130 per minute': 'Heart rate > 130 per minute',
    'systolic bp <= 90 mmhg (or drop >40 from normal)': 'Systolic bp <= 90 mmhg (or drop >40 from normal)',
    'respiratory rate >= 25 per minute': 'Respiratory rate >= 25 per minute',
    'spo2 >= 92%% when on oxygen': 'Spo2 >= 92% when on oxygen',
    'lactate >= 2mmol/l': 'Lactate >= 2mmol/l',
    'responds only to voice or pain/unresponsive': 'Responds only to voice or pain/unresponsive',
    'non-blanching rash, mottled/ashen/cyanotic': 'Non-blanching rash, mottled/ashen/cyanotic',
    'not passed urine in last 18 hours': 'Not passed urine in last 18 hours',
    'urine output < 0.5 ml/kg/hr': 'Urine output < 0.5 ml/kg/hr',
    'recent chemotherapy': 'Recent chemotherapy',
    'under_5': 'Under 5',
    'adult': 'Adult',
    'abdominal pain, drawing legs up or distension': 'Abdominal pain, drawing legs up or distension',
    'contact risk of bacteraemia (eg group b strep)': 'Contact risk of bacteraemia (eg group b strep)',
    'heart rate (see chart)': 'Heart rate',
    'respiratory rate (see chart)': 'Respiratory rate',
    'spo2 < 90% on air': 'Spo2 < 90% on air',
    'temperature <36℃': 'Temperature <36℃',
    'if under 3 months, temperature >38℃': 'If under 3 months, temperature >38℃',
    'non-blanching rash, mottled, ashen or blue skin': 'Non-blanching rash, mottled, ashen or blue skin',
    'no wet nappies/not passed urine in last 18 hours': 'No wet nappies/not passed urine in last 18 hours',
    'no response to social cues/obviously sick': 'No response to social cues/obviously sick',
    'does not wake if roused or will not stay awake': 'Does not wake if roused or will not stay awake',
    'weak, high pitched or continuous cry': 'Weak, high pitched or continuous cry',
    'grunting with every breath or pauses in breathing': 'Grunting with every breath or pauses in breathing',
    'looks very ill to health professional': 'Looks very ill to health professional',
    'capillary refill > 3 secs':'Capillary Refill > 3 secs',
    'spo2 91%% in air':'SPO2 91% in air',
    'responding abnormally to social cues, not smiling':'Responding abnormally to social media cues, not smiling' ,
    'wakes only with prolonged stimulation': 'Wakes only with prolonged stimulation',
    'significantly decreased activity':'Significantly decreased activity',
    'nasal flaring':'Nasal Flaring',
    'poor feeding in infants':'Poor feeding in Infants',
    'reduced urine output':'Reduced Urine Output',
    'leg pain':'Leg pain',
    'cold feet or hands, pale or flushed':'Cold feet or hands, pale or flushed',
    'respiratory rate (see table)':'Respiratory rate',
    'heart rate (see table)':'Heart rate',
    'systolic b.p. <= 90 mmhg':'Systolic B.P. <= 90 mmhg',
    'spo2 >=92%% when on oxygen':'SPO2 >=92% when on Oxygen',
    'lactate >=2 mmol/l':'Lactate >=2 mmol/l',
    'invasive procedure in last 6 weeks (cs, forceps, erpc, cerciage, cvs, miscarriage, termination)':'Invasive procedure in last 6 weeks (cs, forceps, erpc, cerciage, cvs, miscarriage, termination)',
    'systolic bp 91–100 mmhg':'Systolic BP 91–100 mmHg',
    'respiratory rate 21–24':'Respiratory Rate 21–24',
    'heart rate 100–129 (91–129 post partum)':'Heart rate 100–129 (91–129 post partum)',
    'relatives concerned about mental status':'Relatives concerned about mental status',
    'acute deterioration in functional ability':'Acute deterioration in functional ability',
    'immunosuppressed/diabetes/gestational diabetes':'Immunosuppressed/Diabetes/Gestational Diabetes',
    'not passed urine in last 12–18 hours':'Not passed urine in last 12–18 hours',
    'prolonged rupture of membranes':'Prolonged rupture of membranes',
    'close contact with gas':'Close contact with gas',


    'injuries-title': 'Injuries',

    'injuries-head-title': 'Head',
    'injuries-neck-title': 'Neck',
    'injuries-chest-title': 'Chest',
    'injuries-abdomen-title': 'Abdomen',
    'injuries-right-arm-title': 'Right Arm',
    'injuries-right-leg-title': 'Right Leg',
    'injuries-left-arm-title': 'Left Arm',
    'injuries-left-leg-title': 'Left Leg',

    'injuries-burn': 'Burn',
    'injuries-fracture': 'Fracture',
    'injuries-wound': 'Wound',
    'injuries-burn-score-title': 'Total Burn Score',
    'injuries-notes-title': 'Notes',


    'treatment-title': 'Treatments',

    'treatment-head-title': 'Head',
    'treatment-face-title': 'Face',
    'treatment-neck-title': 'Neck',

    'treatment-burn': 'Burn',
    'treatment-light': 'Light',
    'treatment-pressure': 'Pressure',
    'treatment-hemostatic': 'Hemostatic',
    'treatment-none': 'None',

    'treatment-head-chest': 'Chest',
    'treatment-head-chest-intervention': 'Chest Intervention',
    'treatment-head-abdomen': 'Abdomen',

    'treatment-chest-drain': 'Drain',
    'treatment-chest-seal': 'Seal',
    'treatment-chest-needle-d': 'Needle-D',

    'treatment-right-arm': 'Right Arm',
    'treatment-left-arm': 'Left Arm',
    'treatment-right-leg': 'Right Leg',
    'treatment-left-leg': 'Left Leg',

    'treatment-tourniquet-history-right-arm-dist': 'Right Arm Dist.',
    'treatment-tourniquet-history-right-arm-junc': 'Right Arm Junc.',
    'treatment-tourniquet-history-left-arm-dist': 'Left Arm Dist.',
    'treatment-tourniquet-history-left-arm-junc': 'Left Arm Junc.',

    'treatment-tourniquet-history-right-leg-dist': 'Right Leg Dist.',
    'treatment-tourniquet-history-right-leg-junc': 'Right Leg Junc.',
    'treatment-tourniquet-history-left-leg-dist': 'Left Leg Dist.',
    'treatment-tourniquet-history-left-leg-junc': 'Left Leg Junc.',


    'treatment-tourniquet-history-on': 'On',
    'treatment-tourniquet-history-off': 'Off',

    'treatment-notes': 'Notes',

    'interventions-title': 'Interventions',
    'interventions-cric': "Cric",
    'interventions-achieved': "Achieved",
    "interventions-not achieved": "Not Achieved",

    'interventions-et-tube': "ET Tube",
    'interventions-et-tube-size': "Size",
    'interventions-et-tube-outcome': "Outcome",
    'interventions-et-tube-suction': "Suction",
    'interventions-et-tube-confirmed': "Confirmed",
    'interventions-ausculation': "Ausculation",
    'interventions-visualisation': "Visualisation",
    'interventions-capnometer': "Capnometer",
    'interventions-npa-outcome': "Outcome",

    'interventions-npa': "NPA",
    'interventions-npa-size': 'Size',
    //'interventions-npa-outcome': "Outcome",
    'interventions-': "",

    'interventions-o2': "O2",
    'interventions-o2-flow': "Flow Rate",
    'interventions-o2-mask': "Type of Mask",

    'interventions-mask-28% mask': "28% Mask",
    'interventions-mask-100% nrm': "100% NRM",
    'interventions-mask-bvm': "BVM",
    'interventions-mask-nasal cannula': "Nasal Cannula",
    'interventions-mask-nebuliser': "Nebuliser",
    'interventions-mask-ventilator': "Ventilator",

    'interventions-sga': "SGA",
    'interventions-sga-size': "Size",
    'interventions-sga-outcome': "Outcome",
    'interventions-sga-suction': "Suction",
    'interventions-sga-confirmed': "Confirmed",

    'interventions-ivio': "IV/IO",
    'interventions-ivio-peripheral-vein': 'Peripheral Vein',
    'interventions-ivio-interosseous': "Interosseous",
    'interventions-ivio-peripheral-vein-line': "Line",
    'interventions-ivio-peripheral-vein-side': "Side",
    'interventions-ivio-peripheral-vein-area': "Area",
    'interventions-ivio-peripheral-vein-size': "Size",
    'interventions-ivio-peripheral-outcome': "Outcome",
    'line 1': "Line 1", 'line 2': "Line 2",
    'acf': "ACF", 'foot': "Foot", 'forearm': "Forearm", 'hand': "Hand", 'jugular vein': "Jugular Vein", 'wrist': "Wrist",
    'interventions-ivio-perihperal-time-removed': "Time Removed",

    'interventions-ivio-interosseous-vein-side': "Side",
    'interventions-ivio-interosseous-vein-area': "Area",
    'interventions-ivio-interosseous-outcome': "Outcome",
    'upper arm': "Upper Arm", 'lower leg': "Lower Leg",
    'interventions-ivio-interosseous-time-removed': "Time Removed",

    'joules': "Joules",
    'interventions-defib': "Defibrillator",
    'interventions-defib-rosc': "ROSC",
    'interventions-cpr': "CPR",
    'interventions-cpr-rosc': "ROSC",

    'interventions-eyeshield': "Eye Shield",
    'interventions-eyeshield-side': "Side",

    'interventions-splint': "Splint",
    'interventions-splint-type': "Type",
    'interventions-splint-': "",
    'interventions-splint-box': "Box",
    'interventions-splint-cervical': "Cervical",
    'interventions-splint-frac strap': "Frac Strap",
    'interventions-splint-head immobilisation': "Head Immobilisation",
    'interventions-splint-longboard': "Longboard",
    'interventions-splint-pelvic belt': "Pelvic Belt",
    'interventions-splint-sagar': "Sagar",
    'interventions-splint-scoop': "Scoop",
    'interventions-splint-ked': "Ked",
    'interventions-splint-vacuum mattress': "Vacuum Mattress",
    'interventions-splint-vacuum splint': "Vacuum Splint",

    'medications-title': "Medications",
    'medications-administered': "Administered",
    'medications-pain-score': "Pain Score",

    'care-plan-title': "Care Plan",

    'disposition-title': "Disposition",
    'disposition-destination-title': "Destination",
    'disposition-destinationtype': "Destination Type",
    'disposition-destinationtype-casualty clearing station': "Casualty Clearing Station",
    'disposition-destinationtype-deployed hospital care foward': "Deployed Hospital Care Forward",
    'disposition-destinationtype-deployed hospital care rear': "Deployed Hospital Care Rear",
    'disposition-destinationtype-role 4': "Role 4",
    'disposition-destinationname': "Destination Name",

    'disposition-outcome-condition': "Overall Condition",
    'disposition-outcomecondition-unchanged': "Unchanged",
    'disposition-outcomecondition-better': "Better",
    'disposition-outcomecondition-worse': "Worse",
    'disposition-outcomecondition-deceased': "Deceased",

    'dispostition-outcome': "Outcome",
    'disposition-outcome-concious': "Concious",
    'disposition-outcome-rosc': "ROSC",
    'disposition-outcome-respiration': "Respiration",
    'disposition-outcome-pain': "Pain",

    'disposition-outcome-pain-unchanged': "Unchanged",
    'disposition-outcome-pain-better': "Better",
    'disposition-outcome-pain-worse': "Worse",

    'disposition-receiving': "Receiving",
    'disposition-receiving-role': "Role",
    'disposition-receiving-name': "Name",

    'medevac-title': "9 Line Medevac",
    'medevac-lzlocation': "LZ Location",
    'medevac-lz-latitude': "Latitude",
    'medevac-lz-longitude': "Longitude",
    'medevac-callsign': "Call Sign",
    'medevac-callsign-radiofrequency': "Radio Frequency",
    'medevac-callsign-callsign': "Call Sign",
    'medevac-callsign-suffix': "Suffix",

    'medevac-casualties-precedence': "Number of Casualties by Precedence",
    'medevac-casualties-precedence-urgent': "A - Urgent",
    'medevac-casualties-precedence-urgent-surgical': "B - Urgent Surgical",
    'medevac-casualties-precedence-priority': "C - Priority",
    'medevac-casualties-precedence-routine': "D - Routine",
    'medevac-casualties-precedence-convenience': "E - Convenience",

    'medevac-special-equipment-none': "A - None",
    'medevac-special-equipment-hoist': "B - Hoist",
    'medevac-special-equipment-extraction': "C - Extraction Equipment",
    'medevac-special-equipment-ventilator': "D - Ventilator",
    'medevac-special-equipment-other': "E - Other",

    'medevac-special-equipment-title': "Special Equipment Required",
    'medevac-special-equipment': "Required",

    'medevac-casualties-type': "Number of Casualties by Type",
    'medevac-casualties-type-litter': "A - Litter",
    'medevac-casualties-type-ambulatory': "B - Ambulatory",
    'medevac-casualties-type-escort': "C - Escort",

    'medevac-security-title': "Security at LZ/Pick Up Site",
    'medevac-security': "Security",

    'medevac-security-n': "N - No Enemy Troops In Area",
    'medevac-security-p': "P - Approach wtih Caution",
    'medevac-security-e': "E - Enemy Troops In Area",
    'medevac-security-x': "X - Armed Escort Required",

    'medevac-markings-title': "Marking at LZ/Pick Up Site",
    'medevac-markings': "Markings",

    'medevac-markings-a': "A - Panels",
    'medevac-markings-b': "B - Pyrotechnic",
    'medevac-markings-c': "C - Smoke",
    'medevac-markings-d': "D - None",
    'medevac-markings-e': "E - Other",

    'medevac-nationality-status-title': "Casualty Nationality and Status",
    'medevac-nationality-status': "Status",
    'medevac-nationality-a': "A - Coalition Military",
    'medevac-nationality-b': "B - Coalition Civilian",
    'medevac-nationality-c': "C - Non-Coalition Forces",
    'medevac-nationality-d': "D - Non-Coalition Civilian",
    'medevac-nationality-e': "E - Opposing Forces Detainees",
    'medevac-nationality-f': "F - Child",

    'medevac-zone-terrain-obstacles-title': "Zone Terrain Obstacles",

    'disposition-pmrone-title': "PMR One",
    'disposition-pmrone-dtg': "1 - PMR1 DTG",
    'disposition-pmrone-emplane-title': "2 - Emplane Details",
    'disposition-pmrone-emplane-origin': "Origin",
    'disposition-pmrone-emplane-destination': "Destination",
    'disposition-pmrone-priority': "3 - Priority",
    'disposition-pmrone-priority-1': "Priority 1",
    'disposition-pmrone-priority-2': "Priority 2",
    'disposition-pmrone-priority-3': "Priority 3",
    'disposition-pmrone-patient-details-servicenumber': "Service Number",
    'disposition-pmrone-patient-details-rank': "Rank",
    'disposition-pmrone-patient-details-name': "Full Name",
    'disposition-pmrone-patient-details-dob': "Date of Birth",
    'disposition-pmrone-patient-details': "4 - Patient Details",

    'disposition-pmrone-patient-unit': "5 - Patient Unit",
    'disposition-pmrone-patient-unit-deployed': "Deployed Unit",
    'disposition-pmrone-patient-unit-home': 'Home Unit',

    'disposition-pmrone-passport-details': '6 - Passport Details',
    'disposition-pmrone-passport-details-number': 'Passport Number',
    'disposition-pmrone-passport-details-issued': 'Issued',
    'disposition-pmrone-passport-details-expiry': 'Expiry',

    'disposition-pmrone-classification': "7 - Classification",

    'disposition-pmrone-classification-1a': '1a - Severe Psychiatric',
    'disposition-pmrone-classification-1b': '1b - Intermediate Psychiatric',
    'disposition-pmrone-classification-1c': '1c - Mildly Psychiatric - Escorted',
    'disposition-pmrone-classification-1c-unescorted': '1c - Mildly Psychiatric - Unescorted',
    'disposition-pmrone-classification-2a': '2a - Stretcher - Immobile - Escorted',
    'disposition-pmrone-classification-2b': '2b - Stretcher - Mobile - Escorted',
    'disposition-pmrone-classification-3a': '3a - Sitting - Immobile - Escorted',
    'disposition-pmrone-classification-3b': '3b - Sitting - Mobile - Escorted',
    'disposition-pmrone-classification-4': '4 - Walking - Unescorted',
    'disposition-pmrone-classification-5': '5 - Outpatient - Unescorted',
    'disposition-pmrone-classification-6': '6 - Civilian',

    'disposition-pmrone-current-location-details': "8 - Current Location Details",
    'disposition-pmrone-current-location-details-patient': "Location of Patient",
    'disposition-pmrone-current-location-details-treating-hospital': 'Treating Hospital',
    'disposition-pmrone-current-location-details-mo-ic': "MO IC",
    'disposition-pmrone-current-location-details-hospital-contact-number': "Contact Number Hospital",
    'disposition-pmrone-current-location-details-mo-contact': "Contact Number MO/MA on Board",
    'disposition-pmrone-current-location-details-patient-contact': "Contact Number Patient",

    'disposition-pmrone-cabin-altitude-restriction': "9 - Cabin Altitude Restriction",

    'disposition-pmrone-altitude-restriction-altitude': "Altitude",

    'unit-feet': "Feet",
    'unit-metres': "Metres",

    'disposition-pmrone-accompanying-relatives': "10 - Accompanying Relatives",
    'disposition-pmrone-accompanying-relatives-count': 'Number',

    'disposition-pmrone-dietary': '11 - Special Dietary Requirements',
    'disposition-pmrone-dietary-none': 'None',
    'disposition-pmrone-dietary-gluten-free': 'Gluten Free',
    'disposition-pmrone-dietary-halal': 'Halal',
    'disposition-pmrone-dietary-kosher': 'Kosher',
    'disposition-pmrone-dietary-vegan': 'Vegan',
    'disposition-pmrone-dietary-vegetarian': 'Vegetarian',

    'disposition-pmrone-patient-disposal': '12 - Patient Disposal',

    'disposition-pmrone-transport-arrangements': "13 - Transport Arrangements",

    'disposition-pmrone-patient-listing': '14 - Patient Listing',
    'disposition-pmrone-transport-aecc': 'Designated by AECC',
    'disposition-pmrone-transport-nhs': 'NHS',
    'disposition-pmrone-parent-unit': 'Parent Unit',
    'disposition-pmrone-transport-private': 'Private',
    'disposition-pmrone-transport-raf-northolt': 'RAF Northolt',
    'disposition-pmrone-transport-brize-norton': 'TACMEDWG Brize Norton',

    'disposition-pmrone-mes': "15 - MES & Date Award",
    'disposition-pmrone-mes-date': "Date awarded",


    'disposition-pmrone-mbi-bi-disease': "16 - MBI/BI/Disease",

    'disposition-pmrone-signature': "17 - Signature",
    'disposition-pmrone-signature-rank': "Rank",
    'disposition-pmrone-signature-name': "First & Second Name",
    'disposition-pmrone-signature-sig': "Signature",

    'disposition-pmrtwo-title': 'PMR2 OFFICIAL SENSITIVE PERSONAL - MEDICAL IN CONFIDENCE',
    'disposition-pmrtwo-dtg': '1 - PMR2 DTG',
    'disposition-pmrtwo-pmrone': '2 - Connect with PMR1',
    'disposition-pmrtwo-classification-and-dependency': '3 - Classification and Dependency',
    'disposition-pmrtwo-classification': 'Classification',

    'disposition-pmrtwo-severe-psychiatric': '1A - Severe Psychiatric',
    'disposition-pmrtwo-intermediate-psychiatric': '1B - Intermediate Psychiatric',
    'disposition-pmrtwo-mildly-psychiatric-escorted': '1C - Mildly Phsyciatric - Escorted',
    'disposition-pmrtwo-mildly-psychiatric-unescorted': '1C - Mildly Phsyciatric - Unescorted',
    'disposition-pmrtwo-stretcher-immobile': '2A - Stretcher - Immobile - Escorted ',
    'disposition-pmrtwo-stretcher-mobile': '2B - Stretcher - Mobile - Escorted',
    'disposition-pmrtwo-sitting-immobile': '3A - Sitting - Immobile - Escorted',
    'disposition-pmrtwo-sitting-mobile': '3B - Sitting - Mobile - Escorted',
    'disposition-pmrtwo-walking': '4 - Walking - Unescorted',
    'disposition-pmrtwo-outpatient': '5 - Outpatient - Unescorted',
    'disposition-pmrtwo-civilian': '6 - Civilian',

    'disposition-pmrtwo-dependency': 'Dependency',
    'disposition-pmrtwo-dependency-high': '1 - High',
    'disposition-pmrtwo-dependency-medium': '2 - Medium',
    'disposition-pmrtwo-dependency-low': '3 - Low',
    'disposition-pmrtwo-dependency-minimal': '4 - Minimal',

    'disposition-pmrtwo-full-clinical-details': '4 - Full Clinical Details',
    'disposition-pmrtwo-atmist': 'ATMIST',
    'disposition-pmrtwo-investigations': 'Investigations Radiology, Blood Tests',
    'disposition-pmrtwo-vte-risk': 'VTE Risk',
    'disposition-pmrtwo-flight-considerations': '5 -Consideration for Flight Details',
    'disposition-pmrtwo-flight-fully-mobile': 'A - Fully Mobile?',
    'disposition-pmrtwo-flight-carry-bag': 'B - Patient can carry own bag?',
    'disposition-pmrtwo-flight-escort': 'C - Escort',
    'disposition-pmrtwo-flight-wheelchair': 'D - Does patient require a wheelchair?',
    'disposition-pmrtwo-infection-details': '6 - Infection Risk Details',
    'disposition-pmrtwo-infection-fever': 'A - Fever',
    'disposition-pmrtwo-infection-diarrhoea': 'B - Diarrhoea',
    'disposition-pmrtwo-infection-vomiting': 'Vomiting',
    'disposition-pmrtwo-infection-rash': 'D - Rash',
    'disposition-pmrtwo-infection-cough': 'E - Cough',
    'disposition-pmrtwo-infection-risk': ' F - Infection Risk',
    'disposition-pmrtwo-infection-mrsa': 'G - MRSA',
    'positive': 'Positive',
    'negative': 'Negative',
    'disposition-pmrtwo-dah-details': '7 - DAH Details',
    'disposition-pmrtwo-disposition-rcdm': 'RCDM',
    'disposition-pmrtwo-disposition-isp-psychiatry': 'ISP Psychiatry',
    'disposition-pmrtwo-dispostion-headley-court': 'Headley Court',
    'disposition-pmrtwo-disposition-nhs-unit': 'NHS Unit',
    'dispostion-pmrtwo-disposition-name': 'Name',
    'dispostion-pmrtwo-disposition-location': 'Location',
    'disposition-pmrtwo-medical-escort-details': '8 - Medical Escort Required Details',
    'disposition-pmrtwo-type': 'Type',
    'disposition-pmrtwo-location': 'Location',
    'disposition-pmrtwo-treating': 'Treating',
    'disposition-pmrtwo-mo-ic': 'MO IC',
    'disposition-pmrtwo-contact-hospital-no': 'Contact Number Hospital',
    'disposition-pmrtwo-contact-mo-no': 'Contact Number MO',
    'disposition-pmrtwo-contact-patient-no': 'Contact Number Patient',
    'disposition-pmrtwo-signature': '9 - Signature',
    'disposition-pmrtwo-signature-rank': 'Rank',
    'disposition-pmrtwo-signature-name': 'First & Second Name',
    'disposition-pmrtwo-signature-sig': 'Signature',

    'disposition-ccast-title': 'CCAST',
    'disposition-ccast-ventilation': '1 - Ventilation Settings',
    'disposition-ccast-ventilation-volume': 'Tidal Volume',
    'disposition-ccast-ventilation-rate': 'Rate',
    'disposition-ccast-ventilation-peep': 'PEEP',
    'disposition-ccast-ventilation-pip': 'PIP',

    'disposition-ccast-observations': '2 - Observations',
    'disposition-ccast-observations-pulse': 'Pulse',
    'disposition-ccast-observations-bp': 'Blood Pressure',
    'disposition-ccast-observations-capr': 'Capillary Refill > 2 seconds?',
    'disposition-ccast-observations-coretemp': 'Core Temperature',
    'disposition-ccast-observations-spo2': 'SPO2',
    'disposition-ccast-observations-fio2': 'FIO2',
    'disposition-ccast-observations-etco2': 'ETCO2',

    'disposition-ccast-venous-arterial-lines': '3 - Venous/Arterial Lines',
    'disposition-ccast-venous-arterial-lines-arterial': 'Arterial',
    'disposition-ccast-venous-arterial-lines-cvp': 'CVP',
    'disposition-ccast-venous-arterial-lines-venous': 'Venous',
    'disposition-ccast-venous-arterial-lines-side': ' Side',
    'disposition-ccast-venous-arterial-lines-area': 'Area',
    'disposition-ccast-venous-arterial-lines-area-acf': 'ACF',
    'disposition-ccast-venous-arterial-lines-area-foot': 'Foot',
    'disposition-ccast-venous-arterial-lines-area-forearm': 'Forearm',
    'disposition-ccast-venous-arterial-lines-area-hand': 'Hand',
    'disposition-ccast-venous-arterial-lines-area-jugular': 'Jugular Vein',
    'disposition-ccast-venous-arterial-lines-area-wrist': 'Wrist',
    'disposition-ccast-venous-arterial-lines-area-lowerleg': 'Lower Leg',
    'disposition-ccast-venous-arterial-lines-area-upperarm': 'Upper Arm',

    'disposition-ccast-venous-arterial-lines-arterial-size': 'Size',
    'disposition-ccast-venous-arterial-lines-line': 'Line',

    'disposition-ccast-venous-arterial-lines-size': 'Size',
    'disposition-ccast-venous-arterial-lines-size-14g': '14G',
    'disposition-ccast-venous-arterial-lines-size-16g': '16G',
    'disposition-ccast-venous-arterial-lines-size-18g': '18G',
    'disposition-ccast-venous-arterial-lines-size-20g': '20G',
    'disposition-ccast-venous-arterial-lines-size-22g': '22G',
    'disposition-ccast-venous-arterial-lines-size-24g': '24G',

    'disposition-ccast-drains': '4 - Drains & Tubes',
    'disposition-ccast-drains-urinary-catheter-size': 'Urinary Catheter Size',
    'disposition-ccast-drains-nasogastric-size': 'Nesogastric Size',
    'disposition-ccast-drains-abdomnial-type': 'Abdominal Drain Type',
    'disposition-ccast-drains-abdomnial-size': 'Abdominal Drain Size',

    'disposition-ccast-drains-chest-side': 'Chest Drain',
    'disposition-ccast-drains-chest-size': 'Size',
    'disposition-ccast-drains-other': 'Other',

    'disposition-ccast-blood-group': '5 - Blood Group',
    'disposition-ccast-blood-group-group': 'Group',
    'disposition-ccast-blood-group-a': 'A',
    'disposition-ccast-blood-group-b': 'B',
    'disposition-ccast-blood-group-ab': 'AB',
    'disposition-ccast-blood-group-o': 'O',

    'disposition-ccast-blood-group-rhd': 'RHD',
    'disposition-ccast-blood-rhd-positive': 'RHD +ve',
    'disposition-ccast-blood-rhd-negative': 'RHD -ve',

    'disposition-ccast-infusions': '6 - Infusions',
    'disposition-ccast-infusions-sedation': 'Sedation',
    'disposition-ccast-infusions-medazolam': 'Midazolam',
    'disposition-ccast-infusions-rate': 'Rate',
    'disposition-ccast-infusions-conc': 'Concentration',
    'disposition-ccast-infusions-morphine': 'Morphine',

    'disposition-ccast-infusions-propofol': 'Propofol',
    'disposition-ccast-infusions-inotropes': 'Inotropes',
    'disposition-ccast-infusions-noradrenaline': 'Noradrenaline',
    'disposition-ccast-infusions-adrenaline': 'Adrenaline',

    'disposition-ccast-blood-results': '7 - Blood Results ',
    'disposition-ccast-blood-results-haemotology': 'Haemotology',
    'disposition-ccast-blood-results-haemotology-hb': 'HB',
    'disposition-ccast-blood-results-haemotology-plt': 'PLT',
    'disposition-ccast-blood-results-haemotology-inr': 'INR',
    'disposition-ccast-blood-results-haemotology-pt': 'PT',
    'disposition-ccast-blood-results-haemotology-aptt': 'APTT',
    'disposition-ccast-blood-results-haemotology-fib': 'FIB',
    'disposition-ccast-blood-results-haemotology-hct': 'HCT',
    'disposition-ccast-blood-results-haemotology-wcc':'WCC',

    'disposition-ccast-blood-results-biochemistry': 'Biochemistry',
    'disposition-ccast-blood-results-biochemistry-sodium': 'Sodium',
    'disposition-ccast-blood-results-biochemistry-potassium': 'Potassium',
    'disposition-ccast-blood-results-biochemistry-creatinine': 'Creatinine',
    'disposition-ccast-blood-results-biochemistry-urea': 'Urea',
    'disposition-ccast-blood-results-biochemistry-glucose': 'Glucose',
    'disposition-ccast-blood-results-biochemistry-calcium': 'Calcium',
    'disposition-ccast-blood-results-biochemistry-phosphate': 'Phosphate',
    'disposition-ccast-blood-results-biochemistry-magnesium': 'Magnesium',

    'disposition-ccast-blood-results-microbiology': 'Microbiology',
    'disposition-ccast-blood-results-microbiology-site': 'Site',
    'disposition-ccast-blood-results-microbiology-culture': 'Culture',

    'disposition-ccast-blood-results-gases': 'Gases',
    'disposition-ccast-blood-results-gases-fio2': 'FIO2',
    'disposition-ccast-blood-results-gases-po2': 'PO2',
    'disposition-ccast-blood-results-gases-pco2': 'PCO2',
    'disposition-ccast-blood-results-gases-ph': 'PH',
    'disposition-ccast-blood-results-gases-hco3': 'HCO3',

    'imagery-title': 'Case Imagery',
    'image/incident': 'Incident Images',
    'image/injuries' : 'Injury Images',

    'ecg': 'ECG',
    'ecg-date-recorded' : 'Date recorded',
    'ecg-report-notes': 'Notes',
    'ecg-duration': 'Duration',

    'ecg-stemi_anterior': 'Anterior',
    'ecg-stemi_inferior': 'Inferior',
    'ecg-stemi_lateral': 'Lateral',
    'ecg-stemi_septal': 'Septal',
    'ecg-st_depression_anterior':'ST Depression anterior',
    'ecg-st_depression_inferior':'ST Depression inferior',
    'ecg-st_depression_lateral':'ST Depression lateral',
    'ecg-st_depression_septal':'ST Depression septal',
    'ecg-lbbb': 'LBBB',
    'ecg-rbbb': 'RBBB',
    'ecg-vt': 'VT',
    'ecg-svt': 'SVT',
    'ecg-af': 'AF',
    'ecg-avt': 'AVT',
    'ecg-1_heartblock': '1\'Heartblock',
    'ecg-2_heartblock': '2\'Heartblock',
    'ecg-3_heartblock': '3\'Heartblock',
    'ecg-sinus_brady': 'Sinus Brady',
    'ecg-sinus_tachy': 'Sinus Tachy',
    'ecg-atrial_tachy': 'Atrial Tachy',

    'interventions-needle-d' : 'Needle Decompression',
    'interventions-left' : 'Left',
    'interventions-right' : 'Right',
    'interventions-needle-d-position' : 'Position',
    'interventions-needle-d-size' : 'Size',
    'interventions-needle-d-notes' : 'Notes',
    'interventions-chest-drain': 'Chest Drain',
    'interventions-chest-drain-size': 'Size',
    'interventions-chest-drain-notes': 'Notes',
    'interventions-chest-seal': 'Chest Seal',
    'interventions-chest-seal-position': 'Position',
    'interventions-chest-seal-size': 'Size',
    'interventions-chest-seal-notes': 'Notes',

    'date': "Date",
    'medication-name':'Medication',
    'quantity': 'Quantity',
    'route': 'Route',
    'frequency': 'Frequency',

    'vitals-label-avpu': 'AVPU',
    'vitals-label-capr': 'CAP R > 2s',
    'vitals-label-co2': 'CO2',
    'vitals-label-etco2' : 'ETCO2',
    'vitals-label-fico2' : 'FICO2',
    'vitals-label-ambientetco2' : 'Ambient ETCO2',

    'disposition-handover-title' : 'Handover',
    'disposition-handover-time' : 'Handover Time',
    'disposition-handover-signature': 'Handover Signature',

    'patient-details': 'Patient Details',
    'patient-details-forename': 'First Name',
    'patient-details-surname': 'Surname',
    'patient-details-dob': 'DOB',
    'patient-details-age':'Age',
    'patient-details-sex': 'Sex',
    'patient-details-service-no':'Service No',
    'patient-details-unit': 'Unit',
    'patient-details-brn':'Battle Roster Number',
    'patient-details-rank': "Rank",
    'patient-details-zap':'Zap',
    'patient-details-blood-group': 'Blood Group',
    'patient-details-blood-rhd': 'RhD', 

    'initial-assessment-title': 'Initial Assessment',
    'incident-mechanism-title' : 'Mechanism',
    'incident-mechanism-notes' : 'Notes',
    'injury-title':'Injury',
    'injury-head-title': 'Head',
    'injury-torso-title':'Torso',
    'injury-arms-title':'Arms',
    'injury-legs-titie':'Legs',

    'injury-none':'None',
    'injury-head':'Head',
    'injury-neck':'Neck',
    'injury-both':'Both',
    'injury-chest':'Chest',
    'injury-abdo':'Abdo',
    'injury-right':'Right',
    'injury-left':'Left',

    'signs':'Signs',
    'signs-pulse-title': 'Pulse',
    'signs-resprate-title': 'Resp Rate',
    'signs-capr-title':'Capillary Refill',
    'signs-spo2-title': 'SPO2',
    'signs-avpu-title': 'AVPU',

    'ia_pulse_state_none': 'None',
    'ia_pulse_state_gt_100': '> 100',
    'ia_pulse_state_lt_100': '< 100',
    'ia_cap_r_lt_2_secs':'< 2secs',
    'ia_cap_r_gt_2_secs':'> 2secs',
    'ia_resp_one':'> 24',
    'ia_resp_two':'12 - 24',
    'ia_resp_three':'< 12',
    'ia_bp_one':'< 89',
    'ia_bp_two':'> 90',
    'ia_spo2_one':'< 90',
    'ia_spo2_two':'> 91',

    'ia_tourniquet_history_title': 'TQ',

    'otfc': 'OTFC',
    'otfc-time' : 'Time',

    'initial-assessment-location' : 'Location',
    'initial-assessment-location-utm-ref': 'UTM Reference',
    'initial-assessment-location-mgrs-ref': 'MGRS Reference',

    'cbrn': 'CBRN',
    'high-risk-of-sepsis':'Red Flag Sepsis',
    'low-risk-of-sepsis':'Low Risk of Sepsis',
    'sepsis-likely': 'Sepsis Likely'

};