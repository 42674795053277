import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Disposition,PatientDetails,Vitals } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import { MultiSelectionComponent } from "./MultiSelectionComponent";
import { PmrOneComponent } from "./PmrOneComponent";
import { PmrTwoComponent } from "./PmrTwoComponent";
import { CCastComponent } from "./CCastComponent";
import DateTimeRender from "./DateTimeRenderer";
import { ReactElement } from "react";

export function DispositionComponent(p: { i: Disposition, v: Vitals, pd:PatientDetails }) {

    const t = p.i;
    const pd = p.pd;

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'disposition-title'} /> </Typography>} />

            <CardContent>
                {t && (
                    <ul>
                        <li>
                            <L t={'medevac-title'} />
                            <ul>
                                <li>
                                    <L t={'medevac-lzlocation'} />
                                    <ul>
                                        <li>
                                            <L t={'medevac-lz-latitude'} />{': '}<U><>{convertToLatLng(t.medevac?.lzLat)}</></U>
                                        </li>
                                        <li>
                                            <L t={'medevac-lz-longitude'} />{': '}<U><>{convertToLatLng(t.medevac?.lzLon)}</></U>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <L t={'medevac-callsign'} />
                                    <ul>
                                        <li>
                                            <L t={'medevac-callsign-radiofrequency'} />{': '}<U><>{t.medevac?.callRadio}</></U>
                                        </li>
                                        <li>
                                            <L t={'medevac-callsign-callsign'} />{': '}<U><>{t.medevac?.callSign}</></U>
                                        </li>
                                        <li>
                                            <L t={'medevac-callsign-suffix'} />{': '}<U><>{t.medevac?.callSuffix}</></U>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <L t={'medevac-casualties-precedence'} />
                                    <ul>
                                        <li>
                                            <L t={'medevac-casualties-precedence-urgent'} />{': '}<U><>{m1(t.medevac?.precedenceUrgent)}</></U>
                                        </li>
                                        <li>
                                            <L t={'medevac-casualties-precedence-urgent-surgical'} />{': '}<U><>{m1(t.medevac?.precedenceSurgical)}</></U>
                                        </li>
                                        <li>
                                            <L t={'medevac-casualties-precedence-priority'} />{': '}<U><>{m1(t.medevac?.precedencePriority)}</></U>
                                        </li>
                                        <li>
                                            <L t={'medevac-casualties-precedence-routine'} />{': '}<U><>{m1(t.medevac?.precedenceRoutine)}</></U>
                                        </li>
                                        <li>
                                            <L t={'medevac-casualties-precedence-convenience'} />{': '}<U><>{m1(t.medevac?.precedenceConvenience)}</></U>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <L t={'medevac-special-equipment-title'} />
                                    <ul>
                                        <li>
                                            <L t={'medevac-special-equipment'} />{': '}<U><MultiSelectionComponent
                                                data={t.medevac?.equipment ?? []}
                                                availableItems={{
                                                    "A - None": 'medevac-special-equipment-none',
                                                    "B - Hoist": 'medevac-special-equipment-hoist',
                                                    "C - Extraction Equipment": 'medevac-special-equipment-extraction',
                                                    "D - Ventilator": 'medevac-special-equipment-ventilator',
                                                    "E - Other": 'medevac-special-equipment-other',
                                                }
                                                } /></U>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'medevac-casualties-type'} />
                            <ul>
                                <li>
                                    <L t={'medevac-casualties-type-litter'} />{': '}<U><>{m1(t.medevac?.typeLitter)}</></U>
                                </li>
                                <li>
                                    <L t={'medevac-casualties-type-ambulatory'} />{': '}<U><>{m1(t.medevac?.typeAmbulatory)} </></U>
                                </li>
                                <li>
                                    <L t={'medevac-casualties-type-escort'} />{': '}<U><>{m1(t.medevac?.typeEscort)}</></U>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'medevac-security-title'} />
                            <ul>
                                <li>
                                    <L t={'medevac-security'} />{': '}<U><MultiSelectionComponent data={t.medevac?.security ?? []}
                                        availableItems={{
                                            "N - No Enemy Troops in Area": 'medevac-security-n',
                                            "P - Approach with Caution": 'medevac-security-p',
                                            "E - Enemy Troops in Area": 'medevac-security-e',
                                            "X - Armed Escort Required": 'medevac-security-x',
                                        }} /></U>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'medevac-markings-title'} />
                            <ul>
                                <li>
                                    <L t={'medevac-markings'} />{': '}<U><MultiSelectionComponent data={t.medevac?.marking ?? []}
                                        availableItems={{
                                            "A - Panels": 'medevac-markings-a',
                                            "B - Pyrotechnic": 'medevac-markings-b',
                                            "C - Smoke": 'medevac-markings-c',
                                            "D - None": 'medevac-markings-d',
                                            "E - Other": 'medevac-markings-e',
                                        }} /></U>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'medevac-nationality-status-title'} />
                            <ul>
                                <li>
                                    <L t={'medevac-nationality-status'} />{': '}<U><MultiSelectionComponent data={t.medevac?.nationality ?? []}
                                        availableItems={{
                                            "A - Coalition Military": 'medevac-nationality-a',
                                            "B - Coalition Civilian": 'medevac-nationality-b',
                                            "C - Non-Coalition Forces": 'medevac-nationality-c',
                                            "D - Non-Coalition Civilian": 'medevac-nationality-d',
                                            "E - Opposing Forces Detainees": 'medevac-nationality-e',
                                            "F - Child": 'medevac-nationality-f',
                                        }} /></U>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'medevac-zone-terrain-obstacles-title'} />{': '}<U><>{t.medevac?.obstacles}</></U>
                        </li>
                        <PmrOneComponent t={t.pmrOne} pd={pd} />
                        <PmrTwoComponent t={t.pmrTwo} />
                        <CCastComponent t={t.ccast} v={p.v} />
                        <li>
                            <L t={'disposition-destination-title'} />
                            <ul>
                                <li>
                                    <L t={'disposition-destinationtype'} />{': '}<U><L t={t.destinationType !== '' ? 'disposition-destinationtype-' + t.destinationType : ''} /></U>
                                </li>
                                <li>
                                    <L t={'disposition-destinationname'} />{': '}<U><>{t.destinationName}</></U>
                                </li>
                                <li>
                                    <L t={'dispostition-outcome'} />
                                    <ul>
                                        <li>
                                            <L t={'disposition-outcome-condition'} />{': '}<U><><L t={t.outcomeCondition !== '' ? 'disposition-outcomecondition-' + t.outcomeCondition : ''} /></></U>
                                        </li>
                                        <li>
                                            <L t={'disposition-outcome-concious'} />{': '}<U><><L t={t.outcomeConscious !== '' ? t.outcomeConscious : ''} /></></U>
                                        </li>
                                        <li>
                                            <L t={'disposition-outcome-rosc'} />{': '}<U><><L t={t.outcomeCirculation !== '' ? t.outcomeCirculation : ''} /></></U>
                                        </li>
                                        <li>
                                            <L t={'disposition-outcome-respiration'} />{': '}<U><><L t={t.outcomeRespiration !== '' ? t.outcomeRespiration : ''} /></></U>
                                        </li>
                                        <li>
                                            <L t={'disposition-outcome-pain'} />{': '}<U><><L t={t.outcomePain !== '' ? 'disposition-outcome-pain-' + t.outcomePain : ''} /></></U>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <L t={'disposition-receiving'} />
                                    <ul>
                                        <li>
                                            <L t={'disposition-receiving-name'} />{': '}<U><>{t.receiverName}</></U>
                                        </li>
                                        <li>
                                            <L t={'disposition-receiving-role'} />{': '}<U><>{t.receivingRole}</></U>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>                        
                        <HandoverComponent d={t} />

                    </ul>)}
            </CardContent>
        </Card>
    )
}

 function HandoverComponent(p:{d:Disposition}) {
    return ( 
    <li>
        <L t={'disposition-handover-title'} />
        <ul>
            <li>
                <L t={'disposition-handover-time'} />{': '}<U><DateTimeRender value={p.d.handoverTime} format='evt' /></U>
            </li>
            <li>
                <L t={'disposition-handover-signature'} /><br /><U><>
                    <img alt='signature' width={'100%'} src={`data:image/jpeg;base64,${p.d.handoverSignature}`} /></></U>
            </li>
        </ul>
    </li>
    )
}

function convertToLatLng(val:Number|undefined) {
    if (val == 1000) return "";
    else return val?.toFixed(6);
}
function m1(val:Number|undefined)  {
    if (val == undefined || val == -1) return "";
    return val;
}
