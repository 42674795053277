import { Card, CardHeader, Typography, CardContent, Stack } from "@mui/material";
import { CaseDetail } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import DateTimeRender from "./DateTimeRenderer";
import { NumberOrMinusOneComponent } from "./NumberOrMinusOneComponent";
import { PrimarySurveyComponent } from "./PrimarySurveyComponent";
import { CycleComponent } from "./CycleComponent";
import { TreatmentsComponent } from "./TreatmentsComponents";
import { MedicationsComponent } from "./MedicationsComponent";

export function ATMISTComponent(p: { selectedCase: CaseDetail }) {
    const c = p.selectedCase
    const patientName = (c.patientDetails.name+' '+c.patientDetails.surname).trim();
    console.log(c);
    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t="atmist-title" /> </Typography>} />

            <CardContent>
                {c && (
                    <Stack>
                        <Typography component="h1">{c.caseId}</Typography>
                        <Typography component="h2"><U><><L t={'name'}/>:{patientName}</></U></Typography>
                        <Typography component="h2"><L t="patient-details-age" /></Typography>
                        <ul>
                        <li><U><Typography component="h2"><L t="patient-details-age" />:  <NumberOrMinusOneComponent v={c.patientDetails.age} decimalPlaces={0}/> </Typography></U></li>
                        <li><U><Typography component="h2"><L t="patient-details-sex" />:  <L t={c.patientDetails.sex}/> </Typography></U></li>
                        </ul>
                        <Typography component="h2"><L t="timings-title" />:</Typography>
                        <ul>
                         <li><U><><L t={'timings-incident'}/>: <DateTimeRender value={c.timings.incident} format="evt" /></></U></li>
                         <li><U><><L t={'timings-assigned'}/>: <DateTimeRender value={c.timings.assigned} format="evt" /></></U></li>
                         <li><U><><L t={'timings-dispatched'}/>: <DateTimeRender value={c.timings.dispatched} format="evt" /></></U></li>
                         <li><U><><L t={'timings-atlocation'}/>: <DateTimeRender value={c.timings.atLocation} format="evt" /></></U></li>
                         <li><U><><L t={'timings-atpatient'}/>: <DateTimeRender value={c.timings.atPatient} format="evt" /></></U></li>
                         <li><U><><L t={'timings-leavelocation'}/>: <DateTimeRender value={c.timings.leaveLocation} format="evt" /></></U></li>
                         <li><U><><L t={'timings-atdestination'}/>: <DateTimeRender value={c.timings.atDestination} format="evt" /></></U></li>
                         <li><U><><L t={'disposition-handover-title'}/>: <DateTimeRender value={c.disposition.handoverTime} format="evt" /></></U></li>
                         <li><U><><L t={'timings-leavedestination'}/>: <DateTimeRender value={c.timings.leaveDestination} format="evt" /></></U></li>
                         <li><U><><L t={'timings-cleared'}/>: <DateTimeRender value={c.timings.cleared} format="evt" /></></U></li>
                        </ul>
                        <Typography component="h2"><L t="incident-mechanism" /></Typography>
                        <ul>{(c.incident.mechanism ?? []).map(
                            x=><li><U><L t={x} /></U></li>
                        )}
                        </ul> 
                        <Typography component="h2"><L t="injuries-title" /></Typography>
                        <PrimarySurveyComponent p={c.primarySurvey} d={c.patientDetails}/>
                        <Typography component="h2"><L t="signs" /></Typography>
                        <ul>
                            <li><U><><L t={'vitals-label-hrhistory'} />: <NumberOrMinusOneComponent v={c.vitals.hr} decimalPlaces={0} units="bpm" /></></U></li>
                            <li><U><><L t={'vitals-label-coretemphistory'} />: <NumberOrMinusOneComponent v={c.vitals.coreTemp} decimalPlaces={1} units="°C" /></></U></li>
                            <li><U><><L t={'vitals-label-rrhistory'} />: <NumberOrMinusOneComponent v={c.vitals.rr} decimalPlaces={0} units="bpm" /></></U></li>
                            <li><U><><L t={'vitals-label-systolicbp'} />: <NumberOrMinusOneComponent v={c.vitals.systolicBp} decimalPlaces={0} /> / <NumberOrMinusOneComponent v={c.vitals.diastolicBp} decimalPlaces={0} units='mmHg' /> </></U></li>
                            <li><U><><L t={'vitals-label-o2history'} />: <NumberOrMinusOneComponent v={c.vitals.o2} decimalPlaces={0} units="%" /></></U></li>
                            <li><U><><L t={'vitals-label-bghistory'} />: <NumberOrMinusOneComponent v={c.vitals.bloodGlucose} decimalPlaces={1} units="mmol/L" /></></U></li>
                            <li><U><><L t={'vitals-label-avpu'} />: <CycleComponent availableItems={{0:{text:' A '},1:{text:' V '},2:{text:' P '},3:{text:' U '}}} data={c.vitals.avpu.toString()} /> </></U></li>
                        </ul>
                        <Typography component="h2"><L t="treatment-title" /></Typography>
                        <ul>
                            <li><TreatmentsComponent i={c.treatments} th={c.tourniquetHistory} /></li>        
                            <li><MedicationsComponent i={c.medications} ia={c.initialAssessment} /></li>
                        </ul>
                    </Stack>)

                }
            </CardContent>
        </Card>)
}
