export const TRANSLATIONS_US = {

    "yes" : "Ano",
    "no" : "Ne",

    "name": "Name",
    "gender": "Gender",
    "zap":"Zap",

    "dashboard-title": "Dashboard",

    "active Cases": "Active Cases",
    "historic Cases": "Historic Cases",

    "ample":"AMPLE",
    "allergies":"Allergies",
    "past medical history":"Past Medical History",
    "medications":"Medications",
    "no ample details recorded.": "No Ample details recorded.",
    "asprin":"Asprin",
    "carbamazepine":"Carbamazepine",
    "cephalosporin" : "Cephalosporin",
    "codeine" : "Codeine" ,
    "insulin" : "Insulin",
    "latex" : "Latex",
    "nsaid" : "Nsaid",
    "penicillin" : "Penicillin",
    "phenytoin" : "Phenytoin",
    "sulphonamide" : "Sulphonamide",
    "tetracycline" : "Tetracycline",
    "other" : "Other",
    "none" : "None",
    "unknown" : "Unknown",
    "no medical history recorded.":"No Medical History recorded.",
    "last oral intake":"Last Oral Intake",
    "event": "Event",
    "hours": "Hours",


    "cancer":"Cancer",
    "cva":"CVA",
    "diabetes" : "Diabetes",
    "epilepsy":"Epilepsy",
    "chronic heart condition":"Chronic Heart Condition",
    "hypertension":"Hypertension",
    "chronic lung condition":"Chronic Lung Condition",
    "mental Illness":"Mental Illness",
    "physically Disabled":"Physically Disabled",
    "pregnant":"Pregnant",
    "myocardial infarction":"Myocardial Infarction",
    

    "chooseLocale": "Choose Locale",
    "close": "Close",
    "uk": "United Kingdom",
    "unitedstates" : "United States",
    "german": "Germany",
    "french": "French",
    "czech": "Czech",
    "spain": "Spain"
   };