import { Container, Grid, Tabs, Tab, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MongoDBClientConsumer, MongoDBClientContext } from "../providers/mongoDBClientProvider"
import { AmpleComponent } from "./AmpleComponent";
import { BuildSecondaryActions, PerformStandardAction } from "./buildGISUtils";
import { CarePlanComponent } from "./CarePlanComponent";
import { CaseSummaryTile } from "./CaseSummaryTile";
import { DispositionComponent } from "./DispositionComponent";
import { IncidentComponent } from "./IncidentComponent";
import { InjuriesComponent } from "./InjuriesComponent";
import { InterventionsComponent } from "./InterventionsComponent";
import { MedicationsComponent } from "./MedicationsComponent";
import { PrimarySurveyComponent } from "./PrimarySurveyComponent";
import { TimingsComponent } from "./TimingsComponent";
import { TreatmentsComponent } from "./TreatmentsComponents";
import VitalsComponent from "./VitalsComponent";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { a11yProps, TabPanel } from "./TabPanelComponent";
import { ImageryComponent } from "./ImageryComponent";
import { AzureClientProvider } from "../providers/azureClientProvider";
import { ECGPage } from "./ECGPage";
import { PatientDetailsComponent } from "./PatientDetails";
import {InitialAssessmentComponent} from "./InitialAssessmentComponent";
import { ATMISTComponent } from "./ATMISTComponent";

export function CaseDetailsPage() {
    const { dispatch } = useContext(MongoDBClientContext);
    const [searchParams] = useSearchParams()
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        const id = searchParams.get('id')
        dispatch({ type: 'selectedCaseChange', data: id })
    }, [searchParams, dispatch]);

    useEffect(() => {
        const tab = searchParams.get('tab')
        if (tab === 'ecg') {
            setCurrentTab(13)
        }
    }, [searchParams, dispatch]);


    const navigate = useNavigate();

    const handleClick = (id: string, tab: string) => {
        PerformStandardAction(navigate, id, tab);
    }

    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <Container>
            <Typography component='h2' >
                Case Details
            </Typography>
            <AzureClientProvider>
            <MongoDBClientConsumer>
                {({ state, dispatch }) => {
                    return (state.selectedCase &&
                        <div>
                            <CaseSummaryTile c={state.selectedCase} actions={BuildSecondaryActions(state.selectedCase, 40, handleClick, "row", true, true, false)} />
                            <Grid sx={{ borderBottom: 1, borderColor: 'divider' }} >
                                <Tabs value={currentTab} onChange={handleChange} centered={false}
                                    TabIndicatorProps={{sx:{display:'none'}}} 
                                    sx={{'& .MuiTabs-flexContainer': {
                                        flexWrap: 'wrap',}}}
                                    >
                                    <Tab label={t('initial-assessment')} {...a11yProps(0)} />
                                    <Tab label={t('timings-title')} {...a11yProps(1)} wrapped />
                                    <Tab label={t('incident')} {...a11yProps(2)} wrapped />
                                    <Tab label={t('patient-details')} {...a11yProps(3)} wrapped />
                                    <Tab label={t('ample')} {...a11yProps(4)} wrapped />
                                    <Tab label={t('primary-survey-title')} {...a11yProps(5)} wrapped />
                                    <Tab label={t('vitals')} {...a11yProps(6)} wrapped />
                                    <Tab label={t('injuries-title')} {...a11yProps(7)} wrapped />
                                    <Tab label={t('treatment-title')} {...a11yProps(8)} wrapped />
                                    <Tab label={t('interventions')} {...a11yProps(9)} wrapped />
                                    <Tab label={t('medications-title')} {...a11yProps(10)} wrapped />
                                    <Tab label={t('care-plan-title')} {...a11yProps(11)} wrapped />
                                    <Tab label={t('disposition-title')} {...a11yProps(12)} wrapped />
                                    <Tab label={t('ecg')} {...a11yProps(13)} wrapped />
                                    <Tab label={t('imagery-title')} {...a11yProps(14)} wrapped />
                                    <Tab label={t('atmist-title')} {...a11yProps(15)} wrapped />
                                </Tabs>
                            </Grid>
                            <TabPanel value={currentTab} index={4}>
                                <AmpleComponent a={state.selectedCase.ample} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={2}>
                                <IncidentComponent c={state.selectedCase} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={6}>
                                <VitalsComponent v={state.selectedCase.vitals} h={state.selectedCase.vitalsHistory} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={1}>
                                <TimingsComponent t={state.selectedCase.timings} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={5} >
                                <PrimarySurveyComponent p={state.selectedCase.primarySurvey} d={state.selectedCase.patientDetails} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={7} >
                                <InjuriesComponent c={state.selectedCase} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={8} >
                                <TreatmentsComponent i={state.selectedCase.treatments} th={state.selectedCase.tourniquetHistory} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={9} >
                                <InterventionsComponent i={state.selectedCase.interventions} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={10} >
                                <MedicationsComponent i={state.selectedCase.medications} ia={state.selectedCase.initialAssessment} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={11} >
                                <CarePlanComponent carePlan={state.selectedCase.carePlan} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={12} >
                                <DispositionComponent i={state.selectedCase.disposition} v={state.selectedCase.vitals?? {}} pd={state.selectedCase.patientDetails}  />
                            </TabPanel>
                            <TabPanel value={currentTab} index={13} >
                                <ECGPage selectedCase={state.selectedCase} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={14} >
                                <ImageryComponent selectedCase={state.selectedCase} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={3} >
                                <PatientDetailsComponent d={state.selectedCase.patientDetails} c={state.selectedCase.disposition.ccast} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={0}>
                                <InitialAssessmentComponent d={state.selectedCase.initialAssessment} t={state.selectedCase.incident} 
                                x={state.selectedCase.treatments} i={state.selectedCase.interventions} h={state.selectedCase.tourniquetHistory} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={15} >
                                <ATMISTComponent selectedCase={state.selectedCase} />
                            </TabPanel>
                      </div>
                    )
                }
                }
            </MongoDBClientConsumer>
            </AzureClientProvider>
      </Container>);
};