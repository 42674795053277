import * as React from 'react';
import i18n from "i18next";
import { type } from 'os';


type DateTimeRenderProps = { value: string | null | Date | undefined, format?:string }

export default class DateTimeRender extends React.Component<DateTimeRenderProps, {}> {
    render() {
        let dt = convertToDate(this.props.value);
        let fmt = this.props.format;
        let lang = i18n.language;

        

        if (fmt === "date") {
            let df = dt?.toLocaleString(lang,{dateStyle:'medium', hour12:false});
            return <>{df}</>;
        }
        if (fmt === "evt" && dt) {
            let df = dt?.toLocaleString(lang,{dateStyle:'medium', timeStyle:'short', hour12:false})
            return <>{df}</>;
        } else if (dt && fmt !== undefined) {
            let df = dt?.toLocaleString(lang)
            return <>{df}</>;
        }
        return <>{}</>;
    }
}

function convertToDate(value: string | Date | null | undefined): string | Date | null | undefined {
    if  (Object.prototype.toString.call(value) === '[object String]') {

        let m =(value as string).match(/^(\d\d)[\/-](\d\d)[\/-](\d\d\d\d)$/);
        if (m) {
            //console.log(m[0],m[1],m[2],m[3])
            return new Date(Number.parseInt(m[3]), Number.parseInt(m[2])-1, Number.parseInt(m[1]) );
        }
        else if (value) { 
            return new Date(value as string);        
        }
    }
    return value
}
