import { PatientDetails, PmrOne } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import { MultiSelectionComponent } from "./MultiSelectionComponent";
import DateTimeRender from "./DateTimeRenderer";
import { NumberOrMinusOneComponent } from "./NumberOrMinusOneComponent";


export function PmrOneComponent(p: { t: PmrOne | null, pd: PatientDetails | null }) {
    const t = p.t;
    const pd = p.pd;
    return (t && pd && 
        <li>
            <L t={'disposition-pmrone-title'} />
            <ul>
                <li>
                    <L t={'disposition-pmrone-dtg'} />{': '}<U><DateTimeRender value={t.dTG} format='evt' /></U>
                </li>
                <li>
                    <L t={'disposition-pmrone-emplane-title'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrone-emplane-origin'} />{': '}<U><>{t.emplaneOrigin}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-emplane-destination'} />{': '}<U><>{t.emplaneDestination}</></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-priority'} />
                    <ul>
                        <li>
                            <U><MultiSelectionComponent data={[t.priority === -1 ? "" : t.priority.toString()]}
                                availableItems={{
                                    '1': 'disposition-pmrone-priority-1',
                                    '2': 'disposition-pmrone-priority-2',
                                    '3': 'disposition-pmrone-priority-3',
                                }} /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-patient-details'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrone-patient-details-servicenumber'} />{': '}<U><>{pd.serviceNumber}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-patient-details-rank'} />{': '}<U><>{pd.rank}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-patient-details-name'} />{': '}<U><>{pd.name} {pd.surname}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-patient-details-dob'} />{': '}<U><DateTimeRender value={pd.dob} format='date' /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-patient-unit'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrone-patient-unit-deployed'} />{': '}<U><>{t.unitDeployed}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-patient-unit-home'} />{': '}<U><>{t.unitHome}</></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-passport-details'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrone-passport-details-number'} />{': '}<U><>{t.passportNumber}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-passport-details-issued'} />{': '}<U><DateTimeRender value={t.passportIssued} format='date' /></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-passport-details-expiry'} />{': '}<U><DateTimeRender value={t.passportExpiry} format='date' /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-classification'} />
                    <ul>
                        <li>
                            <U><MultiSelectionComponent data={[t.classification]}
                                availableItems={{
                                    '1a - Severe Psychiatric': 'disposition-pmrone-classification-1a',
                                    '1b - Intermediate Psychiatric': 'disposition-pmrone-classification-1b',
                                    '1c - Mildly Psychiatric - Escorted': 'disposition-pmrone-classification-1c',
                                    '1c - Mildly Psychiatric - Unescorted': 'disposition-pmrone-classification-1c-unescorted',
                                    '2a - Stretcher - Immobile - Escorted': 'disposition-pmrone-classification-2a',
                                    '2b - Stretcher - Mobile - Escorted': 'disposition-pmrone-classification-2b',
                                    '3a - Sitting - Immobile - Escorted': 'disposition-pmrone-classification-3a',
                                    '3b - Sitting - Mobile - Escorted': 'disposition-pmrone-classification-3b',
                                    '4 - Walking - Unescorted': 'disposition-pmrone-classification-4',
                                    '5 - Outpatient - Unescorted': 'disposition-pmrone-classification-5',
                                    '6 - Civilian': 'disposition-pmrone-classification-6'
                                }} /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-current-location-details'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrone-current-location-details-patient'} />{': '}<U><>{t.locationPatient}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-current-location-details-treating-hospital'} />{': '}<U><>{t.locationHospital}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-current-location-details-mo-ic'} />{': '}<U><>{t.locationMO}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-current-location-details-hospital-contact-number'} />{': '}<U><>{t.locationHContact}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-current-location-details-mo-contact'} />{': '}<U><>{t.locationMOContact}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-current-location-details-patient-contact'} />{': '}<U><>{t.locationPContact}</></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-cabin-altitude-restriction'} />
                    <ul>
                        <li>
                            <U><MultiSelectionComponent data={[t.altResYes ? "Yes" : "", t.altResNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                            {(t.altResYes) &&
                                <li>
                                    <L t={'disposition-pmrone-altitude-restriction-altitude'} />{': '}<U><>{t.altResAltitude}&nbsp;<L t={'unit-' + t.altResUnit} /></></U>
                                </li>}
                        </li>
                    </ul>
                </li>
                <li>
                <L t={'disposition-pmrone-accompanying-relatives'} />
                    <ul>
                        <L t={'disposition-pmrone-accompanying-relatives-count'} />{': '}<U><><NumberOrMinusOneComponent v={t.relativeNumber} decimalPlaces={0} /></></U>
                            {t.relatives.map(r => <li><U><>{r.name} - {r.relationship}</></U></li>)}
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-dietary'} />
                    <ul><li><U><MultiSelectionComponent data={t.dietaryReqs}
                        availableItems={{
                            'None': 'disposition-pmrone-dietary-none',
                            'Gluten Free': 'disposition-pmrone-dietary-gluten-free',
                            'Halal': 'disposition-pmrone-dietary-halal',
                            'Kosher': 'disposition-pmrone-dietary-kosher',
                            'Vegan': 'disposition-pmrone-dietary-vegan',
                            'Vegetarian': 'disposition-pmrone-dietary-vegetarian',
                        }} /></U>
                    </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-patient-disposal'} />
                    <ul>
                        <li><U><>{t.patientDisposal}</></U></li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-transport-arrangements'} />
                    <ul>
                        <li><U><MultiSelectionComponent data={t.transport}
                            availableItems={{
                                'Designated by AECC': 'disposition-pmrone-transport-aecc',
                                'NHS': 'disposition-pmrone-transport-nhs',
                                'Parent Unit': 'disposition-pmrone-parent-unit',
                                'Private': 'disposition-pmrone-transport-private',
                                'RAF Northolt': 'disposition-pmrone-transport-raf-northolt',
                                'TACMEDWG Brize Norton': 'disposition-pmrone-transport-brize-norton',
                            }} /></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-patient-listing'} />
                    <ul>
                        <li>
                            <U><>{t.patientListing}</></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-mes'} />
                    <ul>
                        <li>
                            <U><MultiSelectionComponent data={[t.mesYes ? "Yes" : "", t.mesNo ? "No" : ""]}
                                availableItems={{ "Yes": 'yes', "No": 'no' }} />
                            </U>
                            {(t.mesYes) &&
                                <li>
                                    <L t={'disposition-pmrone-mes-date'} />{': '}<U><DateTimeRender value={t.mesDate} format='evt' /></U>
                                </li>}
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-mbi-bi-disease'} />
                    <ul>
                        <li>
                            <U><>{t.disease}</></U>
                        </li>
                    </ul>
                </li>
                <li>
                    <L t={'disposition-pmrone-signature'} />
                    <ul>
                        <li>
                            <L t={'disposition-pmrone-signature-rank'} />{': '}<U><>{t.sigRank}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-signature-name'} />{': '}<U><>{t.sigName}</></U>
                        </li>
                        <li>
                            <L t={'disposition-pmrone-signature-sig'} /><br /><U><>
                                <img alt='signature' width={'100%'} src={`data:image/jpeg;base64,${t.signature}`} /></></U>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    );
}
