import { Card, CardContent, CardHeader, Chip, Grid, Typography } from "@mui/material";
import { PrimarySurvey, PatientDetails, CatHem, CerSpin, Airway, Breathing, Circulation, Disability, Examination, Sepsis } from "../models/Cases";
import U from './UpdateItemIndicator';
import { useTranslation } from "react-i18next";
import L from "./localeText";

import { ReactChild, ReactChildren, ReactElement } from "react";
import personBack from "../assets/img/person_back.png";
import personFront from "../assets/img/person_front.png";
import { CanvasComponent } from "./CanvasComponent";

export function PrimarySurveyComponent(p: { p: PrimarySurvey, d: PatientDetails }) {

    const t = p.p;

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t={'primary-survey-title'} /> </Typography>} />

            <CardContent>
                {t && (<>
                    <CatastrophicHaemorrhage c={t.catHem} />
                    <CervicalSpine c={t.cerSpin} />
                    <AirwayComponent c={t.airway} />
                    <BreathingComponent c={t.breathing} />
                    <CirculationComponent c={t.circulation} />
                    <DisabilityComponent c={t.disability} p={p.d} />
                    <ExaminationComponent c={t.examination} />
                    <SepsisComponent c={t.sepsis} />

                </>
                )}
            </CardContent>
        </Card>);
}

function SubCard(p: { title: string, children: ReactChild | ReactChildren }) {
    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h2"><L t={p.title} /> </Typography>} />
            <CardContent>
                {p.children}
            </CardContent>
        </Card>);
}

function CatastrophicHaemorrhage(p: { c: CatHem }) {
    const t = p.c;
    const children = t.yes ? <>
        <U><ul>
            <li><L t={'ps-site-title'} />:&nbsp;{(t.site || []).map((m, i) => <><span>{i > 0 ? ' ,' : ''}</span> <L t={m} /></>)}</li>
            <li><L t={'ps-loss-title'} />:&nbsp;<L t={t.loss} /></li>
        </ul></U>
    </> : <></>;

    return (
        <SubCard title="ps-cat-hem-title">
            <>
                <U><><L t={'ps-observation'} />:&nbsp;<L t={t.yes ? "yes" : ""} /><L t={t.no ? "no" : ""} /></></U>
                {children}
            </>
        </SubCard>
    )
}
function CervicalSpine(p: { c: CerSpin }) {
    const t = p.c;
    const children = t.abnormal ? <>
        <U><ul>
            <li><L t={'ps-abnormality-title'} />:&nbsp;{(t.abnormality || []).map((m, i) => <><span>{i > 0 ? ' ,' : ''}</span> <L t={m} /></>)}</li>
        </ul></U>
    </> : <></>;

    return (
        <SubCard title="ps-cer-spin-title">
            <>
                <U><><L t={'ps-observation'} />:&nbsp;<L t={t.abnormal ? "abnormal" : ""} /><L t={t.normal ? "normal" : ""} /></></U>
                {children}
            </>
        </SubCard>
    )

}

function AirwayComponent(p: { c: Airway }) {
    const t = p.c;
    const children = t.abnormal ? <>
        <U><ul>
            <li><L t={'ps-airway-severity'} />:&nbsp;<L t={t.severity} /></li>
            <li><L t={'ps-airway-cause'} />:&nbsp;{(t.cause || []).map((m, i) => <><span>{i > 0 ? ' ,' : ''}</span> <L t={m} /></>)}</li>

        </ul></U>
    </> : <></>;

    return (
        <SubCard title="ps-airway-title">
            <>
                <U><><L t={'ps-observation'} />:&nbsp;<L t={t.abnormal ? "abnormal" : ""} /><L t={t.normal ? "normal" : ""} /></></U>
                {children}
            </>
        </SubCard>
    )

}

function BreathingComponent(p: { c: Breathing }) {
    const t = p.c;
    const children = t.abnormal ? <>
        <U><ul>
            <li><L t={'ps-breathing-rate'} />:&nbsp;<L t={t.rate} /></li>
            <li><L t={'ps-breathing-type'} />:&nbsp;{(t.type || []).map((m, i) => <><span>{i > 0 ? ' ,' : ''}</span> <L t={m} /></>)}</li>

        </ul></U>
    </> : <></>;

    return (
        <SubCard title="ps-breathing-title">
            <>
                <U><><L t={'ps-observation'} />:&nbsp;<L t={t.abnormal ? "abnormal" : ""} /><L t={t.normal ? "normal" : ""} /></></U>
                {children}
            </>
        </SubCard>
    )

}

function CirculationComponent(p: { c: Circulation }) {
    const t = p.c;
    const children = t.abnormal ? <>
        <U><ul>
            <li><L t={'ps-circulation-pulse'} />:&nbsp;{(t.pulse || []).map((m, i) => <><span>{i > 0 ? ' ,' : ''}</span> <L t={m} /></>)}</li>
            <li><L t={'ps-circulation-complexion'} />:&nbsp;{(t.complexion || []).map((m, i) => <><span>{i > 0 ? ' ,' : ''}</span> <L t={m} /></>)}</li>
            <li><L t={'ps-circulation-temperature'} />:&nbsp;<L t={t.temperature} /></li>
        </ul></U>
    </> : <></>;

    return (
        <SubCard title="ps-circulation-title">
            <>
                <U><><L t={'ps-observation'} />:&nbsp;<L t={t.abnormal ? "abnormal" : ""} /><L t={t.normal ? "normal" : ""} /></></U>
                {children}
            </>
        </SubCard>
    )

}

function DisabilityComponent(p: { c: Disability, p: PatientDetails }) {
    const t = p.c;
    let eyes: string = '';
    let verbal: string = '';
    let motor: string = '';

    const isChild = p.p.age !== undefined && p.p.age < 5
    switch (t.gcsEyes) {
        case 1: eyes = 'does not open eyes'; break;
        case 2: eyes = 'eyes open to pain'; break;
        case 3: eyes = 'eyes open to speech'; break;
        case 4: eyes = 'eyes open immediately'; break;

    }
    switch (t.gcsVerbal) {
        case 5: verbal = isChild ? 'appropriate coo & cry' : 'alert and orientated'; break;
        case 4: verbal = isChild ? 'irritable cry' : 'confused or disorientated'; break;
        case 3: verbal = isChild ? 'inconsolable crying' : 'inappropriate words'; break;
        case 2: verbal = isChild ? 'grunts' : 'unintelligable sounds'; break;
        case 1: verbal = 'no sounds'; break;

    }
    switch (t.gcsMotor) {
        case 6: motor = isChild ? 'normal spontaneous' : 'obeys command'; break;
        case 5: motor = isChild ? 'withdraws to touch' : 'localises pain'; break;
        case 4: motor = 'withdrawal from pain'; break;
        case 3: motor = 'abnormal flexion'; break;
        case 2: motor = 'extension response'; break;
        case 1: motor = 'no response'; break;

    }


    const children = t.abnormal ? <>
        <U><ul>
            <li><L t={'ps-disabilty-gcs-eyes'} />:&nbsp;<L t={eyes} /></li>
            <li><L t={'ps-disabilty-gcs-verbal'} />:&nbsp;<L t={verbal} /></li>
            <li><L t={'ps-disabilty-gcs-motor'} />:&nbsp;<L t={motor} /></li>
            <li><L t={'ps-disabilty-gcs-score'} />:&nbsp;{(t.gcsEyes < 0 ? 0 : t.gcsEyes) + (t.gcsMotor < 0 ? 0 : t.gcsMotor) + (t.gcsVerbal < 0 ? 0 : t.gcsVerbal)}</li>
            <PupilsComponent c={t} />
            <FASTComponent c={t} />

        </ul></U>
    </> : <></>;

    return (
        <SubCard title="ps-disability-title">
            <>
                <U><><L t={'ps-observation'} />:&nbsp;<L t={t.abnormal ? "abnormal" : ""} /><L t={t.normal ? "normal" : ""} /></></U>
                {children}
            </>
        </SubCard>
    )

}

function PupilsComponent(p: { c: Disability }) {
    const t = p.c;
    var pupilsR = t.pupilsRValue ? 'disability-eyes-' + t.pupilsRValue.toLowerCase() : '';
    var pupilsL = t.pupilsLValue ? 'disability-eyes-' + t.pupilsLValue.toLowerCase() : '';

    const children = t.abnormal ? <>
        <U><>
            <li><L t={'ps-pupils-right'} />:&nbsp;<L t={pupilsR} />&nbsp;<L t={'ps-pupils-right-size'} />:&nbsp;{t.pupilsRSize > 0 ? t.pupilsRSize + ' mm' : ''} </li>
            <li><L t={'ps-pupils-left'} />:&nbsp;<L t={pupilsL} />&nbsp;<L t={'ps-pupils-left-size'} />:&nbsp;{t.pupilsLSize > 0 ? t.pupilsLSize + ' mm' : ''} </li>
        </>
        </U>
    </> : <></>;

    return (
        <>
            {children}
        </>
    )
}

function FASTComponent(p: { c: Disability }) {
    const t = p.c;
    const children = t.abnormal ? <>
        <U><>
            <li><L t={'ps-fast-facial'} />:&nbsp;<L t={t.fastFacial} /></li>
            <li><L t={'ps-fast-arm'} />:&nbsp;<L t={t.fastArm} /></li>
            <li><L t={'ps-fast-leg'} />:&nbsp;<L t={t.fastLeg} /></li>
            <li><L t={'ps-fast-speech'} />:&nbsp;<L t={t.fastSpeech} /></li>

        </>
        </U>
    </> : <></>;

    return (
        <>
            {children}
        </>
    )
}


function ExaminationComponent(p: { c: Examination }) {
    const t = p.c;
    const u = `${personFront}`;
    const b = `${personBack}`;
    const children = t.abnormal ? <Grid container >
        <Grid item xs={6}><CanvasComponent background={u} markers={t.frontAnnotations} scalex={0.29} scaley={0.29} /> </Grid>
        <Grid item xs={6}><CanvasComponent background={b} markers={t.backAnnotations} scalex={0.31} scaley={0.31} /> </Grid>
    </Grid> : <></>;

    return (
        <SubCard title="ps-examination">
            <>
                {children}
            </>
        </SubCard>
    )
}

function SepsisComponent(p: { c: Sepsis }) {
    const sepsis = p.c;
    const oneYes = sepsis.oneYes || [];
    const oneNo = sepsis.oneNo || [];
    const two = sepsis.two || [];
    const three = sepsis.three || [];
    const five = sepsis.five || [];

    const {t} = useTranslation();

    const section2visible = oneYes.length > 0;
    const section3visible = two.some(t => t != "No") || sepsis.twoOther;
    const section5visible = section3visible && three.some(t => t === 'No');


    const questions: { [index: string]: { q: string, y: string[], yv: string, n: string[], nv: string } } = {
        'ps-sepsis-patient-look-sick': { q: "does patient look very sick?", y: oneYes, yv: 'yes', n: oneNo, nv: 'no' },
        'ps-sepsis-observations-abnormal': { q: "are any observations abnormal?", y: oneYes, yv: 'yes', n: oneNo, nv: 'no' },
        'ps-sepsis-child-observations-abnormal': { q: "are observations abnormal?", y: oneYes, yv: 'yes', n: oneNo, nv: 'no' },
        'ps-sepsis-meows-triggering': { q: "is meows triggering?", y: oneYes, yv: 'yes', n: oneNo, nv: 'no' },
        'ps-sepsis-news-3-or-above': { q: "is the news 3 or above?", y: oneYes, yv: 'yes', n: oneNo, nv: 'no' },
        'ps-sepsis-child-feverish': { q: "is the child feverish or looking sick?", y: oneYes, yv: 'yes', n: oneNo, nv: 'no' },
        'ps-sepsis-child-parent-worried': { q: "is the parent/carer very worried?", y: oneYes, yv: 'yes', n: oneNo, nv: 'no' },
    };

    const fn = (key: string, y: string[], yval: string, n: string[], nval: string) =>
        y && y.findIndex(q => q.toLowerCase() === key) !== -1 ? yval : (n && n.findIndex(q => q.toLowerCase() === key) !== -1 ? nval : '');


    const answers: ReactElement[] = [];
    for (const k in questions) {
        const v = questions[k];
        const a = fn(v.q, v.y, v.yv, v.n, v.nv);
        if (a) {
            answers.push(<li><L t={k} />:&nbsp; <L t={a} />  </li>);
        }
    }

    const twoItems = two.map(a => <li><L t={a} /></li>);
    if (sepsis.twoOther) {
        twoItems.push(<li>{sepsis.twoOther}</li>);
    }

    if (section2visible) {
        answers.push(<li><L t='ps-sepsis-history-infection' />:&nbsp; <ul>{twoItems}</ul></li>)
    }


    if (section3visible && three.some(t=>t)) {
        const threeItems = three.map(a => <li><L t={a} /></li>);
        answers.push(<li><L t='ps-sepsis-any-red-flags' />:&nbsp; <ul>{threeItems}</ul></li>)

    }
    if (section5visible && five.some(t=>t)){
        const fiveItems = five.map(a => <li><L t={a} /></li>);
        answers.push(<li><L t='ps-sepsis-any-amber-flags' />:&nbsp; <ul>{fiveItems}</ul></li>)
    }


    const lowRiskOfSepsis = (section2visible && two.some(t=>t === 'No') ) || (three.some(t => t === 'No') && (five.some(t => t === 'No')))
    const sepsisLikely = section5visible && (five.some(t => t !== 'No'));
    const highRiskOfSepsis = section3visible && (three.some(t => t !== 'No'));
    
    const sepsisColor = highRiskOfSepsis ? "error" : sepsisLikely ? "warning" : lowRiskOfSepsis ? "success" : "default";

    const sepsisContent = highRiskOfSepsis ? t('high-risk-of-sepsis')
        : lowRiskOfSepsis ?
            t('low-risk-of-sepsis')
            : sepsisLikely ? t('sepsis-likely') : "";

    return (
        <SubCard title="ps-sepsis">
            <ul>
                <li><U><><L t={'ps-sepsis-type'} />:&nbsp;<L t={sepsis.type} /></></U></li>
                <ol>
                    {answers}
                </ol>
                {(highRiskOfSepsis || lowRiskOfSepsis || sepsisLikely) && (
                    <li><U><Chip variant="outlined" color={sepsisColor} label={sepsisContent} /></U></li>
                )}
            </ul>
        </SubCard>
    )

}