import { Chip, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import L from "./localeText";

export function TreatmentCycleButton(p: { title: string; value: number; }) {
    return BaseTreatmentCycleButton({ ...p, treatmentValues: treatmentValues });
}

export const treatmentValues: { [index: string]: { text: string, sx: any } } = {
    '0': { text: 'treatment-none', sx: {} },
    '1': { text: 'treatment-burn', sx: { color: 'white', bgcolor: 'green' } },
    '2': { text: 'treatment-pressure', sx: { color: 'white', bgcolor: 'green' } },
    '3': { text: 'treatment-hemostatic', sx: { color: 'white', bgcolor: 'green' } },
    '4': { text: 'treatment-light', sx: { color: 'white', bgcolor: 'green' } }
};

const chestInterventionValues: { [index: string]: { text: string, sx: any } } = {
    '0': { text: 'treatment-none', sx: {} },
    '1': { text: 'treatment-chest-drain', sx: { color: 'white', bgcolor: 'green' } },
    '2': { text: 'treatment-chest-seal', sx: { color: 'white', bgcolor: 'green' } },
    '3': { text: 'treatment-chest-needle-d', sx: { color: 'white', bgcolor: 'green' } },
};

export function ChestInterventionCycleButton(p: { title: string, value: number }) {
    return BaseTreatmentCycleButton({ ...p, treatmentValues: chestInterventionValues })
}

function BaseTreatmentCycleButton(p: { title: string, value: number, treatmentValues: { [index: string]: { text: string, sx: any } } }) {
    const { t } = useTranslation();
    var items: ReactNode[] = [];
    const v = p.value.toString()
    const a = p.treatmentValues
    var key = 1;

    const match = Object.keys(a).filter(k => k === v);
    if (match && match.length) {
        const text = t(a[match[0]].text) || a[match[0]].text;
        const sx = a[match[0]].sx;
        items.push(<Chip key={`k${key}`} label={text} sx={sx} />)
    }

    return (
        <>
            <Typography component={'h2'}><L t={p.title} /></Typography>
            {items}
        </>
    );
}
