
import { Container, List, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CaseSummary } from "../models/Cases";
import { MongoDBClientContext } from "../providers/mongoDBClientProvider"
import { BuildSecondaryActions, PerformStandardAction } from "./buildGISUtils";
import { CaseSummaryTile } from "./CaseSummaryTile";
import L from "./localeText";


export function HistoricCasesPage() {
    const { state } = useContext(MongoDBClientContext);
    const [cases, setCases] = useState<CaseSummary[]>([])


    useEffect(() => {
        setCases(state.historicCases.sort((a,b)=>b.created.getTime() - a.created.getTime()));
    },[setCases, state.historicCases]);

    const navigate = useNavigate();
    const handleClick = (id: string, tab: string) => {
        PerformStandardAction(navigate, id, tab);
    }

    return (
        <Container>
            <Typography component='h2' >
                <L t='Historic Cases' />
            </Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {cases.map((c: any) =>
                    <CaseSummaryTile key={c._id} c={c} actions={BuildSecondaryActions(c, 40, handleClick, 'row', true, true, true)} />
                )}
            </List>
        </Container>
    )
}