import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { TourniquetHistory, Treatments, InitialAssessment, Incident, Interventions } from "../models/Cases";
import U from './UpdateItemIndicator';
import L from "./localeText";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactNode } from "react";
import DateTimeRender from "./DateTimeRenderer";
import { MultiSelectionComponent } from "./MultiSelectionComponent";
import { TourniquetHistoryView } from "./TourniquetHistoryView";
import { PriorityBadge } from "./PriorityBadges";
import { NumberOrMinusOneComponent } from "./NumberOrMinusOneComponent";
import { TextWithCarriageReturnsComponent } from "./TextWithCarriageReturnsComponent";

export function InitialAssessmentComponent(p: { d: InitialAssessment, t: Incident, i: Interventions, x: Treatments, h: TourniquetHistory }) {
    const d = p.d;
    const t = p.t;
    const h = p.h;

    return (
        <Card variant='outlined'>
            <CardHeader title={<Typography component="h1"><L t="initial-assessment-title" /> </Typography>} />

            <CardContent>
                {d && (
                    <ul>
                        <li><L t={'initial-assessment-location'}/>
                            <ul>
                                <li><span><L t='incident-location-lat' />:  <NumberOrMinusOneComponent v={d.ia_latitude} decimalPlaces={6} />&nbsp;<L t='incident-location-lng'/>: <NumberOrMinusOneComponent v={d.ia_longitude} decimalPlaces={6} /></span></li>
                                <li><span><L t='initial-assessment-location-utm-ref' />: {d.ia_UTMReference}</span></li>
                                <li><span><L t='initial-assessment-location-mgrs-ref' />: {d.ia_MGRSReference}</span></li>
                            </ul>
                        </li>
                        <li><L t={'incident-mechanism-title'} /></li>
                        <ul>
                            <li>
                                <U><><L t={'incident-mechanism-title'} /><div><MechanismDetails data={t.mechanism} /></div>
                                    <div><U><><L t={'incident-mechanism-notes'} />{`: ${t?.mechanismNotes ?? ''}`}</></U></div></></U>
                            </li>
                        </ul>
                        <li>
                            <L t={'injury-title'} />
                            <ul><li>
                                <Grid container >
                                    <Grid item md={3}><HeadInjuryButton title={'injury-head-title'} value={d.head_injury} /></Grid>
                                    <Grid item md={3}><TorsoInjuryButton title={'injury-torso-title'} value={d.torso_injury} /></Grid>
                                    <Grid item md={3}><ArmInjuryButton title={'injury-arms-title'} value={d.arms_injury} /></Grid>
                                    <Grid item md={3}><LegInjuryButton title={'injury-legs-titie'} value={d.legs_injury} /></Grid>
                                </Grid>
                            </li></ul>
                        </li>
                        <li>
                            <L t={'signs'} />
                            <ul>
                                <li>
                                    <Grid container >
                                        <Grid item md={2}><SignsPulseButton title={'signs-pulse-title'} value={d.pulse} /></Grid>
                                        <Grid item md={2}><SignsRespRateButton title={'signs-resprate-title'} value={d.respRate} /></Grid>
                                        <Grid item md={2}><SignsCapRButton title={'signs-capr-title'} value={d.capR} /></Grid>
                                        <Grid item md={2}><SignsSPO2Button title={'signs-spo2-title'} value={d.spo2} /></Grid>
                                        <Grid item md={2}><SignsAVPUButton title={'signs-avpu-title'} value={d.avpu} /></Grid>
                                    </Grid>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <L t={'otfc'} /> {(d.fentLollipops??-1 )>= 0 ? d.fentLollipops?.valueOf() : ""} {(d.fentLollipops??-1 )>= 0 && <><L t={'otfc-time'}/> <DateTimeRender value={d.fentLollipopsTime} format='evt' /></> }
                        </li>
                        <li>
                            <L t={'treatment-title'} />
                                <ul>
                                <TextWithCarriageReturnsComponent text={d.treatment} />
                                </ul>
                        </li>
                        <li>
                            <L t='ia_tourniquet_history_title' />
                                {h.rightArmDistTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-right-arm-dist" tourniquetOnOffhistory={h.rightArmDistTimes} /></li></ul>}
                                {h.rightArmJuncTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-right-arm-junc" tourniquetOnOffhistory={h.rightArmJuncTimes} /></li></ul>}
                                {h.leftArmDistTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-left-arm-dist" tourniquetOnOffhistory={h.leftArmDistTimes} /></li></ul>}
                                {h.leftArmJuncTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-left-arm-junc" tourniquetOnOffhistory={h.leftArmJuncTimes} /></li></ul>}
                                {h.rightLegDistTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-right-leg-dist" tourniquetOnOffhistory={h.rightLegDistTimes} /></li></ul>}
                                {h.rightLegJuncTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-right-leg-junc" tourniquetOnOffhistory={h.rightLegJuncTimes} /></li></ul>}
                                {h.leftLegDistTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-left-leg-dist" tourniquetOnOffhistory={h.leftLegDistTimes} /></li></ul>}
                                {h.leftLegJuncTimes.length != 0 && <ul><li><TourniquetHistoryView title="treatment-tourniquet-history-left-leg-junc" tourniquetOnOffhistory={h.leftLegJuncTimes} /></li></ul>}
                        </li>
                        <li>
                            <L t='incident-evac-priority' />
                                <ul>
                                    <PriorityBadge priorityId={t.evacPriorityId ?? -1} />
                                </ul>
                        </li>
                    </ul>
                )}
            </CardContent>
        </Card>
    )

}

function MechanismDetails(p: { data: string[] }) {
    return <MultiSelectionComponent data={p.data} availableItems={{
        Chemical: 'chemical', Biological: 'biological', Radiological: 'radiological', Nuclear: 'nuclear', Blast: 'blast', Aircraft: 'aircraft',
        Assault: 'assault', 'Collapsed Structure': 'collapsed-structure', Fall: 'fall', CBRN:'cbrn', Fire: 'fire', GSW: 'gsw', Hazmat: 'hazmat', Industrial: 'industrial',
        MTFA: 'mtfa', MVA: 'mva', 'Natural Disaster': 'natural-disaster', 'Train/Metro': 'train-metro', Water: 'water', Other: 'other'
    }} itemsToHide={{Chemical: 1, Biological: 1, Radiological: 1, Nuclear: 1, Blast: 0, Aircraft: 1,
    Assault: 1, 'Collapsed Structure': 1, Fall: 0, CBRN:0, Fire: 0, GSW: 0, Hazmat: 1, Industrial: 1,
    MTFA: 1, MVA: 0, 'Natural Disaster': 1, 'Train/Metro': 1, Water: 1, Other: 0
}} />
}


const InjuryHeadValues: { [index: string]: { text: string, sx: any } } = {
    '1': { text: 'injury-none', sx: { color: 'white', bgcolor: 'green' } },
    '2': { text: 'injury-head', sx: { color: 'white', bgcolor: 'red' } },
    '3': { text: 'injury-neck', sx: { color: 'white', bgcolor: 'red' } },
    '4': { text: 'injury-both', sx: { color: 'white', bgcolor: 'red' } },
};

const InjuryTorsoValues: { [index: string]: { text: string, sx: any } } = {
    '1': { text: 'injury-none', sx: { color: 'white', bgcolor: 'green' } },
    '2': { text: 'injury-chest', sx: { color: 'white', bgcolor: 'red' } },
    '3': { text: 'injury-abdo', sx: { color: 'white', bgcolor: 'red' } },
    '4': { text: 'injury-both', sx: { color: 'white', bgcolor: 'red' } },
};

const InjuryArmsValues: { [index: string]: { text: string, sx: any } } = {
    '1': { text: 'injury-none', sx: { color: 'white', bgcolor: 'green' } },
    '2': { text: 'injury-right', sx: { color: 'white', bgcolor: 'red' } },
    '3': { text: 'injury-left', sx: { color: 'white', bgcolor: 'red' } },
    '4': { text: 'injury-both', sx: { color: 'white', bgcolor: 'red' } },
};

const InjuryLegValues: { [index: string]: { text: string, sx: any } } = {
    '1': { text: 'injury-none', sx: { color: 'white', bgcolor: 'green' } },
    '2': { text: 'injury-right', sx: { color: 'white', bgcolor: 'red' } },
    '3': { text: 'injury-left', sx: { color: 'white', bgcolor: 'red' } },
    '4': { text: 'injury-both', sx: { color: 'white', bgcolor: 'red' } },
};

const SignsPulseValues: {[index:string]: {text:string, sx:any} } = {
    '1': {text: 'ia_pulse_state_lt_100', sx: { color: 'white', bgcolor: 'green' }},
    '2': {text: 'ia_pulse_state_gt_100', sx: { color: 'white', bgcolor: 'red' }},
    '3': {text: 'ia_pulse_state_none', sx: { color: 'white', bgcolor: 'red' }}
};

const SignsRespRateValues: {[index:string]: {text:string, sx:any} } = {
    '1': {text: 'ia_resp_one', sx: { color: 'white', bgcolor: 'red' }},
    '2': {text: 'ia_resp_two', sx: { color: 'white', bgcolor: 'green' }},
    '3': {text: 'ia_resp_three', sx: { color: 'white', bgcolor: 'red' }}
};

const SignsCapRValues: {[index:string]: {text:string, sx:any} } = {
    '1': {text: 'ia_cap_r_lt_2_secs', sx: { color: 'white', bgcolor: 'green' }},
    '2': {text: 'ia_cap_r_gt_2_secs', sx: { color: 'white', bgcolor: 'red' }},
};

const SignsSPO2Values: {[index:string]: {text:string, sx:any} } = {
    '1': {text: 'ia_spo2_one', sx: { color: 'white', bgcolor: 'red' }},
    '2': {text: 'ia_spo2_two', sx: { color: 'white', bgcolor: 'green' }},
};

const SignsAVPUValues: {[index:string]: {text:string, sx:any} } = {
    '1': {text: 'A', sx: { color: 'white', bgcolor: 'green' }},
    '2': {text: 'V', sx: { color: 'white', bgcolor: 'green' }},
    '3': {text: 'P', sx: { color: 'white', bgcolor: 'red' }},
    '4': {text: 'U', sx: { color: 'white', bgcolor: 'red' }},
};





function HeadInjuryButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: InjuryHeadValues })
}

function TorsoInjuryButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: InjuryTorsoValues })
}
function ArmInjuryButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: InjuryArmsValues })
}
function LegInjuryButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: InjuryLegValues })
}

function SignsPulseButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: SignsPulseValues })
}

function SignsRespRateButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: SignsRespRateValues })
}

function SignsCapRButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: SignsCapRValues })
}


function SignsSPO2Button(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: SignsSPO2Values })
}

function SignsAVPUButton(p: { title: string, value?: number }) {
    return BaseInjuryCycleButton({ ...p, treatmentValues: SignsAVPUValues })
}


function BaseInjuryCycleButton(p: { title: string, value?: number, treatmentValues: { [index: string]: { text: string, sx: any } } }) {
    const { t } = useTranslation();
    var items: ReactNode[] = [];
    const v = p.value || -1;
    const a = p.treatmentValues
    var key = 1;

    const match = Object.keys(a).filter(k => k === v.toString());
    if (match && match.length) {
        const text = t(a[match[0]].text) || a[match[0]].text;
        const sx = a[match[0]].sx;
        items.push(<Chip key={`k${key}`} label={text} sx={sx} />)
    }

    return (
        <>
            <Typography component={'h2'}><L t={p.title} /></Typography>
            {items}
        </>
    );
}