import L from "./localeText";
import { AzureClientConsumer, AzureClientContext } from '../providers/azureClientProvider';
import { Container, Grid, Typography } from '@mui/material';
import { LoadingSpinner } from './LoadingSpinner';
import { useContext, useEffect, useState } from 'react';
import { ECGComponent } from "./ECGComponent";
import { CaseDetail } from "../models/Cases";

export function ECGPage(p: { selectedCase: CaseDetail }) {
    const { dispatch } = useContext(AzureClientContext);

    useEffect(() => {
        dispatch({ type: 'caseSelectedChange', data: p.selectedCase._id })
    }, [p.selectedCase._id, dispatch])

    return (
        <Container>
            <Typography component='h2' >
                <L t='ecg' />
            </Typography>

            <AzureClientConsumer>
                {({ state, dispatch }) => {
                    if (state.selectedCaseId && state.azureCaseData) {
                        let f = () => {
                        var groupedByType = groupBy((state.azureCaseData as []),'datatype');
                        const ecgs = Object.keys(groupedByType).filter(t=>t.startsWith('ECG')).map(t=><>
                        <Grid container spacing={1}>{(groupedByType[t]||[] as []).map((i:any) => <Grid md={12} item ><ECGComponent d={i} ecgMeasurements={p.selectedCase.ecgMeasurements} /></Grid>)}</Grid>
            
                        </>                        
                        );

                        return <>{ecgs}</>;
                        }
                        return f();
                    
                    } else {
                        return <LoadingSpinner />;
                    }
                }
                }
            </AzureClientConsumer>
        </Container>
    )
}

function ImageComponent(p: { d: { fetchImage: () => Promise<Blob | null> , name:string|null, dataType:string, caseId:string } }) {
    const [imageData, setImageData] = useState<string | null>(null);
    const [blob, setBlob] = useState<ArrayBuffer|null>(null);

    useEffect(() => {
        async function f() {
            let img = await p.d.fetchImage();
            if (img != null) {
                const url = URL.createObjectURL(img);

                setImageData(url);
            }
        }
        f();
    }, [p.d]);

    const c = imageData?
     <div><p>{p.d.name||''}</p><img alt={p.d.name||''} src={imageData} width={'100%'} /></div> : <p>{'loading...'}</p>
    return c;
}

var groupBy = function(xs:object[], key:string):{[key:string]:[]} {
    return xs.reduce(function(rv:any, x:any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
};