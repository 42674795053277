import React, {useState} from 'react';
import i18n from "i18next";
import L from './localeText';
import France from '../assets/img/flags/France.png';
import Germany from '../assets/img/flags/Germany.png';
import US from '../assets/img/flags/United-States.png';
import UK from '../assets/img/flags/unitedkingdom.png';
import Spain from '../assets/img/flags/Spain.png';
import Czech from '../assets/img/flags/czech-republic.png';
import Dialog from '@mui/material/Dialog';
import { Avatar, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const LocaleSwitcher = () => {

    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentLocale, setCurrentLocale] = useState(i18n.language)

    const onClose = () => {
        setDialogOpen(false);
    }
    const changeLocale = (newLocale: string) => {        
        setCurrentLocale(newLocale)
        i18n.changeLanguage(newLocale)
        document.cookie = `lang=${newLocale}`    
    }
        const availableLang = [
            { img: UK, textId:"uk", locale:"en" },
            { img: US, textId:"unitedstates", locale:"us" },
            { img: Germany, textId:"german", locale:"de" },
            { img: France, textId:"french", locale:"fr" },
            { img: Czech, textId:"czech", locale:"cz" },
            //{ img: Saudi, textId: "saudi", locale: "sa" },
            { img: Spain, textId: "spain", locale: "es" },
        ];

        const current = availableLang.find((v) => { return v.locale === currentLocale }) || availableLang[0];

        var elements = availableLang.map((v) => {
            let classname = v.locale === currentLocale ? "bg-primary" : "";
            return <div style={{margin:10, display:'flex', flexDirection:'row', alignItems:'center'}} key={v.locale} className={classname} onClick={() => {changeLocale(v.locale) }} >
                <Avatar src={v.img} sx={{width:48,height:48}}/>
               &nbsp; <L t={v.textId} /></div>;
        });

        let dialog = <Dialog onClose={onClose} open={dialogOpen} >
            <DialogTitle>
            <h3><L t="chooseLocale" /></h3>
            </DialogTitle>
            <DialogContent>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'space-around' }} > 
                {elements}
                </div>
                </DialogContent>
                <DialogActions>
            <button type="button" onClick={(e) => onClose()}><h1><L t="close"  /></h1></button>
            </DialogActions>
            </Dialog>;

        return <>
                <Avatar src={current.img}  onClick={(e) => setDialogOpen(true)} sx={{width:24, height:24}}/>
                  {dialog}
                </>;

    }

    export default LocaleSwitcher;