import React from 'react';
import login from '../assets/img/login.jpeg'
import { pageStyle } from '../styles'

import { createUseStyles } from "react-jss";
import { createTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

import microsoftSignIn from "../assets/img/signInWithMicrosoftLight.png";
import RSA from 'react-simple-auth';
import { microsoftProvider } from '../providers/microsoft';
import { AppContextConsumer } from '../providers/appContextProvider';


const useStyles = createUseStyles(pageStyle);

const onClickLogin = (dispatch: React.Dispatch<any> )=> 
{
   const f = async ()=>{ 
   try {
       const session = await RSA.acquireTokenAsync(microsoftProvider);
       await dispatch({type:'login', data:{...session}})
       
   } catch (error) {
       //throw error
   }
    };
    f();
}


function LoginPage() {
    const theme = createTheme({});
    const classes = useStyles({ theme });
    return (
        <AppContextConsumer>
            {({state,dispatch})=>{
                return (
        <div className={classes.fullPage}
            style={{ backgroundImage: "url(" + login + ")" }} >            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <div className="login">
                        <div className="login-providers">
                            <h4>Login:</h4>
                            <button type="button" className="login-button" onClick={() => onClickLogin(dispatch)}>
                                <img src={microsoftSignIn} alt="Login with Microsoft" />
                            </button>
                            
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
                )} }
        </AppContextConsumer>
    )
}

export default LoginPage;