import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { link } from 'fs';

let theme = createTheme({})
export default createTheme(theme,{
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    typography: {
        fontSize: '20px'        
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 20
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    maxWidth: '1200px !important',
                    marginRight: '0px',
                    marginLeft:'240px',
                    minWidth: '1200px !important',
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {"&.MuiTab-root": {
                    fontSize:'larger'
                }
            }
        }
        },
        MuiTableCell: {
            styleOverrides: {
                body: {
                    fontSize:'24px'
                },
                head: {
                    fontSize:'24px'
                }
            }
        }

    }

    

});