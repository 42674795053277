export const TRANSLATIONS_ES = {
    "Yes" : "Si",
    "No" : "Sin",

    "name": "Nombre",
    "gender": "Sexo",
    "zap":"zap",

    "dashboard-title": "Panel",

    "Active Cases": "Casos activos",
    "Historic Cases": "Casos históricos",

    "Ample":"AMPLE",
    "Allergies":"Alergias",
    "Medical History":"Historial médico previo",
    "Medications":"Medicamentos",
    "No Ample details recorded.": "No Ample details recorded.",
    "Asprin":"Aspirina",
    "Carbamazepine":"Carbamazepina",
    "Cephalosporin" : "Cefalosporina",
    "Codeine" : "Codeína" ,
    "Insulin" : "Insulina",
    "Latex" : "Látex",
    "Nsaid" : "AINE",
    "Penicillin" : "Penicilina",
    "phenytoin" : "Fenitoína",
    "Sulphonamide" : "Sulfonamida",
    "Other" : "Otro",
    "None" : "Ninguna",
    "Unknown" : "Desconocido",
    "No Medical History recorded.":"No Medical History recorded.",

    "Cancer":"Cáncer",
    "CVA":"ACV",
    "Diabetes" : "Diabetes",
    "Epilepsy":"Epilepsia",
    "Chronic Heart Condition":"Afección cardíaca",
    "Hypertension":"Hipertensión",
    "Chronic Lung Condition":"Afección pulmonar",
    "Mental Illness":"Trastorno mental",
    "Physically Disabled":"Discapacitado",
    "Pregnant":"Embarazada",
    "Myocardial Infarction":"Miocárdico",

    "chooseLocale": "Elegir idioma",
    "close": "Cerrar",
    "uk": "Reino Unido",
    "unitedstates" : "Estados Unidos de Norteamérica",
    "german": "Alemán",
    "french": "Francés",
    "czech": "Republica checa",
    "spain": "Español",

    "vitals-label-skintemphistory": "Temperatura de la piel",
    "vitals-label-coretemphistory": "Temperatura del núcleo",

   };