import { Card, CardContent, CardHeader, Stack, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Ample, AmpleAllergies, AmpleMedicalHistory, AmpleMedications, Administration } from "../models/Cases";
import U from './UpdateItemIndicator';
import TF from './TrueFalseComponent';
import L from "./localeText";
import DateTimeRender from "./DateTimeRenderer";

export function AmpleComponent(p: { a: Ample }) {

    const a = p.a;
    
    let lastOralIntake = <></>
    if (a.lastOralIntake >= 0) {
        switch(a.lastOralIntake) {
        case 4: lastOralIntake=<span>&lt; 4 <L t="Hours" /></span>; break;
        case 8: lastOralIntake=<span>&lt; 8<L t="Hours" /></span>; break;
        case 12: lastOralIntake=<span>&lt; 12 <L t="Hours" /></span>; break;
        case 23: lastOralIntake=<span>&lt; 24 <L t="Hours" /></span>; break;
        case 25: lastOralIntake=<span>&gt; 24 <L t="Hours" /></span>; break;
        default: lastOralIntake=<span><L t="Not Known"/></span>; break;        
        }
    } 

    return (
        <Card variant="outlined">
            <CardHeader title={<Typography component="h1"><L t="Ample" /> </Typography>} />

            <CardContent>
                {a && (
                    <Stack>
                        <Typography component="h2"><L t="Allergies" />:</Typography>
                        <Allergies a={a.allergies} />
                        <Typography component="body"><L t="ample-medications-history" />: <Medications m={a.medications} /></Typography>
                        <Typography component="body"><L t="Past Medical History" />: <MedicationHistory m={a.pastMedicalHistory} /> </Typography>
                        <Typography component="body"><L t="Last Oral Intake" />: &nbsp;{lastOralIntake}</Typography>
                        <Typography component="body"><L t="Event" />: {a.event} </Typography>
                    </Stack>
                )}
                {!a && (<Typography component="body"><L t="No Ample details recorded." /></Typography>)}
            </CardContent>
        </Card>
    )
}

function Allergies(p: { a?: AmpleAllergies }) {
    return <ul style={{ columnCount: 3 }}>
        {p.a ? <>
            <li><TF key={'asp'} label='Aspirin' value={p.a.aspirin} /></li>
            <li><TF key={'asp'} label='Carbamazepine' value={p.a.carbamazepine} /></li>
            <li><TF key={'cep'} label='Cephalosporin' value={p.a.cephalosporin} /></li>
            <li><TF key={'cod'} label='Codeine' value={p.a.codeine} /></li>
            <li><TF key={'ins'} label='Insulin' value={p.a.insulin} /></li>
            <li><TF key={'lat'} label='Latex' value={p.a.latex} /></li>
            <li><TF key={'nsa'} label='Nsaid' value={p.a.nsaid} /></li>
            <li><TF key={'pen'} label='Penicillin' value={p.a.penicillin} /></li>
            <li><TF key={'phe'} label='Phenytoin' value={p.a.phenytoin} /></li>
            <li><TF key={'sul'} label='Sulphonamide' value={p.a.sulphonamide} /></li>
            <li><TF key={'sul'} label='Tetracycline' value={p.a.tetracycline} /></li>
            <li><span><TF key={'other'} label='Other' value={p.a.otherNotes.length > 0} /><Typography>{p.a.otherNotes}</Typography></span> </li>
            <li><TF key={'none'} label='None' value={p.a.none} /></li>
            <li><TF key={'unk'} label='Unknown' value={p.a.unknown} /></li>
        </> : <li><U>(<Typography key={'none'} component="p"><L t="No allergies specified" /> </Typography>)</U></li>
        }
    </ul>
}

function Medications(p: { m?: AmpleMedications }) {
    return (p.m?.administrations ?? []).length ? ( 
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell><L t="medication-name" /></TableCell>
                    <TableCell><L t="quantity" /></TableCell>
                    <TableCell><L t="unit" /></TableCell>
                    <TableCell><L t="route" /></TableCell>
                    <TableCell><L t="frequency" /></TableCell>
                </TableRow>
                </TableHead>
            {p.m?.administrations?.map((a: Administration) => <TableRow>
            <TableCell><Typography component="span">{a.name}</Typography></TableCell>
            <TableCell><Typography component="span">{a.quantity}</Typography></TableCell>
            <TableCell><Typography component="span">{a.unit}</Typography></TableCell>
            <TableCell><Typography component="span">{a.ivio}</Typography></TableCell>
            <TableCell><Typography component="span">{a.drugFrequency}</Typography></TableCell></TableRow> )}
            </Table> ) :
            (<p><Typography component="p"><L t="No medications recorded." /></Typography></p>)
}

function MedicationHistory(p: { m: AmpleMedicalHistory }) {
    return <ul style={{ columnCount: 3 }}>
        {p.m ? <>
            <li><TF key={'can'} label='Cancer' value={p.m.cancer} /></li>
            <li><TF key={'cva'} label='CVA' value={p.m.cva} /></li>
            <li><TF key={'dia'} label='Diabetes' value={p.m.diabetes} /></li>
            <li><TF key={'epi'} label='Epilepsy' value={p.m.epilepsy} /></li>
            <li><TF key={'chc'} label='Chronic Heart Condition' value={p.m.heartCondition} /></li>
            <li><TF key={'hyp'} label='Hypertension' value={p.m.hypertension} /></li>
            <li><TF key={'clc'} label='Chronic Lung Condition' value={p.m.lungCondition} /></li>
            <li><TF key={'pd'} label='Physically Disabled' value={p.m.disabled} /></li>
            <li><TF key={'mi'} label='Mental Illness' value={p.m.mentalIllness} /></li>
            <li><TF key={'prg'} label='Pregnant' value={p.m.pregnant} /></li>
            <li><TF key={'myf'} label='Myocardial Infarction' value={p.m.myocardial} /></li>
            <li><U key={'other'}><Typography component="p"><L t="Other" />: {p.m.other}</Typography></U></li>
            <li><TF key={'none'} label='None' value={p.m.none} /></li>
            <li><TF key={'unk'} label='Unknown' value={p.m.unknown} /></li>
        </> : <li><U>(<Typography key={'none'} component="p"><L t="No Medical History recorded." /></Typography>)</U></li>
        }
    </ul>
}

