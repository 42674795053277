import { useState, useEffect } from "react";
import ArcGISMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import esriConfig from "@arcgis/core/config";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import Feature from "@arcgis/core/widgets/Feature";

import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle.js";

const mapStyle = { padding: 0, margin: 0, height: '100%', width: '100%' };

export type GISSummaryData = { lat?: number, lng?: number, id: string, caseObjectId: string } & object

export type GISSummaryList = GISSummaryData[];

const simpleMarkerSymbol = new SimpleMarkerSymbol({
  color: [0, 0, 255],
  style: "square",
  size: 10,
  outline: {
    color: [255, 255, 255], // White
    width: 1
  }
});

export function ArcGISMapping(p: {
  list: GISSummaryList, fields: { name: string, type: any }[], popupTemplate: { title: string, content: (fields: any) => Promise<HTMLElement> },
  selectedCaseIds?: string[], popupVisibilityChange?: (caseId:string, visible:boolean)=>void
}) {

  esriConfig.apiKey = "AAPK6e1a8845fb0c457c9738419893523b03xhfYRxTEpNwi0tDuge1RU72aOIpLlFrF29qipdke9R1VrzbY8Ra2AQN_JbxdLqTX";
  esriConfig.assetsPath = "./assets";

  const [graphicsLayerId, setGraphicsLayerId] = useState<string | undefined>();
  const [mapView, setMapView] = useState<MapView | undefined>();
  const [markers, setMarkers] = useState<{ [key: string]: Feature }>({});
  const [cursorXY, setCursorXY] = useState<{ x?: Number, y?: number }>();

  useEffect(() => {
    const f = p.list.filter(c => c.lng! !== -1 && c.lat! !== -1)

    if (f.length) {
      const m: { [key: string]: Feature } = { ...markers };
      f.forEach(e => {
        const c = m[e.id];
        if (c) {
          for (const k in c.graphic.attributes) {
            //console.log(k);
            if ((e as any)[k] !== c.graphic.getAttribute(k) || k === 'title') {
              c.graphic.attributes[k] = (e as any)[k];
            }
          }
          c.graphic.geometry = new Point({ latitude: e.lat!, longitude: e.lng!, spatialReference: SpatialReference.WGS84 });
        }
      });


      f.forEach(e => {
        if (!m[e.id]) m[e.id] = new Feature({
          spatialReference: SpatialReference.WGS84,
          graphic: new Graphic({
            geometry: new Point({ longitude: e.lng!, latitude: e.lat!, spatialReference: SpatialReference.WGS84 }),
            symbol: simpleMarkerSymbol,
            attributes: e,
            /*popupTemplate: {
              title: p.popupTemplate.title,
              content: function (target: any) {
                return p.popupTemplate.content(target.graphic.attributes);
              }
            }*/
          })
        })
      });

      setMarkers(m);
    }
  }, [p.list]);

  useEffect(() => {
    if (mapView && Object.keys(markers).length > 0) {
      const layer = (mapView!.map.layers.find(g => g.id === graphicsLayerId)) as FeatureLayer;
      if (layer) {
        const itemsToAdd = Object.entries(markers).filter(v =>
          v[1].graphic.layer == null).map(v => v[1].graphic);

        const itemsToUpdate = Object.entries(markers).filter(v =>
          v[1].graphic.layer != null).map(v => v[1].graphic);

        layer.applyEdits({
          updateFeatures: itemsToUpdate,
          addFeatures: itemsToAdd
        }).then((result) => {
          itemsToAdd.forEach(i => i.layer = layer);
        })
          .catch(r => {
            console.log(r)
          })
          .finally(() => {
            let zoom = 13;
            if (p.selectedCaseIds && p.selectedCaseIds.length) {
              const s = Object.entries(markers).filter((kvp) => p.selectedCaseIds!.indexOf(kvp[0]) !== -1).map(kvp => kvp[1].graphic);
              if (s.length > 1)
                layer.when(() => { }).then(r => { 
                  //console.log(r); 
                  mapView.goTo(s).then(r=> {
                  mapView.popup.open({features:s,fetchFeatures:false,shouldFocus:false}); 
                  })
                  });
              else 
                layer.when(() => { }).then(r => { 
                  console.log(r);
                  mapView.goTo({ target: s, zoom: zoom }).then( r => {
                  //mapView.popup.visible = false;  
                  mapView.popup.open({features:s,fetchFeatures:false,shouldFocus:false, updateLocationEnabled:true })
                  });
                });

            }
            else {
              layer.when(() => { return layer.queryExtent() }).then(r => { console.log(r); mapView.goTo(r.extent) });
            }

          });


      }
    }
  }, [markers, p.selectedCaseIds]);

  useEffect(() => {
    const map = new ArcGISMap({
      basemap: "streets-vector"
    });

    const featureLayer = new FeatureLayer({
      spatialReference: SpatialReference.WGS84,
      source: [],
      fields: p.fields,
      objectIdField: 'id',
      geometryType: 'point',
      popupTemplate: {
        title: p.popupTemplate.title,
        outFields: ["caseid", "evacpriorityid", "title", "caseObjectId", "id", "surname", "forename", "lat", "lng"],
        content: function (target: any) {
          return p.popupTemplate.content(target.graphic.attributes);
        }
      }
    });

    map.add(featureLayer);

    setGraphicsLayerId(featureLayer.id);

    const view = new MapView({
      map: map,
      container: "viewDiv",
      center: new Point({ longitude: 0, latitude: 52 }),
      constraints: { snapToZoom: true }
    });

    const basemapToggle = new BasemapToggle({
      view:view,
      nextBasemap:'arcgis-imagery'
    });

    view.ui.add(basemapToggle,'bottom-right')
    view.popup.watch("visible", (popupStatusChange)=> {
        setTimeout( () => {
          if (p.popupVisibilityChange) {
            //console.log(view.popup.get('content.graphic.attributes.caseObjectId'));
          p.popupVisibilityChange(view.popup.get('content.graphic.attributes.caseObjectId'), popupStatusChange)
          }}
        ,100)
      }
    )


    view.when(() => {
      //console.log("Map is loaded");
    })

    view.on('pointer-move', (e) => { const mp = webMercatorUtils.webMercatorToGeographic(view.toMap({ x: e.x, y: e.y })); setCursorXY({ x: (mp as Point).x, y: (mp as Point).y }) });

    setMapView(view)
  }, []);

  return <><div id="viewDiv" style={mapStyle}></div><div>Lat:{cursorXY?.x} Lng:{cursorXY?.y}</div></>
}
