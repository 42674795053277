import React from 'react';

function UnauthenticatedLayout(props: { children: React.ReactNode }) {
    return (
        <div>
            {props.children}
        </div>
    )
}

export default UnauthenticatedLayout;